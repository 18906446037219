export default {
    id: null,
    so_number : null,
    req_delivery_date : null,
    order_type : null,
    origin_id : null,
    dest_id : null,
    client_id : null,
    status : null,
    remark : null,
    item_product : []
}