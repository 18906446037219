export default {
    id: null,
    dn_number : null,
    reference : null,
    delivery_date : null,
    order_type : null,
    client_id : 0,
    status : null,
    remark : null,
    item_product : [],
    preferred_vehicle_type_id : null,
    preferred_vehicle_desc : null
}