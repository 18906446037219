<template>
    <div>
        <Panel :title="titleView"  style="margin:0px 1px; ">
            <div class="datagrid-toolbar">
                <LinkButton :plain="true" :iconCls="iconUtil.iconRefresh" @click="onRefresh()">{{ btnText.refresh }}</LinkButton>
                <LinkButton v-if="action.add" :plain="true" :iconCls="iconUtil.iconAdd" @click="onAdd()">{{ btnText.add }}</LinkButton>
                <LinkButton v-if="action.edit" :plain="true" :iconCls="iconUtil.iconEdit" @click="onEdit()">{{ btnText.edit }}</LinkButton>
                <LinkButton v-if="action.delete" :plain="true" :iconCls="iconUtil.iconDelete" @click="onDelete()">{{ btnText.delete }}</LinkButton>
                <span class="button-sep"></span> {{ search.text }} : 
                <SearchBox style="width:300px"
                    :placeholder="search.placeholder" 
                    v-model="dataGrid.search"
                    @search="onSearch()">
                    <Addon>
                        <span v-if="dataGrid.search" class="textbox-icon icon-clear" title="Clear value" @click="dataGrid.search=null;onSearch();"></span>
                    </Addon>
                </SearchBox>
                <LinkButton :plain="true" :iconCls="iconUtil.iconMultiSearch" @click="onMultiSearch()">{{ informationUtil.multiSearchText }}</LinkButton>
            </div>
            <DataGrid
                    ref="dataGrid"
                    :columnResizing="true"
                    :border="false"
                    :lazy="true"
                    :loading="dataGrid.loading"
                    :data="dataGrid.rows"
                    :pagination="true"
                    :selectionMode="dataGrid.selectionMode"
                    :total="dataGrid.total"
                    :pageSize="dataGrid.pageSize"
                    :pageNumber="dataGrid.pageNumber"
                    :pageLayout="dataGrid.pageLayout" 
                    @pageChange="onPageChange($event)"
                    @sortChange="onSortChange($event)"
                    @rowDblClick="onRowDblClick()"
                    >
                    <GridColumn align="center" cellCss="datagrid-td-rownumber" width="30">
                    <template slot="body" slot-scope="scope">
                        {{scope.rowIndex + 1}}
                    </template>
                </GridColumn>
                <GridColumn field="trucking_order_no" title="Trucking ID" :sortable="true"></GridColumn>
                <GridColumn field="client_desc" title="Client ID" :sortable="true"></GridColumn>
                <GridColumn field="manifest_id" title="Manifest ID" :sortable="true"></GridColumn>
                <GridColumn field="schedule_date_desc" title="Schedule Date" :sortable="true"></GridColumn>
                <GridColumn field="origin_customer_name" title="Origin Name" :sortable="true"></GridColumn>
                <GridColumn field="origin_customer_address" title="Origin Address" :sortable="true"></GridColumn>
                <GridColumn field="origin_area_desc" title="Origin Area" :sortable="true"></GridColumn>
                <GridColumn field="req_pickup_time_desc" title="Req Pickup Time" :sortable="true"></GridColumn>
                <GridColumn field="dest_customer_name" title="Destination Name" :sortable="true"></GridColumn>
                <GridColumn field="dest_customer_address" title="Destination Address" :sortable="true"></GridColumn>
                <GridColumn field="dest_area_desc" title="Destination Area" :sortable="true"></GridColumn>
                <GridColumn field="req_arrival_time_desc" title="Req Arrival Time" :sortable="true"></GridColumn>
                <GridColumn field="pref_vehicle_type_desc" title="Pref Vehicle Type" :sortable="true"></GridColumn>
                <GridColumn field="created_by" title="Request By" :sortable="true"></GridColumn>
                <GridColumn field="created_date" title="Request Date" :sortable="true"></GridColumn>
                <GridColumn field="tr_status_desc" title="Status" :sortable="true"></GridColumn>
            </DataGrid>
        </Panel>

        <!-- Multi Search -->
        <Dialog ref="dlgSearch" :iconCls="iconUtil.iconMultiSearch" bodyCls="f-column" :title="informationUtil.multiSearchText" :draggable="true" :modal="true" closed :dialogStyle="{width:'500px', height:'auto'}">
            <div class="f-full" style="overflow:auto">
                <table width="100%" cellspacing="1" cellpadding="5" border="0">
                    <tbody>
                        <tr>
                            <td style="width : 20%" class="bg_subtitle">
                                Client ID
                            </td>
                            <td class="bg_grid2" colspan="3"> 
                                <ComboBox  :data="clients" @filterChange="optionClient($event)" inputId="client_id" name="client_id" class="f-field-text" v-model="modelMultiSearch.client_id">
                                    <template slot="item" slot-scope="scope">
                                        <span style="font-weight:bold">{{scope.row.text}}</span><br/>
                                        <span style="color:#888">{{scope.row.address}}</span><br/>
                                    </template>
                                    <Addon>
                                        <span v-if="modelMultiSearch.client_id" class="textbox-icon icon-clear" title="Clear value" @click="modelMultiSearch.client_id=null;"></span>
                                    </Addon>
                                </ComboBox>
                            </td>
                        </tr>
                        <tr>
                            <td class="bg_subtitle">Schedule Date</td>
                            <td class="bg_grid2">
                                <DateBox inputId="schedule_date_from" name="schedule_date_from" class="f-field-text" v-model="modelMultiSearch.schedule_date_from" format="dd/MM/yyyy">
                                    <Addon>
                                        <span v-if="modelMultiSearch.schedule_date_from" class="textbox-icon icon-clear" title="Clear value" @click="modelMultiSearch.schedule_date_from=null;"></span>
                                    </Addon>
                                </DateBox>
                            </td>
                            <td>to</td>
                            <td class="bg_grid2">
                                <DateBox inputId="schedule_date_to" name="schedule_date_to" class="f-field-text" v-model="modelMultiSearch.schedule_date_to" format="dd/MM/yyyy">
                                    <Addon>
                                        <span v-if="modelMultiSearch.schedule_date_to" class="textbox-icon icon-clear" title="Clear value" @click="modelMultiSearch.schedule_date_to=null;"></span>
                                    </Addon>
                                </DateBox>
                            </td>
                        </tr>
                        <tr>
                            <td class="bg_subtitle">Origin ID</td>
                            <td class="bg_grid2" colspan="3">
                                <ComboBox  :data="customer_origins" @filterChange="optionCustomerOrigin($event)" inputId="origin_id" name="origin_id" class="f-field-text" v-model="modelMultiSearch.origin_id">
                                    <template slot="item" slot-scope="scope">
                                        <span style="font-weight:bold">{{scope.row.text}}</span><br/>
                                        <span style="color:#888">{{scope.row.name}}</span><br/>
                                        <span style="color:#888">{{scope.row.address}}</span><br/>
                                    </template>
                                    <Addon>
                                        <span v-if="modelMultiSearch.origin_id" class="textbox-icon icon-clear" title="Clear value" @click="modelMultiSearch.origin_id=null;"></span>
                                    </Addon>
                                </ComboBox>
                            </td>
                        </tr>
                        <tr>
                            <td class="bg_subtitle">Origin Area</td>
                            <td class="bg_grid2" colspan="3">
                                <ComboBox  :data="origins" @filterChange="optionOrigin($event)" inputId="origin_area_id" name="origin_area_id" class="f-field-text" v-model="modelMultiSearch.origin_area_id">
                                    <Addon>
                                        <span v-if="modelMultiSearch.origin_area_id" class="textbox-icon icon-clear" title="Clear value" @click="modelMultiSearch.origin_area_id=null;"></span>
                                    </Addon>
                                </ComboBox>
                            </td>
                        </tr>
                        <tr>
                            <td class="bg_subtitle">Req. Pickup Time</td>
                            <td class="bg_grid2">
                                <DateBox inputId="req_pickup_time_from" name="req_pickup_time_from" class="f-field-text" v-model="modelMultiSearch.req_pickup_time_from" format="dd/MM/yyyy">
                                    <Addon>
                                        <span v-if="modelMultiSearch.req_pickup_time_from" class="textbox-icon icon-clear" title="Clear value" @click="modelMultiSearch.req_pickup_time_from=null;"></span>
                                    </Addon>
                                </DateBox>
                            </td>
                            <td>to</td>
                            <td class="bg_grid2">
                                <DateBox inputId="req_pickup_time_to" name="req_pickup_time_to" class="f-field-text" v-model="modelMultiSearch.req_pickup_time_to" format="dd/MM/yyyy">
                                    <Addon>
                                        <span v-if="modelMultiSearch.req_pickup_time_to" class="textbox-icon icon-clear" title="Clear value" @click="modelMultiSearch.req_pickup_time_to=null;"></span>
                                    </Addon>
                                </DateBox>
                            </td>
                        </tr>
                        <tr>
                            <td class="bg_subtitle">Destination ID</td>
                            <td class="bg_grid2" colspan="3">
                                <ComboBox  :data="customer_destinations" @filterChange="optionCustomerDestination($event)" inputId="dest_id" name="dest_id" class="f-field-text" v-model="modelMultiSearch.dest_id"> 
                                    <template slot="item" slot-scope="scope">
                                        <span style="font-weight:bold">{{scope.row.text}}</span><br/>
                                        <span style="color:#888">{{scope.row.name}}</span><br/>
                                        <span style="color:#888">{{scope.row.address}}</span><br/>
                                    </template>
                                    <Addon>
                                        <span v-if="modelMultiSearch.dest_id" class="textbox-icon icon-clear" title="Clear value" @click="modelMultiSearch.dest_id=null;"></span>
                                    </Addon>
                                </ComboBox>
                            </td>
                        </tr>
                        <tr>
                            <td class="bg_subtitle">Dest Area</td>
                            <td class="bg_grid2" colspan="3">
                                <ComboBox  :data="destinations" @filterChange="optionDestination($event)" inputId="dest_area_id" name="dest_area_id" class="f-field-text" v-model="modelMultiSearch.dest_area_id">
                                    <Addon>
                                        <span v-if="modelMultiSearch.dest_area_id" class="textbox-icon icon-clear" title="Clear value" @click="modelMultiSearch.dest_area_id=null;"></span>
                                    </Addon>
                                </ComboBox>
                            </td>
                        </tr>
                        <tr>
                            <td class="bg_subtitle">Req. Arrival Time</td>
                            <td class="bg_grid2">
                                <DateBox inputId="req_arrival_time_from" name="req_arrival_time_from" class="f-field-text" v-model="modelMultiSearch.req_arrival_time_from" format="dd/MM/yyyy">
                                    <Addon>
                                        <span v-if="modelMultiSearch.req_arrival_time_from" class="textbox-icon icon-clear" title="Clear value" @click="modelMultiSearch.req_arrival_time_from=null;"></span>
                                    </Addon>
                                </DateBox>
                            </td>
                            <td>to</td>
                            <td class="bg_grid2">
                                <DateBox inputId="req_arrival_time_to" name="req_arrival_time_to" class="f-field-text" v-model="modelMultiSearch.req_arrival_time_to" format="dd/MM/yyyy">
                                    <Addon>
                                        <span v-if="modelMultiSearch.req_arrival_time_to" class="textbox-icon icon-clear" title="Clear value" @click="modelMultiSearch.req_arrival_time_to=null;"></span>
                                    </Addon>
                                </DateBox>
                            </td>
                        </tr>
                        <tr>
                            <td class="bg_subtitle">Pref Vehicle Type</td>
                            <td class="bg_grid2" colspan="3">
                                <ComboBox  :data="vehicleTypes" @filterChange="optionVehicleType($event)" inputId="pref_vehicle_type" name="pref_vehicle_type" class="f-field-text" v-model="modelMultiSearch.pref_vehicle_type">
                                    <template slot="item" slot-scope="scope">
                                        <span style="font-weight:bold">{{scope.row.text}}</span><br/>
                                        <span style="color:#888">Weight : {{scope.row.weight_cap}}  Volume : {{scope.row.volume_cap}}</span><br/>
                                        <Addon>
                                            <span v-if="modelMultiSearch.pref_vehicle_type" class="textbox-icon icon-clear" title="Clear value" @click="modelMultiSearch.pref_vehicle_type=null;"></span>
                                        </Addon>
                                    </template>
                                </ComboBox>
                            </td>
                        </tr>
                        <tr>
                            <td class="bg_subtitle">Request Date</td>
                            <td class="bg_grid2">
                                <DateBox inputId="request_date_from" name="request_date_from" class="f-field-text" v-model="modelMultiSearch.request_date_from" format="dd/MM/yyyy">
                                    <Addon>
                                        <span v-if="modelMultiSearch.request_date_from" class="textbox-icon icon-clear" title="Clear value" @click="modelMultiSearch.request_date_from=null;"></span>
                                    </Addon>
                                </DateBox>
                            </td>
                            <td>to</td>
                            <td class="bg_grid2">
                                <DateBox inputId="request_date_to" name="request_date_to" class="f-field-text" v-model="modelMultiSearch.request_date_to" format="dd/MM/yyyy">
                                    <Addon>
                                        <span v-if="modelMultiSearch.request_date_to" class="textbox-icon icon-clear" title="Clear value" @click="modelMultiSearch.request_date_to=null;"></span>
                                    </Addon>
                                </DateBox>
                            </td>
                        </tr>
                        <tr>
                            <td class="bg_subtitle">Status Data</td>
                            <td class="bg_grid2" colspan="3">
                                <ComboBox  :data="statusData" inputId="tr_status" name="tr_status" class="f-field-text" v-model="modelMultiSearch.tr_status">
                                    <Addon>
                                        <span v-if="modelMultiSearch.tr_status" class="textbox-icon icon-clear" title="Clear value" @click="modelMultiSearch.tr_status=null;"></span>
                                    </Addon>
                                </ComboBox>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="dialog-button">
                <LinkButton :iconCls="iconUtil.iconSearch" @click="onSearchMulti()" style="width:89px">{{ informationUtil.searchText }}</LinkButton>
                <LinkButton :iconCls="iconUtil.iconCancel" @click="onResetSearchMulti()" style="width:89px">{{ informationUtil.resetText }}</LinkButton>
            </div>
        </Dialog>

        <!-- Form -->
        <Dialog ref="dlg" :iconCls="icon" bodyCls="f-column" :title="title" :draggable="true" :modal="true" closed :dialogStyle="{width:'750px', height:'600px'}">
            <div class="f-full" style="overflow:auto">
                <Form ref="form" :model="model" :rules="rules" @validate="errors=$event">
                    <fieldset class="c-fieldset">
                        <legend class="c-legend"><b>{{ titleMessage }}</b></legend>
                        <table width="100%" cellspacing="1" cellpadding="5" border="0">
                            <tbody>
                                <tr>
                                    <td>TR ID</td>
                                    <td style="width:35%;">
                                        <NumberBox inputId="trucking_order_no" name="trucking_order_no" class="f-field-text" v-model="modelNotMapping.trucking_order_no" :disabled="true" :spinners="false"></NumberBox>
                                    </td>
                                    <td>Request By</td>
                                    <td>
                                        <TextBox inputId="created_by" name="created_by" class="f-field-text" v-model="modelNotMapping.created_by" :disabled="true"></TextBox>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Manifest ID</td>
                                    <td>
                                        <NumberBox inputId="manifest_id" name="manifest_id" class="f-field-text" v-model="modelNotMapping.manifest_id" :disabled="true" :spinners="false">
                                            <Addon>
                                                <LinkButton v-if="modelNotMapping.manifest_id != null" :btnStyle="{borderRadius:0,borderWidth:'0 0 0 1px',width:'70px',height:'30px', backgroundColor:'#000'}" @click="onvIewManifest(modelNotMapping.manifest_id)">View</LinkButton>
                                            </Addon>
                                        </NumberBox>
                                    </td>
                                    <td>Request Time</td>
                                    <td>
                                        <TextBox inputId="created_date" name="created_date" class="f-field-text" v-model="modelNotMapping.created_date" :disabled="true"></TextBox>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Client ID <font color="red">*</font>
                                    </td>
                                    <td> 
                                        <ComboBox  :data="clients" inputId="client_id" name="client_id" @filterChange="optionClient($event)" class="f-field-text" v-model="model.client_id" :disabled="disableManifestConfirm">
                                            <template slot="item" slot-scope="scope">
                                                <span style="font-weight:bold">{{scope.row.text}}</span><br/>
                                                <span style="color:#888">{{scope.row.address}}</span><br/>
                                            </template>
                                        </ComboBox>
                                        <div class="error">{{textError.client_id}}</div>
                                    </td>
                                    <td>Schedule Date <font color="red">*</font></td>
                                    <td>
                                        <DateBox inputId="schedule_date" name="schedule_date" class="f-field-text" v-model="model.schedule_date" format="dd/MM/yyyy"></DateBox>
                                        <div class="error">{{textError.schedule_date}}</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Transport Mode <font color="red">*</font></td>
                                    <td>
                                        <ComboBox  :data="transportModes" inputId="transport_mode" name="transport_mode" class="f-field-text" v-model="model.transport_mode"></ComboBox>
                                        <div class="error">{{textError.transport_mode}}</div>
                                    </td>
                                    <td>Preferred Vehicle Type <font color="red">*</font></td>
                                    <td>
                                        <ComboBox  :data="vehicleTypes" inputId="pref_vehicle_type" name="pref_vehicle_type" @filterChange="optionVehicleType($event)" class="f-field-text" v-model="model.pref_vehicle_type" :disabled="disabled">
                                            <template slot="item" slot-scope="scope">
                                                <span style="font-weight:bold">{{scope.row.text}}</span><br/>
                                                <span style="color:#888">Weight : {{scope.row.weight_cap}}  Volume : {{scope.row.volume_cap}}</span><br/>
                                            </template>
                                        </ComboBox>
                                        <div class="error">{{textError.pref_vehicle_type}}</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Budget</td>
                                    <td>
                                        <NumberBox inputId="budget" name="budget" class="f-field-text" v-model="model.budget" :spinners="false"></NumberBox>
                                    </td>
                                    <td>TR Status</td>
                                    <td>
                                        <TextBox inputId="tr_status_desc" name="tr_status_desc" class="f-field-text" v-model="modelNotMapping.tr_status_desc" :disabled="true"></TextBox>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </fieldset>
                    <fieldset class="c-fieldset">
                        <legend class="c-legend"><b>Details Information</b></legend>
                        <Tabs style="height:263px">
                            <TabPanel :title="'Trucking Option'">
                                <div class="datagrid-toolbar" style="margin-bottom: 10px;">
                                    <LinkButton :plain="true" :iconCls="iconUtil.iconEdit" @click="onEditVehicle()">{{ btnText.edit }}</LinkButton>
                                </div>
                                <DataGrid
                                    ref="dataGridVehicle"
                                    :columnResizing="true"
                                    :border="false"                                    
                                    :data="dataGridVehicle.rows"
                                    @rowDblClick="onRowVehicleDblClick()"
                                    :selectionMode="dataGridVehicle.selectionMode"
                                    style="width:676px;height:65px"
                                    >
                                    <GridColumnGroup :frozen="true" align="left" width="370">
                                        <GridHeaderRow>
                                            <GridColumn field="vehicle_desc" title="Vehicle Id" :frozen="true" width="90"></GridColumn>
                                            <GridColumn field="driver_desc" title="Driver" :frozen="true" width="140"></GridColumn>
                                            <GridColumn field="co_driver_desc" title="Co Driver" :frozen="true" width="140"></GridColumn>
                                        </GridHeaderRow>
                                    </GridColumnGroup>
                                    <GridColumnGroup>
                                        <GridHeaderRow>
                                            <GridColumn field="transporter_desc" title="Transporter Id" width="130"></GridColumn>
                                            <GridColumn field="status_desc" title="Status" width="100"></GridColumn>
                                            <GridColumn field="type_desc" title="Type" width="100"></GridColumn>
                                            <GridColumn field="max_volume" title="Max Volume" width="100"></GridColumn>
                                            <GridColumn field="max_weight" title="Max Weight" width="100"></GridColumn>
                                        </GridHeaderRow>
                                    </GridColumnGroup>                            
                                </DataGrid>
                            </TabPanel>
                            <TabPanel :title="'Order Summary'">
                                <table width="100%" cellspacing="1" cellpadding="5" border="0">
                                    <tbody>
                                        <tr>
                                            <td>Origin ID <font color="red">*</font></td>
                                            <td>
                                                <ComboBox  :data="customer_origins" inputId="origin_id" name="origin_id" @filterChange="optionCustomerOrigin($event)" @selectionChange="onSelectionCustomerOrigin($event)" class="f-field-text" v-model="model.origin_id" :disabled="disableManifestConfirm">
                                                    <template slot="item" slot-scope="scope">
                                                        <span style="font-weight:bold">{{scope.row.text}}</span><br/>
                                                        <span style="color:#888">{{scope.row.name}}</span><br/>
                                                        <span style="color:#888">{{scope.row.address}}</span><br/>
                                                    </template>
                                                </ComboBox>
                                                <div class="error">{{textError.origin_id}}</div>
                                            </td>
                                            <td>Destination ID <font color="red">*</font></td>
                                            <td>
                                                <ComboBox  :data="customer_destinations" inputId="dest_id" name="dest_id" @filterChange="optionCustomerDestination($event)" @selectionChange="onSelectionCustomerDestination($event)" class="f-field-text" v-model="model.dest_id" :disabled="disableManifestConfirm"> 
                                                    <template slot="item" slot-scope="scope">
                                                        <span style="font-weight:bold">{{scope.row.text}}</span><br/>
                                                        <span style="color:#888">{{scope.row.name}}</span><br/>
                                                        <span style="color:#888">{{scope.row.address}}</span><br/>
                                                    </template>
                                                </ComboBox>
                                                <div class="error">{{textError.dest_id}}</div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Origin Name</td>
                                            <td>
                                                <TextBox inputId="origin_customer_name" name="origin_customer_name" class="f-field-text" v-model="modelNotMapping.origin_customer_name" :disabled="true"></TextBox>
                                            </td>
                                            <td>Destination Name</td>
                                            <td>
                                                <TextBox inputId="dest_customer_name" name="dest_customer_name" class="f-field-text" v-model="modelNotMapping.dest_customer_name" :disabled="true"></TextBox>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Origin Address</td>
                                            <td>
                                                <TextBox inputId="origin_customer_address" name="origin_customer_address" class="f-field-text" v-model="modelNotMapping.origin_customer_address" :disabled="true"></TextBox>
                                            </td>
                                            <td>Destination Address</td>
                                            <td>
                                                <TextBox inputId="dest_customer_address" name="dest_customer_address" class="f-field-text" v-model="modelNotMapping.dest_customer_address" :disabled="true"></TextBox>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Origin Area <font color="red">*</font></td>
                                            <td>
                                                <ComboBox  :data="origins" inputId="origin_area_id" name="origin_area_id" @filterChange="optionOrigin($event)" class="f-field-text" v-model="model.origin_area_id" :disabled="disableManifestConfirm"></ComboBox>
                                                <div class="error">{{textError.origin_area_id}}</div>
                                            </td>
                                            <td>Dest Area <font color="red">*</font></td>
                                            <td>
                                                <ComboBox  :data="destinations" inputId="dest_area_id" name="dest_area_id" @filterChange="optionDestination($event)" class="f-field-text" v-model="model.dest_area_id" :disabled="disableManifestConfirm"></ComboBox>
                                                <div class="error">{{textError.dest_area_id}}</div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Req. Pickup Time <font color="red">*</font></td>
                                            <td>
                                                <DateBox inputId="req_pickup_time" name="req_pickup_time" class="f-field-text" v-model="model.req_pickup_time" format="dd/MM/yyyy"></DateBox>
                                                <div class="error">{{textError.req_pickup_time}}</div>
                                            </td>
                                            <td>Req. Arrival Time <font color="red">*</font></td>
                                            <td>
                                                <DateBox inputId="req_arrival_time" name="req_arrival_time" class="f-field-text" v-model="model.req_arrival_time" format="dd/MM/yyyy"></DateBox>
                                                <div class="error">{{textError.req_arrival_time}}</div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </TabPanel>
                            <TabPanel :title="'Log'">
                                <table width="100%" cellspacing="1" cellpadding="5" border="0">
                                    <tbody>
                                        <tr>
                                            <td>
                                                Updated By
                                            </td>
                                            <td>
                                                <TextBox inputId="updated_by" name="updated_by" class="f-field-text" :value="modelNotMapping.updated_by" :disabled="true"></TextBox>
                                            </td>
                                            <td>
                                                Updated Date
                                            </td>
                                            <td>
                                                <TextBox inputId="updated_date" name="updated_date" class="f-field-text" :value="modelNotMapping.updated_date" :disabled="true"></TextBox>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </TabPanel>
                        </Tabs>
                    </fieldset>
                </Form>
            </div>
            <div class="dialog-button">
                <LinkButton :iconCls="iconUtil.iconSave" v-if="action.add || action.edit" @click="onSave()" :disabled="disableBtnSave" style="width:89px">{{ btnText.save }}</LinkButton>
                <LinkButton :iconCls="iconUtil.iconCancel" @click="$refs.dlg.close()" style="width:89px">{{ btnText.cancel }}</LinkButton>
            </div>
        </Dialog>

        <Dialog ref="dlgVehicle" :iconCls="icon" bodyCls="f-column" :title="titleVehicle" :draggable="true" :modal="true" closed :dialogStyle="{width:'350px', height:'auto'}">
            <div class="f-full" style="overflow:auto">
                <Form ref="formVehicle" :model="modelVehicle" :rules="rulesVehicle" @validate="errorsVehicle=$event">
                    <table width="100%" cellspacing="1" cellpadding="5" border="0">
                        <tbody>
                            <tr>
                                <td class="bg_subtitle">Vehicle ID <font color="red">*</font></td>
                                <td class="bg_grid2">
                                    <ComboBox  :data="vehicles" inputId="vehicle_id" name="vehicle_id" @filterChange="optionVehicle($event)" class="f-field-text" v-model="modelVehicle.vehicle_id" :disabled="disableManifestConfirm">
                                        <template slot="item" slot-scope="scope">
                                            <span style="font-weight:bold">{{scope.row.text}}</span><br/>
                                            <span style="color:#888">{{scope.row.vehicleType}}</span><br/>
                                            <span style="color:#888">{{scope.row.transporter}} ({{scope.row.status}})</span><br/>
                                            <span style="color:#888">Max Weight : {{scope.row.max_weight}}  Max Volume : {{scope.row.max_volume}}</span><br/>
                                        </template>
                                    </ComboBox>
                                    <div class="error">{{textErrorVehicle.vehicle_id}}</div>
                                </td>
                            </tr>
                            <tr>
                                <td class="bg_subtitle">
                                    Driver <font color="red">*</font>
                                </td>
                                <td class="bg_grid2"> 
                                    <ComboBox  :data="drivers" inputId="driver_id" name="driver_id" @filterChange="optionDriver($event)" class="f-field-text" v-model="modelVehicle.driver_id" :disabled="disableManifestSelfBilling">
                                        <template slot="item" slot-scope="scope">
                                            <span style="font-weight:bold">{{scope.row.text}}</span><br/>
                                            <span style="color:#888">{{scope.row.transporter}}</span><br/>
                                        </template>
                                    </ComboBox>
                                    <div class="error">{{textErrorVehicle.driver_id}}</div>
                                </td>
                            </tr>
                            <tr>
                                <td class="bg_subtitle">Co Driver <font color="red">*</font></td>
                                <td class="bg_grid2">
                                    <ComboBox  :data="coDriver" inputId="co_driver_id" name="co_driver_id" @filterChange="optionCoDriver($event)" class="f-field-text" v-model="modelVehicle.co_driver_id" :disabled="disableManifestSelfBilling">
                                        <template slot="item" slot-scope="scope">
                                            <span style="font-weight:bold">{{scope.row.text}}</span><br/>
                                            <span style="color:#888">{{scope.row.transporter}}</span><br/>
                                        </template>
                                    </ComboBox>
                                    <div class="error">{{textErrorVehicle.co_driver_id}}</div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </Form>
            </div>
            <div class="dialog-button">
                <LinkButton :iconCls="iconUtil.iconSave" @click="onSaveVehicle()" :disabled="disableBtnSaveVehicle" style="width:89px">{{ btnText.save }}</LinkButton>
                <LinkButton :iconCls="iconUtil.iconCancel" @click="$refs.dlgVehicle.close()" style="width:89px">{{ btnText.cancel }}</LinkButton>
            </div>
        </Dialog>

        <NotificationComponent ref="notification"></NotificationComponent>
        <FormManifestComponent ref="formManifest" actionData="route_planning" @onSaveDataVehicle="onLoadVehicle"></FormManifestComponent>
    </div>
    
</template>

<script>
import TruckingOrder from '@/models/TruckingOrder';
import TransportService from '@/services/TransportService';
import ModelUtil from '@/utils/ModelUtil';
import InformationUtil from '@/utils/InformationUtil';
import AccessUtil from '@/utils/AccessUtil';
import IconUtil from '@/utils/IconUtil';
import GlobalUtil from '@/utils/GlobalUtil';

import OptionService from '@/services/OptionService';

// component
import FormManifestComponent from '@/components/forms/FormManifestComponent.vue';
import NotificationComponent from '@/components/NotificationComponent.vue';

export default {
    name: 'TruckingOrderView',
    components : {
        NotificationComponent,
        FormManifestComponent
    },
    data() {
        return {
            titleView : InformationUtil.truckingOrder + "s",
            titleMessage : InformationUtil.truckingOrder,
            titleVehicle : InformationUtil.editText + " " + InformationUtil.vehicle,

            iconUtil : IconUtil,
            informationUtil : InformationUtil,

            titleForm :{
                add : InformationUtil.addText,
                edit : InformationUtil.editText
            },

            iconForm :{
                add : IconUtil.iconAdd,
                edit : IconUtil.iconEdit
            },
            
            action : {
                view : false,
                add : false,
                edit : false,
                delete : false
            },

            btnText :{
                refresh : InformationUtil.refreshText,
                add : InformationUtil.addText,
                edit : InformationUtil.editText,
                delete : InformationUtil.removeText,
                save : InformationUtil.saveText,
                cancel : InformationUtil.cancelText,
                search : InformationUtil.searchText
            },

            search : {
                text : InformationUtil.searching,
                placeholder : InformationUtil.searchHolder
            },
            
            modelMultiSearch : {
                client_id : "",
                origin_id : "",
                origin_area_id : "",
                dest_id : "",
                dest_area_id : "",
                req_pickup_time_from : null,
                req_pickup_time_to : null,
                req_arrival_time_from : null,
                req_arrival_time_to : null,
                pref_vehicle_type : "",
                schedule_date_from : null,
                schedule_date_to : null,
                request_date_from : null,
                request_date_to : null,
                tr_status : null,
            },

            dataGrid : {
                loading: false,
                rows : [],
                total: 0,
                start: 0,
                pageNumber: 1,
                pageSize: 10,
                selectionMode : 'single',
                pageLayout: ['list','sep','first','prev','next','last','sep','refresh','info'],

                // filter
                search : null,
                sortField : "",
                sortOrder : ""
            },

            dataGridVehicle : {
                loading: false,
                rows : [],
                total: 0,
                start: 0,
                pageNumber: 1,
                pageSize: 5,
                selectionMode : 'single',

                // filter
                search : "",
                sortField : "",
                sortOrder : "",
                id : null
            },

            // form
            title: '',
            icon: '',
            model: TruckingOrder,
            disabled : false,
            disableManifestConfirm : false,
            disableManifestSelfBilling : false,
            disableBtnSave : false,
            modelNotMapping : {
                trucking_order_no : 0,
                manifest_id : 0,
                manifest_status : 0,
                created_by : "",
                updated_by : "",
                created_date : "",
                updated_date : "",
                tr_status_desc : "",
                origin_customer_name : "",
                dest_customer_name : "",
                origin_customer_address : "",
                dest_customer_address : "",
                invpch_hash_id:null,
            },

            // validation
            rules: {
                client_id : 'required',
                transport_mode : 'required',
                schedule_date : 'required',
                pref_vehicle_type : 'required',
                origin_id : 'required',
                dest_id : 'required',
                origin_area_id : 'required',
                dest_area_id : 'required',
                req_pickup_time : 'required',
                req_arrival_time : 'required',
            },
            errors: {},
            textError :{
                client_id : '',
                transport_mode : '',
                schedule_date : '',
                pref_vehicle_type : '',
                origin_id : '',
                dest_id : '',
                origin_area_id : '',
                dest_area_id : '',
                req_pickup_time : '',
                req_arrival_time : '',
            },

            disableBtnSaveVehicle : false,
            modelVehicle : {
                id : null,
                vehicle_id : null,
                driver_id : null,
                co_driver_id : null
            },
            rulesVehicle: {
                vehicle_id: 'required',
                driver_id: 'required',
                co_driver_id: 'required'
            },
            errorsVehicle: {},
            textErrorVehicle :{
                vehicle_id: '',
                driver_id: '',
                co_driver_id: ''
            },

            // combobox
            transportModes: [
                { value :1, text :"LAND"},
                { value :2, text :"AIR"},
                { value :3, text :"SEA"},
                { value :4, text :"RAILWAY"},
            ],
            clients : [],
            vehicleTypes : [],
            customer_origins : [],
            customer_destinations : [],
            origins : [],
            destinations : [],

            drivers : [],
            coDriver : [],
            vehicles : [],
            statusData : [
                { value: 0 , text: 'OPEN' },
                { value: 1 , text: 'CLOSE' },
            ],
        }
    },
    methods:{
        onTabSelected(){
            this.dataGrid.search = null;
            this.onRefresh();
        },
        onvIewManifest(id){
            if(id != null){
                this.$refs.formManifest.onPageShow(id);
            }
        },
        getError(name) {
            return this.errors[name] && this.errors[name].length
            ? this.errors[name][0]
            : null;
        },
        getErrorVehicle(name) {
            return this.errorsVehicle[name] && this.errorsVehicle[name].length ? this.errorsVehicle[name][0] : null;
        },
        onAdd(){
            ModelUtil.resetModel(this.textError);
            // method for add data
            this.disabled = false;
            this.$refs.dataGrid.unselectRow(this.$refs.dataGrid.selectedRows[0]);
            this.model = ModelUtil.resetModel(this.model);
            this.modelNotMapping = ModelUtil.resetModel(this.modelNotMapping);
            this.onCallAllOption();

            // set data default
            this.model.transport_mode = 1; 
            this.modelNotMapping.trucking_order_no = parseInt(this.modelNotMapping.trucking_order_no);
            this.model.schedule_date = new Date();
            this.model.req_pickup_time = new Date();
            this.model.req_arrival_time = new Date();
            this.model.budget = parseInt(this.model.budget);

            this.title = this.titleForm.add;
            this.icon = this.iconForm.add;
            this.$refs.dlg.open();
        },
        onEdit(){
            ModelUtil.resetModel(this.textError);
            // method for get data to edit
            if(this.$refs.dataGrid.selectedRows.length > 0){
                this.disabled = true;
                this.title = this.titleForm.edit;
                this.icon = this.iconForm.edit;

                // additional for combo box
                this.clients = [{value: this.$refs.dataGrid.selectedRows[0].client_id, text: this.$refs.dataGrid.selectedRows[0].client_desc, address: this.$refs.dataGrid.selectedRows[0].client_address}];
                this.vehicleTypes = [{value: this.$refs.dataGrid.selectedRows[0].pref_vehicle_type, text: this.$refs.dataGrid.selectedRows[0].pref_vehicle_type_desc}]
                this.customer_origins = [{value: this.$refs.dataGrid.selectedRows[0].origin_id, 
                                        text: this.$refs.dataGrid.selectedRows[0].origin_customer_desc, 
                                        name : this.$refs.dataGrid.selectedRows[0].origin_customer_name, 
                                        address: this.$refs.dataGrid.selectedRows[0].origin_customer_address,
                                        area_id : this.$refs.dataGrid.selectedRows[0].origin_area_id,
                                        area_desc : this.$refs.dataGrid.selectedRows[0].origin_area_desc,
                                        area_description : this.$refs.dataGrid.selectedRows[0].origin_area_description
                                    }]
                this.customer_destinations = [{value: this.$refs.dataGrid.selectedRows[0].dest_id, 
                                            text: this.$refs.dataGrid.selectedRows[0].dest_customer_desc, 
                                            name : this.$refs.dataGrid.selectedRows[0].dest_customer_name, 
                                            address: this.$refs.dataGrid.selectedRows[0].dest_customer_address,
                                            area_id : this.$refs.dataGrid.selectedRows[0].dest_area_id,
                                            area_desc : this.$refs.dataGrid.selectedRows[0].dest_area_desc,
                                            area_description : this.$refs.dataGrid.selectedRows[0].dest_area_description
                                        }]
                this.origins = [{value: this.$refs.dataGrid.selectedRows[0].origin_area_id, text: this.$refs.dataGrid.selectedRows[0].origin_area_desc +" - "+this.$refs.dataGrid.selectedRows[0].origin_area_description}]
                this.destinations = [{value: this.$refs.dataGrid.selectedRows[0].dest_area_id, text: this.$refs.dataGrid.selectedRows[0].dest_area_desc +" - "+this.$refs.dataGrid.selectedRows[0].dest_area_description}]

                this.model = ModelUtil.resetModel(this.model);
                this.model = ModelUtil.mapModel(this.model, this.$refs.dataGrid.selectedRows[0])
                this.modelNotMapping = ModelUtil.resetModel(this.modelNotMapping);
                this.modelNotMapping = ModelUtil.mapModel(this.modelNotMapping, this.$refs.dataGrid.selectedRows[0])

                // mapping data
                this.modelNotMapping.trucking_order_no = parseInt(this.modelNotMapping.trucking_order_no);
                this.modelNotMapping.manifest_id = parseInt(this.modelNotMapping.manifest_id);
                this.model.budget = parseInt(this.model.budget);

                this.model.schedule_date = new Date(this.model.schedule_date);
                this.model.req_pickup_time = new Date(this.model.req_pickup_time);
                this.model.req_arrival_time = new Date(this.model.req_arrival_time);

                this.disableManifestConfirm = this.modelNotMapping.manifest_status > 0 ? true : false;
                this.disableManifestSelfBilling = this.modelNotMapping.invpch_hash_id != null && this.modelNotMapping.invpch_hash_id != '' ? true : false;

                this.onLoadVehicle();
                this.$refs.dlg.open();
            }else{
                this.$refs.notification.centerTop(InformationUtil.selectRow);
            }
        },
        onDelete(){
            if(this.$refs.dataGrid.selectedRows.length > 0){

                this.$messager.confirm({
                    title: 'Confirm',
                    msg: InformationUtil.confirmDelete,
                    result: (r) => {
                        if (r){
                            this.dataGrid.loading = true;
                            TransportService.deleteData("/truckingOrder", this.$refs.dataGrid.selectedRows[0].id)
                                .then((response) => {
                                    this.dataGrid.loading = false;
                                    this.$refs.dlg.close();
                                    if(response.code == 200){
                                        this.$refs.notification.success(this.titleMessage + " " + InformationUtil.delete);
                                        this.loadData();
                                    }else{
                                        this.$messager.alert({
                                            title: "Error",
                                            icon: "error",
                                            msg: response.message
                                        });
                                    }
                                }).catch((err) => {
                                    this.$messager.alert({
                                            title: "Error",
                                            icon: "error",
                                            msg: err.message
                                        });
                                });
                            this.$refs.dataGrid.unselectRow(this.$refs.dataGrid.selectedRows[0]) 
                        }
                    }
                })
            }else{
                this.$refs.notification.centerTop(InformationUtil.selectRow);
            }
        },
        onSave(){
            ModelUtil.resetModel(this.textError);
            this.$refs.form.validate((valid) => {
                if(!valid){
                    this.disableBtnSave = true;
                    this.model.schedule_date = new Date(this.$moment(this.model.schedule_date).format('YYYY-MM-DD'));
                    this.model.req_pickup_time = new Date(this.$moment(this.model.req_pickup_time).format('YYYY-MM-DD'));
                    this.model.req_arrival_time = new Date(this.$moment(this.model.req_arrival_time).format('YYYY-MM-DD'));
                    if(this.model.id == null){
                        // action add data
                        TransportService.addData("/truckingOrder", this.model)
                            .then((res) => {
                                this.disableBtnSave = false;
                                if(res.code == 200){
                                    this.$refs.notification.success(this.titleMessage + " " + InformationUtil.add);
                                    this.loadData();
                                    this.$refs.dlg.close();
                                } else {
                                    this.$messager.alert({
                                        title: "Error",
                                        icon: "error",
                                        msg: res.message
                                    });
                                }
                            }).catch((error) => {
                                this.disableBtnSave = false;
                                this.$refs.notification.error(error);
                            });

                    }else{
                        // action update data
                        TransportService.updateData("/truckingOrder", this.model.id, this.model)
                            .then((res) => {
                                this.disableBtnSave = false;
                                if(res.code == 200){
                                    this.$refs.notification.success(this.titleMessage + " " + InformationUtil.update);
                                    this.loadData();
                                    this.$refs.dlg.close();
                                } else {
                                    this.$messager.alert({
                                        title: "Error",
                                        icon: "error",
                                        msg: res.message
                                    });
                                }
                            }).catch((error) => {
                                this.disableBtnSave = false;
                                this.$refs.notification.error(error);
                            });
                    }
                    this.$refs.dataGrid.unselectRow(this.$refs.dataGrid.selectedRows[0])
                } else {
                    for(let key in valid){
                        this.textError[key] = this.getError(key);
                    }
                }
            });
            
        },
        
        // handle data grid
        onMultiSearch(){
            this.onCallAllOption();
            this.$refs.dlgSearch.open();
        },
        onSearchMulti(){
            this.dataGrid.pageNumber = 1;
            this.$refs.dataGrid.unselectRow(this.$refs.dataGrid.selectedRows[0]);

            // set date format
            this.modelMultiSearch.schedule_date_from = this.modelMultiSearch.schedule_date_from != null ? new Date(this.$moment(this.modelMultiSearch.schedule_date_from).format('YYYY-MM-DD')) : null;
            this.modelMultiSearch.schedule_date_to = this.modelMultiSearch.schedule_date_to != null ? new Date(this.$moment(this.modelMultiSearch.schedule_date_to).format('YYYY-MM-DD')) : null;
            this.modelMultiSearch.req_pickup_time_from = this.modelMultiSearch.req_pickup_time_from != null ? new Date(this.$moment(this.modelMultiSearch.req_pickup_time_from).format('YYYY-MM-DD')) : null;
            this.modelMultiSearch.req_pickup_time_to = this.modelMultiSearch.req_pickup_time_to != null ? new Date(this.$moment(this.modelMultiSearch.req_pickup_time_to).format('YYYY-MM-DD')) : null;
            this.modelMultiSearch.req_arrival_time_from = this.modelMultiSearch.req_arrival_time_from != null ? new Date(this.$moment(this.modelMultiSearch.req_arrival_time_from).format('YYYY-MM-DD')) : null;
            this.modelMultiSearch.req_arrival_time_to = this.modelMultiSearch.req_arrival_time_to != null ? new Date(this.$moment(this.modelMultiSearch.req_arrival_time_to).format('YYYY-MM-DD')) : null;
            this.modelMultiSearch.request_date_from = this.modelMultiSearch.request_date_from != null ? new Date(this.$moment(this.modelMultiSearch.request_date_from).format('YYYY-MM-DD')) : null;
            this.modelMultiSearch.request_date_to = this.modelMultiSearch.request_date_to != null ? new Date(this.$moment(this.modelMultiSearch.request_date_to).format('YYYY-MM-DD')) : null;
            
            this.loadData();
            this.$refs.dlgSearch.close();
        },
        onResetSearchMulti(){
            this.modelMultiSearch = ModelUtil.resetModel(this.modelMultiSearch);

            this.modelMultiSearch.schedule_date_from = null;
            this.modelMultiSearch.schedule_date_to = null;
            this.modelMultiSearch.req_pickup_time_from = null;
            this.modelMultiSearch.req_pickup_time_to = null;
            this.modelMultiSearch.req_arrival_time_from = null;
            this.modelMultiSearch.req_arrival_time_to = null;
            this.modelMultiSearch.request_date_from = null;
            this.modelMultiSearch.request_date_to = null;

            this.dataGrid.pageNumber = 1;
            this.$refs.dataGrid.unselectRow(this.$refs.dataGrid.selectedRows[0]);
            this.loadData();
            this.$refs.dlgSearch.close();
        },
        onRowDblClick(){
            if(this.action.edit){
                this.onEdit();
            }
        },
        async onRefresh(){
            this.dataGrid.pageNumber = 1;
            this.$refs.dataGrid.unselectRow(this.$refs.dataGrid.selectedRows[0]);
            await this.loadData();
            this.$refs.notification.success(InformationUtil.refresh);
        },
        onSearch(){
            this.loadData();
        },
        onSortChange(event){
            if(event.length > 0){
                this.dataGrid.pageNumber = 1;
                this.dataGrid.sortField = event[0].field;
                this.dataGrid.sortOrder = event[0].order;
                this.loadData();
            }
        },
        onPageChange(event) {
            this.$refs.dataGrid.unselectRow(this.$refs.dataGrid.selectedRows[0]);
            this.dataGrid.pageNumber = event.pageNumber;
            this.dataGrid.pageSize = event.pageSize;
            if(event.refresh) this.dataGrid.pageNumber = 1;
            this.loadData();
            if(event.refresh){
                this.$refs.notification.success(InformationUtil.refresh);
            }
        },

        async loadData(){
            this.dataGrid.loading = true;
            return TransportService.getData('/truckingOrder', this.dataGrid, {multiSearch : this.modelMultiSearch})
                .then((response) => {
                    if(response.data !== "" && response.data !== null){
                        let data = response.data; 
                        this.dataGrid.total = data.total;
                        this.dataGrid.rows = data.rows;
                    }
                    
                    this.dataGrid.loading = false;
                });
        },

        onEditVehicle(){
            this.disableBtnSaveVehicle = this.modelNotMapping.invpch_hash_id != null && this.modelNotMapping.invpch_hash_id != ''  > 0 ? true : false;
            this.textErrorVehicle = ModelUtil.resetModel(this.textErrorVehicle);
            if(this.$refs.dataGridVehicle.selectedRows.length > 0){
                this.vehicles = [{value: this.$refs.dataGridVehicle.selectedRows[0].vehicle_id, text: this.$refs.dataGridVehicle.selectedRows[0].vehicle_desc}]
                this.drivers = [{value: this.$refs.dataGridVehicle.selectedRows[0].driver_id, text: this.$refs.dataGridVehicle.selectedRows[0].driver_desc}]
                this.coDriver = [{value: this.$refs.dataGridVehicle.selectedRows[0].co_driver_id, text: this.$refs.dataGridVehicle.selectedRows[0].co_driver_desc}]

                this.onCallAllOptionVehicle();
                
                this.$refs.dlgVehicle.open();
            }else{
                this.$messager.alert({
                                    title: "Info",
                                    icon: "info",
                                    msg: InformationUtil.selectRow
                                });
            }
        },
        onRowVehicleDblClick(){
            this.onEditVehicle();
        },
        onSaveVehicle(){
            this.$refs.formVehicle.validate((valid) => {
                if(!valid){
                    // save data
                    this.disableBtnSaveVehicle = true;
                    this.modelVehicle.id = this.$refs.dataGridVehicle.selectedRows[0].id;
                    // action update data
                    TransportService.post("/manifest/updateVehicle", this.modelVehicle)
                        .then((res) => {
                            this.disableBtnSaveVehicle = false;
                            if(res.code == 200){
                                this.textErrorVehicle = ModelUtil.resetModel(this.textErrorVehicle);
                                this.$refs.notification.success(this.titleMessage + " " + InformationUtil.update);
                                this.onLoadVehicle();
                                this.$refs.dlgVehicle.close();
                            } else {
                                this.$messager.alert({
                                    title: "Error",
                                    icon: "error",
                                    msg: res.message
                                });
                            }
                        }).catch((error) => {
                            this.disableBtnSaveVehicle = false;
                            this.$refs.notification.error(error);
                        });

                } else {
                    for(let key in valid){
                        this.textErrorVehicle[key] = this.getErrorVehicle(key);
                    }
                }
            });
        },
        onLoadVehicle(){
            this.dataGridVehicle.id = this.modelNotMapping.manifest_id;
            return TransportService.post('/manifest/showVehicle', this.dataGridVehicle)
                .then((response) => {
                    let dataResponse = response;
                    this.dataGridVehicle.loading = false;
                    if(dataResponse.data !== "" && dataResponse.data !== null){
                        if(dataResponse.code == 200){
                            let data = dataResponse.data;
                            this.dataGridVehicle.total = data.total;
                            this.dataGridVehicle.rows = data.rows;
                            
                            if(data.rows.length > 0){
                                this.modelVehicle = ModelUtil.resetModel(this.modelVehicle);
                                this.modelVehicle = ModelUtil.mapModel(this.modelVehicle, data.rows[0]);
                            }

                        }else{
                            this.$messager.alert({
                                title: "Error",
                                icon: "error",
                                msg: dataResponse.message
                            });
                        }
                    }
                });
        },

        // combo box data
        async optionClient(event){
            let search = event == null ? "" : event.filterValue;
            return await OptionService.getOption("/client", search)
                .then((response) => {
                    this.clients = response.data;
                }).catch((error) => {
                    this.$refs.notification.error(error);
                });
        },
        async optionCustomerOrigin(event){
            let search = event == null ? "" : event.filterValue;
            return await OptionService.getOption("/customer", search)
                .then((response) => {
                    this.customer_origins = response.data;
                }).catch((error) => {
                    this.$refs.notification.error(error);
                });
        },
        async optionCustomerDestination(event){
            let search = event == null ? "" : event.filterValue;
            return await OptionService.getOption("/customer", search)
                .then((response) => {
                    this.customer_destinations = response.data;
                }).catch((error) => {
                    this.$refs.notification.error(error);
                });
        },
        async optionOrigin(event){
            let search = event == null ? "" : event.filterValue;
            return await OptionService.getOption("/area", search)
                .then((response) => {
                    this.origins = response.data;
                }).catch((error) => {
                    this.$refs.notification.error(error);
                });
        },
        async optionDestination(event){
            let search = event == null ? "" : event.filterValue;
            return await OptionService.getOption("/area", search)
                .then((response) => {
                    this.destinations = response.data;
                }).catch((error) => {
                    this.$refs.notification.error(error);
                });
        },
        async optionVehicleType(event){
            let search = event == null ? "" : event.filterValue;
            return await OptionService.getOption("/vehicleType", search)
                .then((response) => {
                    this.vehicleTypes = response.data;
                }).catch((error) => {
                    this.$refs.notification.error(error);
                });
        },
        async optionDriver(event){
            let search = event == null ? "" : event.filterValue;
            return await OptionService.getOption("/driver", search)
                .then((response) => {
                    this.drivers = response.data;
                }).catch((error) => {
                    this.$refs.notification.error(error);
                });
        },
        async optionCoDriver(event){
            let search = event == null ? "" : event.filterValue;
            return await OptionService.getOption("/driver", search)
                .then((response) => {
                    this.coDriver = response.data;
                }).catch((error) => {
                    this.$refs.notification.error(error);
                });
        },
        async optionVehicle(event){
            let search = event == null ? "" : event.filterValue;
            return await OptionService.getOption("/vehicle", search)
                .then((response) => {
                    this.vehicles = response.data;
                }).catch((error) => {
                    this.$refs.notification.error(error);
                });
        },
        onCallAllOptionVehicle(){
            this.optionDriver();
            this.optionCoDriver();
            this.optionVehicle();
        },
        onCallAllOption(){
            this.optionClient();
            this.optionCustomerOrigin();
            this.optionCustomerDestination();
            this.optionOrigin();
            this.optionDestination();
            this.optionVehicleType();
        },
        onSelectionCustomerOrigin(row){
            if(row != null){
                this.modelNotMapping.origin_customer_name = row.name;
                this.modelNotMapping.origin_customer_address = row.address;
                this.origins = [{value: row.area_id, text: row.area_desc +" - "+row.area_description}]
                this.model.origin_area_id = row.area_id;
            }
        },
        onSelectionCustomerDestination(row){
            if(row != null){
                this.modelNotMapping.dest_customer_name = row.name;
                this.modelNotMapping.dest_customer_address = row.address;
                this.destinations = [{value: row.area_id, text: row.area_desc +" - "+row.area_description}]
                this.model.dest_area_id = row.area_id;
            }
        },
    },
    async mounted(){
        this.action = AccessUtil.getAccessAction('trucking_order');
        await this.loadData();
        this.$refs.notification.success(InformationUtil.loadGrid + " " + this.dataGrid.total + " " + InformationUtil.successLoadGrid);
    },
    created(){
        GlobalUtil.removeWindow();
    }
};
</script>