<template>
    <div>
        <Dialog ref="dlg" :iconCls="icon" bodyCls="f-column" :title="title" :draggable="true" :modal="true" closed :dialogStyle="{width:'750px', height:'600px'}">
            <div class="f-full" style="overflow:auto">
                <Form ref="form" :model="model">
                    <fieldset class="c-fieldset">
                        <legend class="c-legend"><b>{{ titleLegend }}</b></legend>
                        <table width="100%" cellspacing="1" cellpadding="5" border="0">
                            <tbody>
                                <tr>
                                    <td style="width:10%;">Point ID</td>
                                    <td style="width:65%;">
                                        {{modelNotMapping.customer_id}}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Name</td>
                                    <td>
                                        {{modelNotMapping.customer_name}}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Address</td>
                                    <td>
                                        {{modelNotMapping.customer_address}}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </fieldset>
                    <fieldset class="c-fieldset">
                        <legend class="c-legend"><b>Details Information</b></legend>
                        <Tabs style="height:305px">
                            <TabPanel :title="'Time Stamp'">
                                <table width="100%" cellspacing="1" cellpadding="5" border="0">
                                    <tbody>
                                        <tr>
                                            <td rowspan="3" style="width:10%; vertical-align: top; padding-left: 25px; padding-top: 10px;">Arrival</td>
                                            <td style="width:10%;">Estimation</td>
                                            <td style="width:30%;">
                                                <DateBox inputId="arrival_eta" name="arrival_eta" class="f-field-text text-40" v-model="model.arrival_eta" format="dd/MM/yyyy">
                                                    <Addon>
                                                        <span v-if="model.arrival_eta" class="textbox-icon icon-clear" title="Clear value" @click="model.arrival_eta=null;"></span>
                                                    </Addon>
                                                </DateBox>
                                                <span class="f-field timepicker f-field-text textbox f-inline-row" style="width:100px;">
                                                    <input inputId="arrival_etatime" name="arrival_etatime" class="f-field-text text-box" v-model="model.arrival_etatime" type="time" step="2" style="width:100px; border: none;"/>
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Actual</td>
                                            <td>
                                                <DateBox inputId="arrival_ata" name="arrival_ata" class="f-field-text text-40" v-model="model.arrival_ata" format="dd/MM/yyyy">
                                                    <Addon>
                                                        <span v-if="model.arrival_ata" class="textbox-icon icon-clear" title="Clear value" @click="model.arrival_ata=null;"></span>
                                                    </Addon>
                                                </DateBox>
                                                <span class="f-field timepicker f-field-text textbox f-inline-row" style="width:100px;">
                                                    <input inputId="arrival_atatime" name="arrival_atatime" class="f-field-text text-box" v-model="model.arrival_atatime" type="time" step="1" style="width:100px; border: none;"/>
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>SPM Submit</td>
                                            <td>
                                                <DateBox inputId="spm_submit" name="spm_submit" class="f-field-text text-40" v-model="model.spm_submit" format="dd/MM/yyyy">
                                                    <Addon>
                                                        <span v-if="model.spm_submit" class="textbox-icon icon-clear" title="Clear value" @click="model.spm_submit=null;"></span>
                                                    </Addon>
                                                </DateBox>
                                                <span class="f-field timepicker f-field-text textbox f-inline-row" style="width:100px;">
                                                    <input inputId="spm_submittime" name="spm_submittime" class="f-field-text text-box" v-model="model.spm_submittime" type="time" step="2" style="width:100px; border: none;"/>
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td rowspan="3" style="width:10%; vertical-align: top; padding-left: 25px; padding-top: 10px;">Loading</td>
                                            <td>Start</td>
                                            <td>
                                                <DateBox inputId="loading_start" name="loading_start" class="f-field-text text-40" v-model="model.loading_start" format="dd/MM/yyyy">
                                                    <Addon>
                                                        <span v-if="model.loading_start" class="textbox-icon icon-clear" title="Clear value" @click="model.loading_start=null;"></span>
                                                    </Addon>
                                                </DateBox>
                                                <span class="f-field timepicker f-field-text textbox f-inline-row" style="width:100px;">
                                                    <input inputId="loading_starttime" name="loading_starttime" class="f-field-text text-box" v-model="model.loading_starttime" type="time" step="2" style="width:100px; border: none;"/>
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Finish</td>
                                            <td>
                                                <DateBox inputId="loading_finish" name="loading_finish" class="f-field-text text-40" v-model="model.loading_finish" format="dd/MM/yyyy">
                                                    <Addon>
                                                        <span v-if="model.loading_finish" class="textbox-icon icon-clear" title="Clear value" @click="model.loading_finish=null;"></span>
                                                    </Addon>
                                                </DateBox>
                                                <span class="f-field timepicker f-field-text textbox f-inline-row" style="width:100px;">
                                                    <input inputId="loading_finishtime" name="loading_finishtime" class="f-field-text text-box" v-model="model.loading_finishtime" type="time" step="2" style="width:100px; border: none;"/>
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Documentation</td>
                                            <td>
                                                <DateBox inputId="documentation" name="documentation" class="f-field-text text-40" v-model="model.documentation" format="dd/MM/yyyy">
                                                    <Addon>
                                                        <span v-if="model.documentation" class="textbox-icon icon-clear" title="Clear value" @click="model.documentation=null;"></span>
                                                    </Addon>
                                                </DateBox>
                                                <span class="f-field timepicker f-field-text textbox f-inline-row" style="width:100px;">
                                                    <input inputId="documentationtime" name="documentationtime" class="f-field-text text-box" v-model="model.documentationtime" type="time" step="2" style="width:100px; border: none;"/>
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td rowspan="3" style="width:10%; vertical-align: top; padding-left: 25px; padding-top: 10px;">Departure</td>
                                            <td>Estimation</td>
                                            <td style="width:50%;">
                                                <DateBox inputId="departure_eta" name="departure_eta" class="f-field-text text-40" v-model="model.departure_eta" format="dd/MM/yyyy">
                                                    <Addon>
                                                        <span v-if="model.departure_eta" class="textbox-icon icon-clear" title="Clear value" @click="model.departure_eta=null;"></span>
                                                    </Addon>
                                                </DateBox>
                                                <span class="f-field timepicker f-field-text textbox f-inline-row" style="width:100px;">
                                                    <input inputId="departure_etatime" name="departure_etatime" class="f-field-text text-box" v-model="model.departure_etatime" type="time" step="2" style="width:100px; border: none;"/>
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Actual</td>
                                            <td>
                                                <DateBox inputId="departure_ata" name="departure_ata" class="f-field-text text-40" v-model="model.departure_ata" format="dd/MM/yyyy">
                                                    <Addon>
                                                        <span v-if="model.departure_ata" class="textbox-icon icon-clear" title="Clear value" @click="model.departure_ata=null;"></span>
                                                    </Addon>
                                                </DateBox>
                                                <span class="f-field timepicker f-field-text textbox f-inline-row" style="width:100px;">
                                                    <input inputId="departure_atatime" name="departure_atatime" class="f-field-text text-box" v-model="model.departure_atatime" type="time" step="2" style="width:100px; border: none;"/>
                                                </span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </TabPanel>
                            <TabPanel :title="'Image'">
                                <div class="history-tl-container">
                                    <ul class="tl">
                                        <li class="tl-item">
                                            <div class="item-header">
                                                Arrival
                                            </div>
                                            <div class="item-img">
                                                <img :src="model.arrival_image" />
                                            </div>
                                            <div class="item-title">
                                                <div class="row" v-if="model.arrival_image == null || model.arrival_image == undefined || model.arrival_image == ''">
                                                    <div class="col-sm-1">Image</div>
                                                    <div class="col-sm-1">:</div>
                                                    <div class="col-sm-10">
                                                        <FileButton style="width:100px" @select="onSelectedFileArrival($event)">Choose File</FileButton> &nbsp;&nbsp;{{fileNameArrival}}
                                                        <div style="margin-top: 10px;">
                                                            (Total Max Upload file size 1MB, allowed file type.jpg)
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-sm-1">Note</div>
                                                    <div class="col-sm-1">:</div>
                                                    <div class="col-sm-9">
                                                        {{ arrival_note }}
                                                        <div v-if="(model.arrival_image == null || model.arrival_image == undefined || model.arrival_image == '')">
                                                            <TextBox inputId="arrival_note" name="arrival_note" :multiline="true" class="f-field-text f-field-text-area" v-model="model.arrival_note"></TextBox>
                                                            <div style="margin-top: 10px;">
                                                                Input note ketika upload image
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li class="tl-item">
                                            <div class="item-header">
                                                Loading Start
                                            </div>
                                            <div class="item-img">
                                                <img :src="model.loading_start_image" id="loading_start_image">
                                            </div>
                                            <div class="item-title">
                                                <div class="row" v-if="model.loading_start_image == null || model.loading_start_image == undefined || model.loading_start_image == ''">
                                                    <div class="col-sm-1">Image</div>
                                                    <div class="col-sm-1">:</div>
                                                    <div class="col-sm-10">
                                                        <FileButton style="width:100px" @select="onSelectedFileLoadingStart($event)">Choose File</FileButton> &nbsp;&nbsp;{{fileNameLoadingStart}}
                                                        <div style="margin-top: 10px;">
                                                            (Total Max Upload file size 1MB, allowed file type.jpg)
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-sm-1">Note</div>
                                                    <div class="col-sm-1">:</div>
                                                    <div class="col-sm-9">
                                                        {{ loading_start_note }}
                                                        <div v-if="(model.loading_start_image == null || model.loading_start_image == undefined || model.loading_start_image == '')">
                                                            <TextBox inputId="loading_start_note" name="loading_start_note" :multiline="true" class="f-field-text f-field-text-area" v-model="model.loading_start_note"></TextBox>
                                                            <div style="margin-top: 10px;">
                                                                Input note ketika upload image
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li class="tl-item">
                                            <div class="item-header">
                                                Departure
                                            </div>
                                            <div class="item-img">
                                                <img :src="model.departure_finish_image" id="departure_finish_image">
                                            </div>
                                            <div class="item-title">
                                                <div class="row" v-if="model.departure_finish_image == null || model.departure_finish_image == undefined || model.departure_finish_image == ''">
                                                    <div class="col-sm-1">Image</div>
                                                    <div class="col-sm-1">:</div>
                                                    <div class="col-sm-10">
                                                        <FileButton style="width:100px" @select="onSelectedFileDeparture($event)">Choose File</FileButton> &nbsp;&nbsp;{{fileNameDeparture}}
                                                        <div style="margin-top: 10px;">
                                                            (Total Max Upload file size 1MB, allowed file type.jpg)
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-sm-1">Note</div>
                                                    <div class="col-sm-1">:</div>
                                                    <div class="col-sm-9">
                                                        {{ departure_finish_note }}
                                                        <div v-if="(model.departure_finish_image == null || model.departure_finish_image == undefined || model.departure_finish_image == '')">
                                                            <TextBox inputId="departure_finish_note" name="departure_finish_note" :multiline="true" class="f-field-text f-field-text-area" v-model="model.departure_finish_note"></TextBox>
                                                            <div style="margin-top: 10px;">
                                                                Input note ketika upload image
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </TabPanel>
                            <TabPanel :title="'Log'">
                                <table width="100%" cellspacing="1" cellpadding="5" border="0">
                                    <tbody>
                                        <tr>
                                            <td>
                                                Created By
                                            </td>
                                            <td>
                                                <TextBox inputId="created_by" name="created_by" class="f-field-text" :value="modelNotMapping.created_by" :disabled="true"></TextBox>
                                            </td>
                                            <td>
                                                Created Date
                                            </td>
                                            <td>
                                                <TextBox inputId="created_date" name="created_date" class="f-field-text" :value="modelNotMapping.created_date" :disabled="true"></TextBox>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Updated By
                                            </td>
                                            <td>
                                                <TextBox inputId="updated_by" name="updated_by" class="f-field-text" :value="modelNotMapping.updated_by" :disabled="true"></TextBox>
                                            </td>
                                            <td>
                                                Updated Date
                                            </td>
                                            <td>
                                                <TextBox inputId="updated_date" name="updated_date" class="f-field-text" :value="modelNotMapping.updated_date" :disabled="true"></TextBox>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </TabPanel>
                        </Tabs>
                        <table width="100%" cellspacing="1" cellpadding="5" border="0">
                            <tbody>
                                <tr>
                                    <td>
                                        Notes
                                        <TextBox inputId="remark" name="remark" :multiline="true" class="f-field-text f-field-text-area" v-model="model.notes"></TextBox>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </fieldset>
                </Form>
            </div>
            <div class="dialog-button">
                <LinkButton :iconCls="iconUtil.iconSave" @click="onSaveData()" :disabled="disableBtnSave" style="width:89px">{{ btnText.save }}</LinkButton>
                <LinkButton :iconCls="iconUtil.iconCancel" @click="onPageClose()" style="width:89px">{{ btnText.cancel }}</LinkButton>
            </div>
        </Dialog>
    </div>
</template>
<script>
    
    import { GetObjectCommand } from "@aws-sdk/client-s3";
    import { S3ClientUtil } from "@/utils/S3ClientUtil";
    import { getSignedUrl } from '@aws-sdk/s3-request-presigner';

    import TrafficMonitoring from '@/models/TrafficMonitoring';
    import TransportService from '@/services/TransportService';

    import InformationUtil from '@/utils/InformationUtil';
    import ModelUtil from '@/utils/ModelUtil';
    import IconUtil from '@/utils/IconUtil';
    import GlobalUtil from '@/utils/GlobalUtil';

    export default {
        name: 'FormTimeWindowComponent',
        props: {
            actionData : {
                type : String,
                default : ''
            }
        },
        data() {
            return {
                title : InformationUtil.editText + " " + InformationUtil.timeWindow,
                titleLegend : InformationUtil.customer,
                iconUtil : IconUtil,
                icon : IconUtil.iconEdit,
                disableBtnSave : false,
                btnText :{
                    refresh : InformationUtil.refreshText,
                    add : InformationUtil.addText,
                    edit : InformationUtil.editText,
                    delete : InformationUtil.removeText,
                    save : InformationUtil.saveText,
                    cancel : InformationUtil.cancelText,
                    search : InformationUtil.searchText
                },
                
                model : TrafficMonitoring,
                modelNotMapping: {
                    created_by : "",
                    updated_by : "",
                    created_date : "",
                    updated_date : "",
                    customer_id: '',
                    customer_name : '',
                    customer_address : '',
                },

                trafficMonitoringId : '',

                fileArrival : '',
                fileNameArrival : '',
                fileLoadingStart : '',
                fileNameLoadingStart : '',
                fileDeparture : '',
                fileNameDeparture : '',

                arrival_note : '',
                loading_start_note : '',
                departure_finish_note : '',
            };
        },
        methods: {
            async onPageShow(row) {
                // reset model
                this.onPageClose();
                if(
                    row.manifestId != null && row.manifestId != undefined &&
                    row.pointId != null && row.pointId != undefined &&
                    row.tmState != null && row.tmState != undefined
                    ){
                        this.trafficMonitoringId = row.trafficMonitoringId;
                        TransportService.post('/timeWindow/show', row)
                            .then(async(response) => {
                                let dataResponse = response;
                                if(dataResponse.data !== "" && dataResponse.data !== null){

                                    this.model = ModelUtil.resetModel(this.model);
                                    this.model = ModelUtil.mapModel(this.model, dataResponse.data);

                                    this.modelNotMapping = ModelUtil.resetModel(this.modelNotMapping);
                                    this.modelNotMapping = ModelUtil.mapModel(this.modelNotMapping, dataResponse.data);

                                    this.model.arrival_eta = ( this.model.arrival_eta != null && this.model.arrival_eta != undefined && this.model.arrival_eta != '') ? new Date(this.model.arrival_eta) : null;
                                    this.model.arrival_ata = ( this.model.arrival_ata != null && this.model.arrival_ata != undefined && this.model.arrival_ata != '') ? new Date(this.model.arrival_ata) : null;
                                    this.model.spm_submit = ( this.model.spm_submit != null && this.model.spm_submit != undefined && this.model.spm_submit != '') ? new Date(this.model.spm_submit) : null;
                                    this.model.loading_start = ( this.model.loading_start != null && this.model.loading_start != undefined && this.model.loading_start != '') ? new Date(this.model.loading_start) : null;
                                    this.model.loading_finish = ( this.model.loading_finish != null && this.model.loading_finish != undefined && this.model.loading_finish != '') ? new Date(this.model.loading_finish) : null;
                                    this.model.documentation = ( this.model.documentation != null && this.model.documentation != undefined && this.model.documentation != '') ? new Date(this.model.documentation) : null;
                                    this.model.departure_eta = ( this.model.departure_eta != null && this.model.departure_eta != undefined && this.model.departure_eta != '') ? new Date(this.model.departure_eta) : null;
                                    this.model.departure_ata = ( this.model.departure_ata != null && this.model.departure_ata != undefined && this.model.departure_ata != '') ? new Date(this.model.departure_ata) : null;
                                    
                                    if ( this.model.arrival_image != null && this.model.arrival_image != undefined && this.model.arrival_image != '') {
                                        const dataArrivalImage = await getSignedUrl(S3ClientUtil, new GetObjectCommand({Bucket : "nxa-bucket-04", Key : this.model.arrival_image}), { expiresIn: 15 * 60 })
                                        this.model.arrival_image = dataArrivalImage;
                                    } else {
                                        this.model.arrival_image = null;
                                    }

                                    if ( this.model.loading_start_image != null && this.model.loading_start_image != undefined && this.model.loading_start_image != '') {
                                        const dataLoadingStartImage = await getSignedUrl(S3ClientUtil, new GetObjectCommand({Bucket : "nxa-bucket-04", Key : this.model.loading_start_image}), { expiresIn: 15 * 60 })
                                        this.model.loading_start_image = dataLoadingStartImage;
                                    }else{
                                        this.model.loading_start_image = null;
                                    }

                                    if ( this.model.departure_finish_image != null && this.model.departure_finish_image != undefined && this.model.departure_finish_image != '') {
                                        const dataDepartureImage = await getSignedUrl(S3ClientUtil, new GetObjectCommand({Bucket : "nxa-bucket-04", Key : this.model.departure_finish_image}), { expiresIn: 15 * 60 })
                                        this.model.departure_finish_image = dataDepartureImage;
                                    } else {
                                        this.model.departure_finish_image = null;
                                    }

                                    this.arrival_note = this.model.arrival_note;
                                    this.loading_start_note = this.model.loading_start_note;
                                    this.departure_finish_note = this.model.departure_finish_note;

                                    this.$refs.dlg.open();
                                }
                            });
                }
            },
            onPageClose(){
                this.model.arrival_eta = null;
                this.model.arrival_ata = null;
                this.model.spm_submit = null;
                this.model.loading_start = null;
                this.model.loading_finish = null;
                this.model.documentation = null;
                this.model.departure_eta = null;
                this.model.departure_ata = null;
                this.model = ModelUtil.resetModel(this.model);
                this.modelNotMapping = ModelUtil.resetModel(this.modelNotMapping);
                this.fileArrival = '';
                this.fileNameArrival = '';
                this.fileLoadingStart = '';
                this.fileNameLoadingStart = '';
                this.fileDeparture = '';
                this.fileNameDeparture = '';
                this.$refs.dlg.close()
            },
            onSaveData(){
                this.disableBtnSave = true;
                this.model.arrival_eta = ( this.model.arrival_eta != null && this.model.arrival_eta != undefined && this.model.arrival_eta != '') ? new Date(this.$moment(this.model.arrival_eta).format('YYYY-MM-DD')) : null;
                this.model.arrival_ata = ( this.model.arrival_ata != null && this.model.arrival_ata != undefined && this.model.arrival_ata != '') ? new Date(this.$moment(this.model.arrival_ata).format('YYYY-MM-DD')) : null;
                this.model.spm_submit = ( this.model.spm_submit != null && this.model.spm_submit != undefined && this.model.spm_submit != '') ? new Date(this.$moment(this.model.spm_submit).format('YYYY-MM-DD')) : null;
                this.model.loading_start = ( this.model.loading_start != null && this.model.loading_start != undefined && this.model.loading_start != '') ? new Date(this.$moment(this.model.loading_start).format('YYYY-MM-DD')) : null;
                this.model.loading_finish = ( this.model.loading_finish != null && this.model.loading_finish != undefined && this.model.loading_finish != '') ? new Date(this.$moment(this.model.loading_finish).format('YYYY-MM-DD')) : null;
                this.model.documentation = ( this.model.documentation != null && this.model.documentation != undefined && this.model.documentation != '') ? new Date(this.$moment(this.model.documentation).format('YYYY-MM-DD')) : null;
                this.model.departure_eta = ( this.model.departure_eta != null && this.model.departure_eta != undefined && this.model.departure_eta != '') ? new Date(this.$moment(this.model.departure_eta).format('YYYY-MM-DD')) : null;
                this.model.departure_ata = ( this.model.departure_ata != null && this.model.departure_ata != undefined && this.model.departure_ata != '') ? new Date(this.$moment(this.model.departure_ata).format('YYYY-MM-DD')) : null;
                

                const arrival_eta = this.model.arrival_eta != null ? this.$moment(this.model.arrival_eta).format('YYYY-MM-DD') : null;
                const arrival_ata = this.model.arrival_ata != null ? this.$moment(this.model.arrival_ata).format('YYYY-MM-DD') : null;
                const spm_submit = this.model.spm_submit != null ? this.$moment(this.model.spm_submit).format('YYYY-MM-DD') : null;
                const loading_start = this.model.loading_start != null ? this.$moment(this.model.loading_start).format('YYYY-MM-DD') : null;
                const loading_finish = this.model.loading_finish != null ? this.$moment(this.model.loading_finish).format('YYYY-MM-DD') : null;
                const documentation = this.model.documentation != null ? this.$moment(this.model.documentation).format('YYYY-MM-DD') : null;
                const departure_eta = this.model.departure_eta != null ? this.$moment(this.model.departure_eta).format('YYYY-MM-DD') : null;
                const departure_ata = this.model.departure_ata != null ? this.$moment(this.model.departure_ata).format('YYYY-MM-DD') : null;

                let formData = new FormData();
                formData.append('manifest_id', this.model.manifest_id);
                formData.append('point_id' , this.model.point_id);
                formData.append('tm_state' , this.model.tm_state);
                formData.append('arrival_eta' , arrival_eta);
                formData.append('arrival_etatime' , this.model.arrival_etatime);
                formData.append('arrival_ata' , arrival_ata);
                formData.append('arrival_atatime' , this.model.arrival_atatime);
                formData.append('spm_submit' , spm_submit);
                formData.append('spm_submittime' , this.model.spm_submittime);
                formData.append('loading_start' , loading_start);
                formData.append('loading_starttime' , this.model.loading_starttime);
                formData.append('loading_finish' , loading_finish);
                formData.append('loading_finishtime' , this.model.loading_finishtime);
                formData.append('documentation' , documentation);
                formData.append('documentationtime' , this.model.documentationtime);
                formData.append('departure_eta' , departure_eta);
                formData.append('departure_etatime' , this.model.departure_etatime);
                formData.append('departure_ata' , departure_ata);
                formData.append('departure_atatime' , this.model.departure_atatime);
                formData.append('notes' , this.model.notes);
                formData.append('arrival_note' , this.model.arrival_note);
                if(this.fileArrival != null && this.fileArrival != undefined && this.fileArrival != '') {
                    formData.append('arrival_image' , this.fileArrival);
                }

                formData.append('loading_start_note' , this.model.loading_start_note);
                if(this.fileLoadingStart != null && this.fileLoadingStart != undefined && this.fileLoadingStart != '') {
                    formData.append('loading_start_image' , this.fileLoadingStart);
                } 
                
                formData.append('departure_finish_note' , this.model.departure_finish_note);
                if(this.fileDeparture != null && this.fileDeparture != undefined && this.fileDeparture != '') {
                    formData.append('departure_finish_image' , this.fileDeparture);
                }

                TransportService.uploadFile("/timeWindow/update", formData)
                    .then((res) => {
                        this.disableBtnSave = false;
                        if(res.code == 200){
                            this.$refs.dlg.close();
                            this.model.arrival_eta = null;
                            this.model.arrival_ata = null;
                            this.model.spm_submit = null;
                            this.model.loading_start = null;
                            this.model.loading_finish = null;
                            this.model.documentation = null;
                            this.model.departure_eta = null;
                            this.model.departure_ata = null;

                            this.model = ModelUtil.resetModel(this.model);
                            this.modelNotMapping = ModelUtil.resetModel(this.modelNotMapping);

                            // set event after save
                            this.$emit('onSaveData', this.model);
                        } else {
                            this.$messager.alert({
                                title: "Error",
                                icon: "error",
                                msg: res.message
                            });
                        }
                    }).catch((error) => {
                        this.disableBtnSave = false;
                        this.$refs.notification.error(error);
                    });

            },

            onSelectedFileArrival(event){
                this.fileArrival = event[0];
                this.fileNameArrival = this.fileArrival.name;
            },
            onSelectedFileLoadingStart(event){
                this.fileLoadingStart = event[0];
                this.fileNameLoadingStart = this.fileLoadingStart.name;
            },
            onSelectedFileDeparture(event){
                this.fileDeparture = event[0];
                this.fileNameDeparture = this.fileDeparture.name;
            },
        },
        created(){
            GlobalUtil.removeWindow();
        }
    };
</script>