<template>
    <div>
        <Layout>
            <LayoutPanel region="center">
                <Panel :title="titleView"  style="margin:0px 1px; ">
                    <div class="datagrid-toolbar">
                        <LinkButton :plain="true" :iconCls="iconUtil.iconRefresh" @click="onRefresh()">{{ btnText.refresh }}</LinkButton>
                        <LinkButton v-if="action.add" :plain="true" :iconCls="iconUtil.iconAdd" @click="onAdd()">{{ btnText.add }}</LinkButton>
                        <LinkButton v-if="action.edit" :plain="true" :iconCls="iconUtil.iconEdit" @click="onEdit()">{{ btnText.edit }}</LinkButton>
                        <span class="button-sep"></span> Status : 
                        <ComboBox  :data="statusData" inputId="searchStatusData" name="searchStatusData" class="f-field-text" style="width:75px; margin-left: 5px; margin-right: 5px;" v-model="searchStatusData" @selectionChange="onSelectionStatusData($event)"></ComboBox>
                        <span class="button-sep"></span> {{ search.text }} : 
                        <SearchBox style="width:300px"
                            :placeholder="search.placeholder" 
                            v-model="dataGrid.search"
                            @search="onSearch()">
                            <Addon>
                                <span v-if="dataGrid.search" class="textbox-icon icon-clear" title="Clear value" @click="dataGrid.search=null;onSearch();"></span>
                            </Addon>
                        </SearchBox>
                        <LinkButton :plain="true" :iconCls="iconUtil.iconMultiSearch" @click="onMultiSearch()">{{ informationUtil.multiSearchText }}</LinkButton>
                    </div>
                    <DataGrid
                            ref="dataGrid"
                            :columnResizing="true"
                            :border="false"
                            :lazy="true"
                            :loading="dataGrid.loading"
                            :data="dataGrid.rows"
                            :pagination="true"
                            :selectionMode="dataGrid.selectionMode"
                            :total="dataGrid.total"
                            :pageSize="dataGrid.pageSize"
                            :pageNumber="dataGrid.pageNumber"
                            :pageLayout="dataGrid.pageLayout" 
                            @pageChange="onPageChange($event)"
                            @sortChange="onSortChange($event)"
                            @rowDblClick="onRowDblClick()"
                            >
                            <GridColumn align="center" cellCss="datagrid-td-rownumber" width="30">
                            <template slot="body" slot-scope="scope">
                                {{scope.rowIndex + 1}}
                            </template>
                        </GridColumn>
                        <GridColumn field="so_number" title="So Number" :sortable="true"></GridColumn>
                        <GridColumn field="req_delivery_date_desc" title="Req. Delivery Date" :sortable="true"></GridColumn>
                        <GridColumn field="created_date" title="Created Date" :sortable="true"></GridColumn>
                        <GridColumn field="origin_desc" title="Origin Id" :sortable="true"></GridColumn>
                        <GridColumn field="origin_name" title="Origin Name" :sortable="true"></GridColumn>
                        <GridColumn field="origin_address" title="Origin Address" :sortable="true"></GridColumn>
                        <GridColumn field="dest_desc" title="Destination Id" :sortable="true"></GridColumn>
                        <GridColumn field="dest_name" title="Destination Name" :sortable="true"></GridColumn>
                        <GridColumn field="dest_address" title="Destination Address" :sortable="true"></GridColumn>
                        <GridColumn field="client_desc" title="Client ID" :sortable="true"></GridColumn>
                        <GridColumn field="order_type_desc" title="Order Type" :sortable="true"></GridColumn>
                        <GridColumn field="total_qty" title="Total Qty" :sortable="true"></GridColumn>
                        <GridColumn field="total_qty_remain" title="Total Qty Remain" :sortable="true"></GridColumn>
                        <GridColumn field="total_weight" title="Total Tonase" :sortable="true"></GridColumn>
                        <GridColumn field="total_volume" title="Total M3" :sortable="true"></GridColumn>
                        <GridColumn field="status_desc" title="Status" :sortable="true"></GridColumn>
                    </DataGrid>
                </Panel>
                <GridSuggestionSalesOrderComponent ref="gridSuggestionSalesOrder" actionData="sales_order" :disableBtnSave="disableBtnSave" :multiSearch="modelMultiSearch" :searchField="dataGrid.search" :searchStatusData="searchStatusData" @onGenerateData="onGenerateData($event)"></GridSuggestionSalesOrderComponent>
            </LayoutPanel>
            <LayoutPanel region="east"  :split="true" :collapsible="true" :collapsed="true" :expander="true" title="Preferred Vehicle" :panelStyle="{width:'240px',minWidth:'220px'}">
                <div class="datagrid-toolbar">
                    <div style="font-weight: bold;">Summary</div>
                    <div>Total Remain Qty : <b>{{ dataTotalRemain.qty }}</b></div>
                    <div>Total Remain Tonase (Kg) : <b>{{ dataTotalRemain.weight }}</b></div>
                    <div>Total Remain Volume (M3) : <b>{{ dataTotalRemain.volume }}</b></div>
                </div>
                <DataGrid
                        ref="dataGridPreferredVehicle"
                        :border="false"
                        :data="dataGridPreferredVehicle.rows"
                        >
                    <GridColumn align="center" cellCss="datagrid-td-rownumber" width="30">
                        <template slot="body" slot-scope="scope">
                            {{scope.rowIndex + 1}}
                        </template>
                    </GridColumn>
                    <GridColumn field="vehicle_type_desc" title="Vehicle Type" :sortable="false" width="80"></GridColumn>
                    <GridColumn field="calculated_qty_vehicle" title="Number Of Vehicle" :sortable="false"></GridColumn>
                </DataGrid>
            </LayoutPanel>
        </Layout>
        

        <!-- Multi Search -->
        <Dialog ref="dlgSearch" :iconCls="iconUtil.iconMultiSearch" bodyCls="f-column" :title="informationUtil.multiSearchText" :draggable="true" :modal="true" closed :dialogStyle="{width:'500px', height:'auto'}">
            <div class="f-full" style="overflow:auto">
                <table width="100%" cellspacing="1" cellpadding="5" border="0">
                    <tbody>
                        <tr>
                            <td class="bg_subtitle">SO Number</td>
                            <td class="bg_grid2" colspan="3">
                                <TextBox inputId="so_number" name="so_number" class="f-field-text" v-model="modelMultiSearch.so_number">
                                    <Addon>
                                        <span v-if="modelMultiSearch.so_number" class="textbox-icon icon-clear" title="Clear value" @click="modelMultiSearch.so_number=null;"></span>
                                    </Addon>
                                </TextBox>
                            </td>
                        </tr>
                        <tr>
                            <td class="bg_subtitle">Req Delivery Date</td>
                            <td class="bg_grid2">
                                <DateBox inputId="req_delivery_date_from" name="req_delivery_date_from" class="f-field-text" v-model="modelMultiSearch.req_delivery_date_from" format="dd/MM/yyyy">
                                    <Addon>
                                        <span v-if="modelMultiSearch.req_delivery_date_from" class="textbox-icon icon-clear" title="Clear value" @click="modelMultiSearch.req_delivery_date_from=null;"></span>
                                    </Addon>
                                </DateBox>
                            </td>
                            <td>to</td>
                            <td class="bg_grid2">
                                <DateBox inputId="req_delivery_date_to" name="req_delivery_date_to" class="f-field-text" v-model="modelMultiSearch.req_delivery_date_to" format="dd/MM/yyyy">
                                    <Addon>
                                        <span v-if="modelMultiSearch.req_delivery_date_to" class="textbox-icon icon-clear" title="Clear value" @click="modelMultiSearch.req_delivery_date_to=null;"></span>
                                    </Addon>
                                </DateBox>
                            </td>
                        </tr>

                        <tr>
                            <td class="bg_subtitle">Created Date</td>
                            <td class="bg_grid2">
                                <DateBox inputId="created_date_from" name="created_date_from" class="f-field-text" v-model="modelMultiSearch.created_date_from" format="dd/MM/yyyy">
                                    <Addon>
                                        <span v-if="modelMultiSearch.created_date_from" class="textbox-icon icon-clear" title="Clear value" @click="modelMultiSearch.created_date_from=null;"></span>
                                    </Addon>
                                </DateBox>
                            </td>
                            <td>to</td>
                            <td class="bg_grid2">
                                <DateBox inputId="created_date_to" name="created_date_to" class="f-field-text" v-model="modelMultiSearch.created_date_to" format="dd/MM/yyyy">
                                    <Addon>
                                        <span v-if="modelMultiSearch.created_date_to" class="textbox-icon icon-clear" title="Clear value" @click="modelMultiSearch.created_date_to=null;"></span>
                                    </Addon>
                                </DateBox>
                            </td>
                        </tr>

                        <tr>
                            <td class="bg_subtitle">Origin ID</td>
                            <td class="bg_grid2" colspan="3">
                                <ComboBox  :data="customer_origins" @filterChange="optionCustomerOrigin($event)" inputId="origin_id" name="origin_id" class="f-field-text" v-model="modelMultiSearch.origin_id">
                                    <template slot="item" slot-scope="scope">
                                        <span style="font-weight:bold">{{scope.row.text}}</span><br/>
                                        <span style="color:#888">{{scope.row.name}}</span><br/>
                                        <span style="color:#888">{{scope.row.address}}</span><br/>
                                    </template>
                                    <Addon>
                                        <span v-if="modelMultiSearch.origin_id" class="textbox-icon icon-clear" title="Clear value" @click="modelMultiSearch.origin_id=null;"></span>
                                    </Addon>
                                </ComboBox>
                            </td>
                        </tr>

                        <tr>
                            <td class="bg_subtitle">Destination ID</td>
                            <td class="bg_grid2" colspan="3">
                                <ComboBox  :data="customer_destinations" @filterChange="optionCustomerDestination($event)" inputId="dest_id" name="dest_id" class="f-field-text" v-model="modelMultiSearch.dest_id"> 
                                    <template slot="item" slot-scope="scope">
                                        <span style="font-weight:bold">{{scope.row.text}}</span><br/>
                                        <span style="color:#888">{{scope.row.name}}</span><br/>
                                        <span style="color:#888">{{scope.row.address}}</span><br/>
                                    </template>
                                    <Addon>
                                        <span v-if="modelMultiSearch.dest_id" class="textbox-icon icon-clear" title="Clear value" @click="modelMultiSearch.dest_id=null;"></span>
                                    </Addon>
                                </ComboBox>
                            </td>
                        </tr>

                        <tr>
                            <td class="bg_subtitle">Order Type </td>
                            <td class="bg_grid2" colspan="3">
                                <ComboBox  :data="orderTypes" inputId="order_type" name="order_type" class="f-field-text" v-model="modelMultiSearch.order_type">
                                    <Addon>
                                        <span v-if="modelMultiSearch.order_type" class="textbox-icon icon-clear" title="Clear value" @click="modelMultiSearch.order_type=null;"></span>
                                    </Addon>
                                </ComboBox>
                            </td>
                        </tr>

                        <tr>
                            <td class="bg_subtitle">
                                Client ID
                            </td>
                            <td class="bg_grid2" colspan="3"> 
                                <ComboBox  :data="clients" @filterChange="optionClient($event)" inputId="client_id" name="client_id" class="f-field-text" v-model="modelMultiSearch.client_id">
                                    <template slot="item" slot-scope="scope">
                                        <span style="font-weight:bold">{{scope.row.text}}</span><br/>
                                        <span style="color:#888">{{scope.row.address}}</span><br/>
                                    </template>
                                    <Addon>
                                        <span v-if="modelMultiSearch.client_id" class="textbox-icon icon-clear" title="Clear value" @click="modelMultiSearch.client_id=null;"></span>
                                    </Addon>
                                </ComboBox>
                            </td>
                        </tr>
                        <tr>
                            <td class="bg_subtitle">Status Data</td>
                            <td class="bg_grid2" colspan="3">
                                <ComboBox  :data="statusDataSearch" inputId="status" name="status" class="f-field-text" v-model="modelMultiSearch.status">
                                    <Addon>
                                        <span v-if="modelMultiSearch.status" class="textbox-icon icon-clear" title="Clear value" @click="modelMultiSearch.status=null;"></span>
                                    </Addon>
                                </ComboBox>
                            </td>
                        </tr>                        
                    </tbody>
                </table>
            </div>
            <div class="dialog-button">
                <LinkButton :iconCls="iconUtil.iconSearch" @click="onSearchMulti()" style="width:89px">{{ informationUtil.searchText }}</LinkButton>
                <LinkButton :iconCls="iconUtil.iconCancel" @click="onResetSearchMulti()" style="width:89px">{{ informationUtil.resetText }}</LinkButton>
            </div>
        </Dialog>

        <!-- Form -->
        <Dialog ref="dlg" :iconCls="icon" bodyCls="f-column" :title="title" :draggable="true" :modal="true" closed :dialogStyle="{width:'750px', height:'600px'}">
            <div class="f-full" style="overflow:auto">
                <Form ref="form" :model="model" :rules="rules" @validate="errors=$event">
                    <fieldset class="c-fieldset">
                        <legend class="c-legend"><b>{{ titleMessage }}</b></legend>
                        <table width="100%" cellspacing="1" cellpadding="5" border="0">
                            <tbody>
                                <tr>
                                    <td>SO Number <font color="red">*</font></td>
                                    <td>
                                        <TextBox inputId="so_number" name="so_number" class="f-field-text" v-model="model.so_number" :disabled="disabledClosed"></TextBox>
                                        <div class="error">{{textError.so_number}}</div>
                                    </td>
                                    <td>
                                        Client ID <font color="red">*</font>
                                    </td>
                                    <td> 
                                        <ComboBox  :data="clients" inputId="client_id" name="client_id" @filterChange="optionClient($event)" class="f-field-text" v-model="model.client_id" :disabled="disabledClosed">
                                            <template slot="item" slot-scope="scope">
                                                <span style="font-weight:bold">{{scope.row.text}}</span><br/>
                                                <span style="color:#888">{{scope.row.address}}</span><br/>
                                            </template>
                                        </ComboBox>
                                        <div class="error">{{textError.client_id}}</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Req. Delivery Date <font color="red">*</font></td>
                                    <td>
                                        <DateBox inputId="req_delivery_date" name="req_delivery_date" class="f-field-text" v-model="model.req_delivery_date" format="dd/MM/yyyy" :disabled="disabledClosed"></DateBox>
                                        <div class="error">{{textError.req_delivery_date}}</div>
                                    </td>
                                    <td>Order Type <font color="red">*</font></td>
                                    <td>
                                        <ComboBox  :data="orderTypes" inputId="order_type" name="order_type" class="f-field-text" v-model="model.order_type" :disabled="disabledClosed"></ComboBox>
                                        <div class="error">{{textError.order_type}}</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Origin <font color="red">*</font></td>
                                    <td>
                                        <ComboBox  :data="customer_origins" inputId="origin_id" name="origin_id" @filterChange="optionCustomerOrigin($event)" @selectionChange="onSelectionCustomerOrigin($event)" class="f-field-text" v-model="model.origin_id" :disabled="disabledClosed">
                                            <template slot="item" slot-scope="scope">
                                                <span style="font-weight:bold">{{scope.row.text}}</span><br/>
                                                <span style="color:#888">{{scope.row.name}}</span><br/>
                                                <span style="color:#888">{{scope.row.address}}</span><br/>
                                            </template>
                                        </ComboBox>
                                        <div class="error">{{textError.origin_id}}</div>
                                    </td>
                                    <td>Destination <font color="red">*</font></td>
                                    <td>
                                        <ComboBox  :data="customer_destinations" inputId="dest_id" name="dest_id" @filterChange="optionCustomerDestination($event)" @selectionChange="onSelectionCustomerDestination($event)" class="f-field-text" v-model="model.dest_id" :disabled="disabledClosed">
                                            <template slot="item" slot-scope="scope">
                                                <span style="font-weight:bold">{{scope.row.text}}</span><br/>
                                                <span style="color:#888">{{scope.row.name}}</span><br/>
                                                <span style="color:#888">{{scope.row.address}}</span><br/>
                                            </template>
                                        </ComboBox>
                                        <div class="error">{{textError.dest_id}}</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Origin Name</td>
                                    <td>
                                        <TextBox inputId="origin_customer_name" name="origin_customer_name" class="f-field-text" v-model="modelNotMapping.origin_customer_name" :disabled="true"></TextBox>
                                    </td>
                                    <td>Destination Name</td>
                                    <td>
                                        <TextBox inputId="dest_customer_name" name="dest_customer_name" class="f-field-text" v-model="modelNotMapping.dest_customer_name" :disabled="true"></TextBox>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Origin Address</td>
                                    <td>
                                        <TextBox inputId="origin_customer_address" name="origin_customer_address" class="f-field-text" v-model="modelNotMapping.origin_customer_address" :disabled="true"></TextBox>
                                    </td>
                                    <td>Destination Address</td>
                                    <td>
                                        <TextBox inputId="dest_customer_address" name="dest_customer_address" class="f-field-text" v-model="modelNotMapping.dest_customer_address" :disabled="true"></TextBox>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Remark</td>
                                    <td>
                                        <TextBox inputId="remark" name="remark" :multiline="true" class="f-field-text" v-model="model.remark"></TextBox>
                                    </td>
                                    <td>Status</td>
                                    <td>
                                        <TextBox inputId="status_desc" name="status_desc" class="f-field-text" v-model="modelNotMapping.status_desc" :disabled="true"></TextBox>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </fieldset>
                    <fieldset class="c-fieldset">
                        <legend class="c-legend"><b>Details Information</b></legend>
                        <Tabs style="height:395px">
                            <TabPanel :title="'Product'">
                                <fieldset class="c-fieldset">
                                    <legend class="c-legend"><b>&nbsp;&nbsp;Item</b></legend>
                                    <table width="100%" cellspacing="1" cellpadding="5" border="0">
                                        <tbody>
                                            <tr>
                                                <td>Product Name</td>
                                                <td>
                                                    <TextBox class="f-field-text" name="product_name" @input="onUpperProductName" v-model="modelInputDetail.product_name"></TextBox>
                                                </td>
                                                <td>
                                                    Quantity
                                                </td>
                                                <td> 
                                                    <NumberBox inputId="qty" name="qty" class="f-field-text" :spinners="false" v-model="modelInputDetail.qty" @blur="blurQty" @focus="blurQty" @valueChange="blurQty"></NumberBox>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Weight</td>
                                                <td>
                                                    <NumberBox inputId="weight" name="weight" class="f-field-text" :spinners="false" v-model="modelInputDetail.weight" :readonly="true"></NumberBox>
                                                </td>
                                                <td>
                                                    Volume
                                                </td>
                                                <td> 
                                                    <NumberBox inputId="volume" name="volume" class="f-field-text" :spinners="false" v-model="modelInputDetail.volume" :readonly="true"></NumberBox>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>UOM</td>
                                                <td>
                                                    <ComboBox  :data="uomListV2" inputId="uom" name="uom" @filterChange="optionUomV2($event)" class="f-field-text" v-model="modelInputDetail.uom"></ComboBox>
                                                </td>
                                                <td>
                                                    &nbsp;
                                                </td>
                                                <td> 
                                                    <LinkButton :iconCls="iconUtil.iconAdd" style="border:none;" @click="addDetail()"></LinkButton>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </fieldset>
                                <div class="datagrid-toolbar" style="margin-bottom: 10px;">
                                    {{ search.text }} : 
                                    <SearchBox style="width:300px"
                                        :placeholder="search.placeholder" 
                                        v-model="dataGridDetail.search"
                                        @search="onSearchDetail()">
                                        <Addon>
                                            <span v-if="dataGridDetail.search" class="textbox-icon icon-clear" title="Clear value" @click="dataGridDetail.search=null;onSearchDetail();"></span>
                                        </Addon>
                                    </SearchBox>
                                    <LinkButton v-if="action.delete" :plain="true" :iconCls="iconUtil.iconDelete" @click="onDeleteDetail()">{{ btnText.delete }}</LinkButton>
                                </div>
                                <DataGrid
                                    ref="dataGridDetail"
                                    :columnResizing="true"
                                    :border="false"
                                    :loading="dataGridDetail.loading"                                    
                                    :data="dataGridDetail.rows"
                                    :pagination="true"
                                    :selectionMode="dataGridDetail.selectionMode"
                                    :total="dataGridDetail.total"
                                    :pageSize="dataGridDetail.pageSize"
                                    :pageNumber="dataGridDetail.pageNumber"
                                    :pageLayout="dataGridDetail.pageLayout"
                                    >
                                    <GridColumn align="center" cellCss="datagrid-td-rownumber" width="30">
                                        <template slot="body" slot-scope="scope">
                                            {{scope.rowIndex + 1}}
                                        </template>
                                    </GridColumn>
                                    <GridColumn field="product_name" title="Product Name" :sortable="true"></GridColumn>
                                    <GridColumn field="qty" title="Qty" :sortable="true"></GridColumn>
                                    <GridColumn field="qty_remain" title="Qty Remain" :sortable="true"></GridColumn>
                                    <GridColumn field="uom_desc" title="UOM" :sortable="true"></GridColumn>
                                    <GridColumn field="weight" title="Weight" :sortable="true"></GridColumn>
                                    <GridColumn field="volume" title="Volume" :sortable="true"></GridColumn>
                                </DataGrid>
                            </TabPanel>
                            <TabPanel :title="'Log'">
                                <table width="100%" cellspacing="1" cellpadding="5" border="0">
                                    <tbody>
                                        <tr>
                                            <td>
                                                Created By
                                            </td>
                                            <td>
                                                <TextBox inputId="created_by" name="created_by" class="f-field-text" :value="modelNotMapping.created_by" :disabled="true"></TextBox>
                                            </td>
                                            <td>
                                                Created Date
                                            </td>
                                            <td>
                                                <TextBox inputId="created_date" name="created_date" class="f-field-text" :value="modelNotMapping.created_date" :disabled="true"></TextBox>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Updated By
                                            </td>
                                            <td>
                                                <TextBox inputId="updated_by" name="updated_by" class="f-field-text" :value="modelNotMapping.updated_by" :disabled="true"></TextBox>
                                            </td>
                                            <td>
                                                Updated Date
                                            </td>
                                            <td>
                                                <TextBox inputId="updated_date" name="updated_date" class="f-field-text" :value="modelNotMapping.updated_date" :disabled="true"></TextBox>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </TabPanel>
                        </Tabs>
                    </fieldset>
                </Form>
            </div>
            <div class="dialog-button">
                <LinkButton :iconCls="iconUtil.iconSave" v-if="action.add || action.edit" @click="onSave()" :disabled="disableBtnSave" style="width:89px">{{ btnText.save }}</LinkButton>
                <LinkButton :iconCls="iconUtil.iconCancel" @click="$refs.dlg.close()" style="width:89px">{{ btnText.cancel }}</LinkButton>
            </div>
        </Dialog>

        
        <NotificationComponent ref="notification"></NotificationComponent>
    </div>
    
</template>

<script>
import NotificationComponent from '@/components/NotificationComponent.vue';
import SalesOrder from '@/models/SalesOrder';
import OutboundService from '@/services/OutboundService';
import ModelUtil from '@/utils/ModelUtil';
import InformationUtil from '@/utils/InformationUtil';
import AccessUtil from '@/utils/AccessUtil';
import IconUtil from '@/utils/IconUtil';
import GlobalUtil from '@/utils/GlobalUtil';

import OptionService from '@/services/OptionService';

import GridSuggestionSalesOrderComponent from '@/components/grids/GridSuggestionSalesOrderComponent.vue';

export default {
    name: 'SalesOrderView',
    components : {
        GridSuggestionSalesOrderComponent,  
        NotificationComponent
    },
    data() {
        return {
            titleView : InformationUtil.salesOrder + "s",
            titleMessage : InformationUtil.salesOrder,
            iconUtil : IconUtil,
            informationUtil : InformationUtil,

            titleForm :{
                add : InformationUtil.addText,
                edit : InformationUtil.editText
            },

            iconForm :{
                add : IconUtil.iconAdd,
                edit : IconUtil.iconEdit
            },
            
            action : {
                view : false,
                add : false,
                edit : false,
                delete : false
            },

            filter : {
                viewStatusMessage : false,
            },

            btnText :{
                refresh : InformationUtil.refreshText,
                add : InformationUtil.addText,
                edit : InformationUtil.editText,
                delete : InformationUtil.removeText,
                save : InformationUtil.saveText,
                cancel : InformationUtil.cancelText,
                search : InformationUtil.searchText,
                duplicate : InformationUtil.duplicateText,
                import : InformationUtil.importText,
                upload : InformationUtil.uploadText,
                downloadTemplate : InformationUtil.downloadTemplateText
            },

            search : {
                text : InformationUtil.searching,
                placeholder : InformationUtil.searchHolder
            },
            
            modelMultiSearch : {
                do_number : "",
                so_number : "",
                req_delivery_date_from : null,
                req_delivery_date_to : null,
                created_date_from : null,
                created_date_to : null,
                origin_id : "",
                dest_id : "",
                order_type : null,
                client_id : "",
                status : null,
            },

            dataGrid : {
                loading: false,
                rows : [],
                total: 0,
                start: 0,
                pageNumber: 1,
                pageSize: 10,
                selectionMode : 'single',
                pageLayout: ['list','sep','first','prev','next','last','sep','refresh','info'],

                // filter
                search : null,
                sortField : "",
                sortOrder : ""
            },

            dataGridDetail : {
                loading: false,
                rows : [],
                rowsTemp : [],
                total: 0,
                start: 0,
                pageNumber: 1,
                pageSize: 10,
                selectionMode : 'single',
                pageLayout: ['list','sep','first','prev','next','last','sep','refresh','info'],

                // filter
                search : null,
                sortField : "",
                sortOrder : "",
            },

            dataGridPreferredVehicle : {
                rows : []
            },

            dataTotalRemain : {
                weight : 0,
                qty : 0,
                volume : 0,
            },

            // form
            title: '',
            icon: '',
            model: SalesOrder,
            disabled : false,
            disabledClosed : false,
            disableBtnSave : false,
            modelNotMapping : {
                created_by : "",
                updated_by : "",
                created_date : "",
                updated_date : "",
                origin_customer_name : "",
                dest_customer_name : "",
                origin_customer_address : "",
                dest_customer_address : "",
                status_desc : "",
            },
            
            modelInputDetail : {
                product_name : "",
                qty : 0,
                qty_remain : 0,
                uom : 0,
                weight : 0,
                weight_remain : 0,
                volume : 0,
                volume_remain : 0
            },

            searchStatusData : 0,

            // validation
            rules: {
                client_id : 'required',
                do_number : 'required',
                so_number : 'required',
                order_type : 'required',
                origin_id : 'required',
                dest_id : 'required',
                req_delivery_date : 'required'
            },
            errors: {},
            textError :{
                client_id : '',
                do_number : '',
                so_number : '',
                order_type : '',
                origin_id : '',
                dest_id : '',
                req_delivery_date : ''
            },

            // combobox
            statusData : [
                { value: 0 , text: 'Open' },
                { value: 1 , text: 'Closed' },
                { value: 'all' , text: 'All' },
            ],

            uomList : [],
            uomListV2 : [],
            orderTypes: [],
            clients : [],
            customer_origins : [],
            customer_destinations : [],
            
            statusDataSearch : [
                { value: 0 , text: 'OPEN' },
                { value: 1 , text: 'CLOSE' },
            ]
        }
    },
    methods:{
        blurQty(){
            if(this.modelInputDetail.qty != null && this.modelInputDetail.qty != ''){
                this.modelInputDetail.qty_remain = this.modelInputDetail.qty;
                this.modelInputDetail.volume = this.modelInputDetail.qty * 0.05;
                this.modelInputDetail.volume_remain = this.modelInputDetail.qty * 0.05;
                this.modelInputDetail.weight = this.modelInputDetail.qty * 100;
                this.modelInputDetail.weight_remain = this.modelInputDetail.qty * 100;
            }
        },
        onUpperProductName(evt) {
            if(evt != null && evt != ''){
                this.modelInputDetail.product_name = evt.toUpperCase();
            }
        },
        onTabSelected(){
            this.dataGrid.search = null;
            this.onRefresh();
        },
        getError(name) {
            return this.errors[name] && this.errors[name].length
            ? this.errors[name][0]
            : null;
        },
        onAdd(){
            ModelUtil.resetModel(this.textError);
            // method for add data
            this.disabled = false;
            this.disableBtnSave = false;
            this.$refs.dataGrid.unselectRow(this.$refs.dataGrid.selectedRows[0]);
            this.model = ModelUtil.resetModel(this.model);
            this.modelNotMapping = ModelUtil.resetModel(this.modelNotMapping);
            this.modelInputDetail = ModelUtil.resetModel(this.modelInputDetail);
            this.onCallAllOption();

            //  reset grid details
            this.dataGridDetail.rows = [];
            this.dataGridDetail.rowsTemp = [];

            // set data default
            this.model.order_type = 1;
            this.model.uom = 1;
            this.model.uom_v2 = 1;
            this.model.req_delivery_date = new Date();
            if(this.model.status == 1){
                this.disabledClosed = true;
            }else{
                this.disabledClosed = false;
            }

            this.title = this.titleForm.add;
            this.icon = this.iconForm.add;
            this.$refs.dlg.open();
        },
        onEdit(){
            ModelUtil.resetModel(this.textError);
            // method for get data to edit
            if(this.$refs.dataGrid.selectedRows.length > 0){
                this.disabled = true;
                this.disableBtnSave = false;
                this.title = this.titleForm.edit;
                this.icon = this.iconForm.edit;
                this.onCallAllOption();

                // additional for combo box
                this.clients = [{value: this.$refs.dataGrid.selectedRows[0].client_id, text: this.$refs.dataGrid.selectedRows[0].client_desc, address : this.$refs.dataGrid.selectedRows[0].client_address}];
                this.customer_origins = [{value: this.$refs.dataGrid.selectedRows[0].origin_id, text: this.$refs.dataGrid.selectedRows[0].origin_desc, name : this.$refs.dataGrid.selectedRows[0].origin_name, address: this.$refs.dataGrid.selectedRows[0].origin_address}]
                this.customer_destinations = [{value: this.$refs.dataGrid.selectedRows[0].dest_id, text: this.$refs.dataGrid.selectedRows[0].dest_desc, name : this.$refs.dataGrid.selectedRows[0].dest_name, address: this.$refs.dataGrid.selectedRows[0].dest_address}]
                this.model = ModelUtil.resetModel(this.model);
                this.modelInputDetail = ModelUtil.resetModel(this.modelInputDetail);
                this.model = ModelUtil.mapModel(this.model, this.$refs.dataGrid.selectedRows[0])
                this.modelNotMapping = ModelUtil.resetModel(this.modelNotMapping);

                this.modelNotMapping = ModelUtil.mapModel(this.modelNotMapping, this.$refs.dataGrid.selectedRows[0])

                this.dataGridDetail.rows = this.model.item_product != null ? JSON.parse(this.model.item_product) : null;
                this.dataGridDetail.rowsTemp = this.dataGridDetail.rows;

                this.model.req_delivery_date = new Date(this.model.req_delivery_date);
                if(this.model.status == 1){
                    this.disabledClosed = true;
                }else{
                    this.disabledClosed = false;
                }

                this.$refs.dlg.open();
            }else{
                this.$refs.notification.centerTop(InformationUtil.selectRow);
            }
        },
        onDelete(){
            if(this.$refs.dataGrid.selectedRows.length > 0){
                this.$messager.confirm({
                    title: 'Confirm',
                    msg: InformationUtil.confirmDelete,
                    result: (r) => {
                        if (r){
                            this.dataGrid.loading = true;
                            OutboundService.deleteData("/salesOrder", this.$refs.dataGrid.selectedRows[0].id)
                                .then((response) => {
                                    this.dataGrid.loading = false;
                                    this.$refs.dlg.close();
                                    if(response.code == 200){
                                        this.$refs.notification.success(this.titleMessage + " " + InformationUtil.delete);
                                        this.loadData();
                                        this.loadDataPreferredVehicle();
                                    }else{
                                        this.$refs.notification.error(response.message);
                                    }
                                }).catch((err) => {
                                    this.$refs.notification.error(err.data.message);
                                });
                            this.$refs.dataGrid.unselectRow(this.$refs.dataGrid.selectedRows[0]) 
                        }
                    }
                })
            }else{
                this.$refs.notification.centerTop(InformationUtil.selectRow);
            }
        },
        onSave(){
            ModelUtil.resetModel(this.textError);
            this.$refs.form.validate((valid) => {
                if(!valid){
                    this.disableBtnSave = true; 
                    this.model.req_delivery_date = new Date(this.$moment(this.model.req_delivery_date).format('YYYY-MM-DD'));

                    this.model.item_product = this.dataGridDetail.rowsTemp;

                    //validation item product
                    if(this.model.item_product == null || this.model.item_product.length == 0){
                        this.$messager.alert({
                            title: "Error",
                            icon: "error",
                            msg: "Product is required"
                        });
                        this.disableBtnSave = false;
                        return;
                    }

                    if(this.model.id == null){
                        // action add data
                        OutboundService.addData("/salesOrder", this.model)
                            .then((res) => {
                                this.disableBtnSave = false;
                                if(res.code == 200){
                                    this.$refs.notification.success(this.titleMessage + " " + InformationUtil.add);
                                    this.loadData();
                                    this.loadDataPreferredVehicle();
                                    this.$refs.dlg.close();
                                } else {
                                    this.$messager.alert({
                                        title: "Error",
                                        icon: "error",
                                        msg: res.message
                                    });
                                }
                            }).catch((error) => {
                                this.disableBtnSave = false;
                                this.$refs.notification.error(error);
                            });

                    }else{
                        this.$refs.dlg.close();
                    }
                    this.$refs.dataGrid.unselectRow(this.$refs.dataGrid.selectedRows[0])
                } else {
                    for(let key in valid){
                        this.textError[key] = this.getError(key);
                    }
                }
            });
        },

        // handle form item
        onDeleteDetail(){
            if(this.$refs.dataGridDetail.selectedRows.length > 0){
                this.$messager.confirm({
                    title: 'Confirm',
                    msg: InformationUtil.confirmDelete,
                    result: (r) => {
                        if (r){
                            this.dataGridDetail.loading = true;
                            // find index
                            let index = -1;
                            for(let i = 0; i < this.dataGridDetail.rows.length; i++){
                                if(this.dataGridDetail.rows[i].product_name == this.$refs.dataGridDetail.selectedRows[0].product_name){
                                    index = i;
                                    break;
                                }
                            }
                            if(index > -1) this.dataGridDetail.rows.splice(index, 1);
                            this.dataGridDetail.loading = false;
                            this.$refs.notification.success(this.titleMessage + " " + InformationUtil.delete);
                            this.$refs.dataGridDetail.unselectRow(this.$refs.dataGridDetail.selectedRows[0]);
                            this.dataGridDetail.rowsTemp = this.dataGridDetail.rows;
                        }
                    }
                })
            }else{
                this.$messager.alert({
                    title: "Info",
                    icon: "info",
                    msg: InformationUtil.selectRow
                });
            }
        },
        addDetail(){
            if(this.dataGridDetail.rows == null) this.dataGridDetail.rows = [];

            // validation
            if(this.modelInputDetail.product_name == null || this.modelInputDetail.product_name == ""){
                this.$messager.alert({
                    title: "Error",
                    icon: "error",
                    msg: "Product Name is required"
                });
                return;
            }

            if(this.modelInputDetail.qty == null || this.modelInputDetail.qty == "" || this.modelInputDetail.qty == 0){
                this.$messager.alert({
                    title: "Error",
                    icon: "error",
                    msg: "Qty is required"
                });
                return;
            }

            if(this.modelInputDetail.weight == null || this.modelInputDetail.weight == "" || this.modelInputDetail.weight == 0){
                this.$messager.alert({
                    title: "Error",
                    icon: "error",
                    msg: "Weight is required"
                });
                return;
            }

            if(this.modelInputDetail.volume == null || this.modelInputDetail.volume == "" || this.modelInputDetail.volume == 0){
                this.$messager.alert({
                    title: "Error",
                    icon: "error",
                    msg: "Volume is required"
                });
                return;
            }

            if(this.modelInputDetail.uom == null || this.modelInputDetail.uom == "" || this.modelInputDetail.uom == 0){
                this.$messager.alert({
                    title: "Error",
                    icon: "error",
                    msg: "UOM is required"
                });
                return;
            }
            
            // validation not same name product name in exist data
            for(let i = 0; i < this.dataGridDetail.rows.length; i++){
                if(this.dataGridDetail.rows[i].product_name == this.modelInputDetail.product_name){
                    this.$messager.alert({
                        title: "Error",
                        icon: "error",
                        msg: "Product Name is exist"
                    });
                    return;
                }
            }

            let data = {
                product_name : this.modelInputDetail.product_name,
                uom : this.modelInputDetail.uom,
                uom_desc : this.uomListV2.find(x => x.value == this.modelInputDetail.uom).text,
                qty : parseInt(this.modelInputDetail.qty),
                qty_remain : parseInt(this.modelInputDetail.qty),
                weight : parseInt(this.modelInputDetail.weight),
                weight_remain : parseInt(this.modelInputDetail.weight),
                volume : parseInt(this.modelInputDetail.volume),
                volume_remain : parseInt(this.modelInputDetail.volume),
                amount : parseInt(this.modelInputDetail.qty) * parseInt(this.modelInputDetail.weight)
            }
            this.dataGridDetail.rows.push(data);
            this.dataGridDetail.rowsTemp = this.dataGridDetail.rows;
            this.dataGridDetail.total = this.dataGridDetail.rows.length;

            ModelUtil.resetModel(this.modelInputDetail);
        },
        onSearchDetail(){
            let search = this.dataGridDetail.search;
            let rows = this.dataGridDetail.rowsTemp;
            let result = [];
            if(search != null && search != ""){
                for(let i = 0; i < rows.length; i++){
                    if(rows[i].product_name.toLowerCase().indexOf(search.toLowerCase()) > -1){
                        result.push(rows[i]);
                    }
                }
                this.dataGridDetail.rows = result;
            }else{
                this.dataGridDetail.rows = this.dataGridDetail.rowsTemp;
            }
        },

        // handle data grid
        onMultiSearch(){
            this.onCallAllOption();
            this.$refs.dlgSearch.open();
        },
        onSearchMulti(){
            this.dataGrid.pageNumber = 1;
            this.$refs.dataGrid.unselectRow(this.$refs.dataGrid.selectedRows[0]);

            // set date format
            this.modelMultiSearch.req_delivery_date_from = this.modelMultiSearch.req_delivery_date_from != null ? new Date(this.$moment(this.modelMultiSearch.req_delivery_date_from).format('YYYY-MM-DD')) : null;
            this.modelMultiSearch.req_delivery_date_to = this.modelMultiSearch.req_delivery_date_to != null ? new Date(this.$moment(this.modelMultiSearch.req_delivery_date_to).format('YYYY-MM-DD')) : null;
            this.modelMultiSearch.created_date_from = this.modelMultiSearch.created_date_from != null ? new Date(this.$moment(this.modelMultiSearch.created_date_from).format('YYYY-MM-DD')) : null;
            this.modelMultiSearch.created_date_to = this.modelMultiSearch.created_date_to != null ? new Date(this.$moment(this.modelMultiSearch.created_date_to).format('YYYY-MM-DD')) : null;

            this.loadData();
            this.loadDataPreferredVehicle();
            this.$refs.dlgSearch.close();
        },
        onResetSearchMulti(){
            this.modelMultiSearch = ModelUtil.resetModel(this.modelMultiSearch);

            this.modelMultiSearch.req_delivery_date_from = null;
            this.modelMultiSearch.req_delivery_date_to = null;
            this.modelMultiSearch.created_date_from = null;
            this.modelMultiSearch.created_date_to = null;

            this.dataGrid.pageNumber = 1;
            this.$refs.dataGrid.unselectRow(this.$refs.dataGrid.selectedRows[0]);
            this.loadData();
            this.loadDataPreferredVehicle();
            this.$refs.dlgSearch.close();
        },
        async onSelectionStatusData(){
            this.dataGrid.pageNumber = 1;
            await this.loadData();
            await this.loadDataPreferredVehicle();
            this.$refs.dataGrid.unselectRow(this.$refs.dataGrid.selectedRows[0]);
            if(this.filter.viewStatusMessage){
                this.$refs.notification.success(InformationUtil.refresh);
            }
            this.filter.viewStatusMessage = true;
        },

        onRowDblClick(){
            if(this.action.edit){
                this.onEdit();
            }
        },
        async onRefresh(){
            this.dataGrid.pageNumber = 1;
            this.$refs.dataGrid.unselectRow(this.$refs.dataGrid.selectedRows[0]);
            await this.loadData();
            await this.loadDataPreferredVehicle();
            this.$refs.notification.success(InformationUtil.refresh);
        },
        async onSearch(){
            await this.loadData();
            await this.loadDataPreferredVehicle();
            this.$refs.dataGrid.unselectRow(this.$refs.dataGrid.selectedRows[0]);
            this.$refs.notification.success(InformationUtil.filter);
        },
        onSortChange(event){
            if(event.length > 0){
                this.dataGrid.pageNumber = 1;
                this.dataGrid.sortField = event[0].field;
                this.dataGrid.sortOrder = event[0].order;
                this.loadData();
                this.loadDataPreferredVehicle();
            }
        },
        onPageChange(event) {
            this.$refs.dataGrid.unselectRow(this.$refs.dataGrid.selectedRows[0]);
            this.dataGrid.pageNumber = event.pageNumber;
            this.dataGrid.pageSize = event.pageSize;
            if(event.refresh) this.dataGrid.pageNumber = 1;
            this.loadData();
            this.loadDataPreferredVehicle();
            if(event.refresh){
                this.$refs.notification.success(InformationUtil.refresh);
            }
        },

        async loadData(){
            this.dataGrid.loading = true;
            let param = {
                statusData : this.searchStatusData,
                multiSearch : this.modelMultiSearch,
            }
            return OutboundService.getData('/salesOrder', this.dataGrid, param)
                .then((response) => {
                    if(response.data !== "" && response.data !== null){
                        let data = response.data; 
                        this.dataGrid.total = data.total;
                        this.dataGrid.rows = data.rows;
                    }
                    this.dataGrid.loading = false;

                    this.$refs.gridSuggestionSalesOrder.onPageShow();
                });
        },

        async loadDataPreferredVehicle(){
            let param = {
                statusData : this.searchStatusData,
                multiSearch : this.modelMultiSearch,
            }
            return OutboundService.getData('/salesOrder/preferredVehicle', this.dataGrid, param)
                .then((response) => {
                    if(response.data !== "" && response.data !== null){
                        let data = response.data;
                        if(data){
                            this.dataTotalRemain.qty = data.total_qty_remain;
                            this.dataTotalRemain.volume = data.total_volume_remain;
                            this.dataTotalRemain.weight = data.total_weight_remain;
                            this.dataGridPreferredVehicle.rows = data.data_vehicle;
                        }
                    }
                });
        },

        // handle grid suggestion
        onSuggestion(){
            this.disableBtnSave = false;
            this.$refs.gridSuggestionSalesOrder.onPageShow();
        },
        onGenerateData(data) {
            // disable button generate
            this.disableBtnSave = true;
            if(data.length > 0) {
                let dataParam = {
                    "dataSuggest" : data
                };

                OutboundService.post("/salesOrder/generateData", dataParam)
                    .then((res) => {
                        this.disableBtnSave = false;
                        if(res.code == 200){
                            this.$refs.notification.success(this.titleMessage + " " + InformationUtil.generate);
                            this.loadData();
                            this.loadDataPreferredVehicle();
                            this.$refs.gridSuggestionSalesOrder.onPageClose();
                        } else {
                            this.$messager.alert({
                                title: "Error",
                                icon: "error",
                                msg: res.message
                            });
                        }
                    }).catch((error) => {
                        this.disableBtnSave = false;
                        this.$refs.notification.error(error);
                    });
            }
        },

        // combo box data
        async optionClient(event){
            let search = event == null ? "" : event.filterValue;
            return await OptionService.getOption("/client", search)
                .then((response) => {
                    this.clients = response.data;
                }).catch((error) => {
                    this.$refs.notification.error(error);
                });
        },
        async optionCustomerOrigin(event){
            let search = event == null ? "" : event.filterValue;
            return await OptionService.getOption("/customer", search)
                .then((response) => {
                    this.customer_origins = response.data;
                }).catch((error) => {
                    this.$refs.notification.error(error);
                });
        },
        async optionCustomerDestination(event){
            let search = event == null ? "" : event.filterValue;
            return await OptionService.getOption("/customer", search)
                .then((response) => {
                    this.customer_destinations = response.data;
                }).catch((error) => {
                    this.$refs.notification.error(error);
                });
        },
        async optionUom(event){
            let search = event == null ? "" : event.filterValue;
            return await OptionService.getOption("/uom", search)
                .then((response) => {
                    this.uomList = response.data;
                }).catch((error) => {
                    this.$refs.notification.error(error);
                });
        },
        async optionUomV2(event){
            let search = event == null ? "" : event.filterValue;
            return await OptionService.getOption("/uom2", search)
                .then((response) => {
                    this.uomListV2 = response.data;
                }).catch((error) => {
                    this.$refs.notification.error(error);
                });
        },
        async ringCode(event){
            let search = event == null ? "" : event.filterValue;
            return await OptionService.getOption("/ringCode", search)
                .then((response) => {
                    this.orderTypes = response.data;
                }).catch((error) => {
                    this.$refs.notification.error(error);
                });
        },
        onCallAllOption(){
            this.optionClient();
            this.optionCustomerOrigin();
            this.optionCustomerDestination();
            this.optionUom();
            this.optionUomV2();
            this.ringCode();
        },
        onSelectionCustomerOrigin(row){
            this.modelNotMapping.origin_customer_name = row.name;
            this.modelNotMapping.origin_customer_address = row.address;
        },
        onSelectionCustomerDestination(row){
            this.modelNotMapping.dest_customer_name = row.name;
            this.modelNotMapping.dest_customer_address = row.address;
        },
    },
    async mounted(){
        this.action = AccessUtil.getAccessAction('sales_order');
        await this.loadData();
        await this.loadDataPreferredVehicle();
        this.$refs.notification.success(InformationUtil.loadGrid + " " + this.dataGrid.total + " " + InformationUtil.successLoadGrid);
    },
    created(){
        GlobalUtil.removeWindow();
    }
};
</script>