<template>
    <div>
        <Panel :title="titleView"  style="margin:0px 1px; ">
            <div class="datagrid-toolbar">
                <LinkButton :plain="true" :iconCls="iconUtil.iconRefresh" @click="onRefresh()">{{ btnText.refresh }}</LinkButton>
                <LinkButton v-if="action.add" :plain="true" :iconCls="iconUtil.iconAdd" @click="onAdd()">{{ btnText.add }}</LinkButton>
                <LinkButton v-if="action.edit" :plain="true" :iconCls="iconUtil.iconEdit" @click="onEdit()">{{ btnText.edit }}</LinkButton>
                <LinkButton v-if="action.delete" :plain="true" :iconCls="iconUtil.iconDelete" @click="onDelete()">{{ btnText.delete }}</LinkButton>
                <span class="button-sep"></span> {{ search.text }} : 
                <SearchBox style="width:300px"
                    :placeholder="search.placeholder" 
                    v-model="dataGrid.search"
                    @search="onSearch()">
                    <Addon>
                        <span v-if="dataGrid.search" class="textbox-icon icon-clear" title="Clear value" @click="dataGrid.search=null;onSearch();"></span>
                    </Addon>
                </SearchBox>
            </div>
            <DataGrid
                    ref="dataGrid"
                    :columnResizing="true"
                    :border="false"
                    :lazy="true"
                    :loading="dataGrid.loading"                                    
                    :data="dataGrid.rows"
                    :pagination="true"
                    :selectionMode="dataGrid.selectionMode"
                    :total="dataGrid.total"
                    :pageSize="dataGrid.pageSize"
                    :pageNumber="dataGrid.pageNumber"
                    :pageLayout="dataGrid.pageLayout" 
                    @pageChange="onPageChange($event)"
                    @sortChange="onSortChange($event)"
                    @rowDblClick="onRowDblClick()"
                    >
                
                    <GridColumn align="center" cellCss="datagrid-td-rownumber" width="30">
                    <template slot="body" slot-scope="scope">
                        {{scope.rowIndex + 1}}
                    </template>
                </GridColumn>
                <GridColumn field="email" title="Email" :sortable="true"></GridColumn>
                <GridColumn field="role_desc" title="Role" :sortable="true"></GridColumn>
                <GridColumn field="name" title="Name" :sortable="true"></GridColumn>
                <GridColumn field="phone" title="Phone" :sortable="true"></GridColumn>
                <GridColumn field="contact_email" title="Contact Email" :sortable="true"></GridColumn>
                <GridColumn field="gender_desc" title="Gender" :sortable="true"></GridColumn>
                <GridColumn field="is_active_desc" title="Status" :sortable="true"></GridColumn>
            </DataGrid>
        </Panel>

        <!-- Form -->
        <Dialog ref="dlg" :iconCls="icon" bodyCls="f-column" :title="title" :draggable="true" :modal="true" closed :dialogStyle="{width:'450px', height:'auto'}">
            <div class="f-full" style="overflow:auto">
                <Form ref="form" :model="model" :rules="rules" @validate="errors=$event">
                    <table width="100%" cellspacing="1" cellpadding="5" border="0">
                        <tbody>
                            <tr>
                                <td class="bg_subtitle">
                                    Email <font color="red">*</font>
                                </td>
                                <td class="bg_grid2"> 
                                    <TextBox inputId="email" name="email" class="f-field-text" v-model="model.email" :disabled="disabled"></TextBox>
                                    <div class="error">{{textError.email}}</div>
                                </td>
                            </tr>

                            <tr :hidden="hiddenPassword">
                                <td class="bg_subtitle">
                                    Password
                                </td>
                                <td class="bg_grid2"> 
                                    <PasswordBox inputId="password" name="password" class="f-field-text" v-model="model.password" :disabled="disabled"></PasswordBox>
                                    <div class="error">{{textError.password}}</div>
                                </td>
                            </tr>

                            <tr>
                                <td class="bg_subtitle">Role <font color="red">*</font></td>
                                <td class="bg_grid2">
                                    <ComboBox  :data="roles" inputId="role_id" name="role_id" class="f-field-text" v-model="model.role_id"></ComboBox>
                                    <div class="error">{{textError.role_id}}</div>
                                </td>
                            </tr>

                            <tr>
                                <td class="bg_subtitle">
                                    Name <font color="red">*</font>
                                </td>
                                <td class="bg_grid2"> 
                                    <TextBox inputId="name" name="name" class="f-field-text" v-model="model.name"></TextBox>
                                    <div class="error">{{textError.name}}</div>
                                </td>
                            </tr>

                            <tr>
                                <td class="bg_subtitle">
                                    Phone
                                </td>
                                <td class="bg_grid2"> 
                                    <TextBox inputId="phone" name="phone" class="f-field-text" v-model="model.phone"></TextBox>
                                </td>
                            </tr>

                            <tr>
                                <td class="bg_subtitle">
                                    Contact Email <font color="red">*</font>
                                </td>
                                <td class="bg_grid2"> 
                                    <TextBox inputId="contact_email" name="contact_email" class="f-field-text" v-model="model.contact_email"></TextBox>
                                    <div class="error">{{textError.contact_email}}</div>
                                </td>
                            </tr>

                            <tr>
                                <td class="bg_subtitle">Gender <font color="red">*</font></td>
                                <td class="bg_grid2">
                                    <ComboBox  :data="genders" inputId="gender" name="gender" class="f-field-text" v-model="model.gender"></ComboBox>
                                    <div class="error">{{textError.gender}}</div>
                                </td>
                            </tr>
                            <tr>
                                <td class="bg_subtitle">Client ID</td>
                                <td class="bg_grid2">
                                    <ComboBox inputId="client_id" style="width:300px"
                                        v-model="model.client_id"
                                        :data="clients" 
                                        :multiple="true"></ComboBox>
                                </td>
                            </tr>
                            <tr>
                                <td class="bg_subtitle">Is Change Rate</td>
                                <td class="bg_grid2">
                                    <SwitchButton inputId="is_change_rate" name="is_change_rate" class="f-field-text" v-model="model.is_change_rate"></SwitchButton>
                                </td>
                            </tr>

                            <tr>
                                <td class="bg_subtitle">Company</td>
                                <td class="bg_grid2">
                                    <ComboBox  :data="companies" inputId="company_id" name="company_id" class="f-field-text" v-model="model.company_id"></ComboBox>
                                    <div class="error">{{textError.company_id}}</div>
                                </td>
                            </tr>
                            
                            <tr>
                                <td class="bg_subtitle">Is Active</td>
                                <td class="bg_grid2">
                                    <SwitchButton inputId="is_active" name="is_active" class="f-field-text" v-model="model.is_active"></SwitchButton>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </Form>
            </div>
            <div class="dialog-button">
                <LinkButton :iconCls="iconUtil.iconSave" v-if="action.add || action.edit" @click="onSave()" :disabled="disableBtnSave" style="width:89px">{{ btnText.save }}</LinkButton>
                <LinkButton :iconCls="iconUtil.iconCancel" @click="$refs.dlg.close()" style="width:89px">{{ btnText.cancel }}</LinkButton>
            </div>
        </Dialog>

        <NotificationComponent ref="notification"></NotificationComponent>
    </div>
    
</template>

<script>
import NotificationComponent from '@/components/NotificationComponent.vue';
import User from '@/models/User';
import UserManagementService from '@/services/UserManagementService';
import OptionService from '@/services/OptionService';

import ModelUtil from '@/utils/ModelUtil';
import InformationUtil from '@/utils/InformationUtil';
import AccessUtil from '@/utils/AccessUtil';
import IconUtil from '@/utils/IconUtil';
import GlobalUtil from '@/utils/GlobalUtil';

export default {
    name: 'UsersView',
    components : {
        NotificationComponent
    },
    data() {
        return {
            titleView : InformationUtil.user + "s",
            titleMessage : InformationUtil.user,
            iconUtil : IconUtil,

            titleForm :{
                add : InformationUtil.addText,
                edit : InformationUtil.editText
            },

            iconForm :{
                add : IconUtil.iconAdd,
                edit : IconUtil.iconEdit
            },
            
            action : {
                view : false,
                add : false,
                edit : false,
                delete : false,
            },

            btnText :{
                refresh : InformationUtil.refreshText,
                add : InformationUtil.addText,
                edit : InformationUtil.editText,
                delete : InformationUtil.removeText,
                save : InformationUtil.saveText,
                cancel : InformationUtil.cancelText,
                search : InformationUtil.searchText
            },

            search : {
                text : InformationUtil.searching,
                placeholder : InformationUtil.searchHolder
            },

            dataGrid : {
                loading: false,
                rows : [],
                total: 0,
                start: 0,
                pageNumber: 1,
                pageSize: 10,
                selectionMode : 'single',
                pageLayout: ['list','sep','first','prev','next','last','sep','refresh','info'],

                // filter
                search : null,
                sortField : "",
                sortOrder : ""
            },

            // form
            title: '',
            icon: '',
            model: User,
            disabled : false,
            disableBtnSave : false,
            hiddenPassword : false,

            // validation
            rules: {
                email: ['required', 'email'],
                name: 'required',
                gender: 'required',
                role_id: 'required',
                contact_email: ['required', 'email'],
            },
            errors: {},
            textError :{
                email: '',
                name: '',
                gender: '',
                role_id: '',
                contact_email: '',
            },

            // combo box
            genders : [
                { value: 1, text: "MALE" },
                { value: 2, text: "FEMALE" }
            ],
            roles : [],
            clients : [],
            companies : [
                { value: 1, text: "PT. ERUNIX TEKNOLOGI INOVASI" },
                { value: 2, text: "PT. SUKSEMA RAYYAN LOGISTIK" }
            ]
        }
    },
    methods:{
        onTabSelected(){
            this.dataGrid.search = null;
            this.onRefresh();
        },
        getError(name) {
            return this.errors[name] && this.errors[name].length
            ? this.errors[name][0]
            : null;
        },
        onAdd(){
            ModelUtil.resetModel(this.textError);

            this.optionRole();
            this.optionClient();

            // method for add data
            this.disabled = false;
            this.$refs.dataGrid.unselectRow(this.$refs.dataGrid.selectedRows[0]);
            this.model = ModelUtil.resetModel(this.model);
            
            // set default switch box
            this.model.is_active = true;
            this.model.is_change_rate = false;
            this.hiddenPassword = false;
            
            this.title = this.titleForm.add;
            this.icon = this.iconForm.add;
            this.$refs.dlg.open();
        },
        onEdit(){
            ModelUtil.resetModel(this.textError);
            // method for get data to edit
            if(this.$refs.dataGrid.selectedRows.length > 0){
                this.disabled = true;

                this.optionRole();
                this.optionClient();
                
                this.title = this.titleForm.edit;
                this.icon = this.iconForm.edit;
                this.model = ModelUtil.resetModel(this.model);
                this.model = ModelUtil.mapModel(this.model, this.$refs.dataGrid.selectedRows[0])

                this.model.is_active = this.model.is_active == 1 ? true : false;
                this.model.is_change_rate = this.model.is_change_rate == 1 ? true : false;
                this.model.client_id = this.model.client_id == null || this.model.client_id == "" || this.model.client_id == undefined ? "" :  JSON.parse(this.model.client_id);
                
                this.hiddenPassword = true;
                
                this.$refs.dlg.open();
            }else{
                this.$refs.notification.centerTop(InformationUtil.selectRow);
            }
        },
        onDelete(){
            if(this.$refs.dataGrid.selectedRows.length > 0){
                this.$messager.confirm({
                    title: 'Confirm',
                    msg: InformationUtil.confirmDelete,
                    result: (r) => {
                        if (r){
                            this.dataGrid.loading = true;
                            UserManagementService.deleteData("/user", this.$refs.dataGrid.selectedRows[0].id)
                                .then(() => {
                                    this.dataGrid.loading = false;
                                    this.$refs.notification.success(this.titleMessage + " " + InformationUtil.delete);
                                    this.loadData();
                                    this.$refs.dlg.close();
                                }).catch((err) => {
                                    this.$refs.notification.error(err.data.message);
                                });
                            this.$refs.dataGrid.unselectRow(this.$refs.dataGrid.selectedRows[0]) 
                        }
                    }
                })
                
            }else{
                this.$refs.notification.centerTop(InformationUtil.selectRow);
            }
            
        },
        onSave(){
            ModelUtil.resetModel(this.textError);
            this.$refs.form.validate((valid) => {
                if(!valid){
                    this.disableBtnSave = true; 
                    if(this.model.id == null){
                        if(this.model.password == null || this.model.password == ""){
                            this.textError.password = InformationUtil.required;
                            this.disableBtnSave = false; 
                            return;
                        }

                        // action add data
                        UserManagementService.addData("/user", this.model)
                            .then((res) => {
                                this.disableBtnSave = false;
                                if(res.code == 200){
                                    this.$refs.notification.success(this.titleMessage + " " + InformationUtil.add);
                                    this.loadData();
                                    this.$refs.dlg.close();
                                } else {
                                    this.$messager.alert({
                                        title: "Error",
                                        icon: "error",
                                        msg: res.message
                                    });
                                }
                            }).catch((error) => {
                                this.disableBtnSave = false;
                                this.$refs.notification.error(error);
                            });

                    }else{
                        // action update data
                        UserManagementService.updateData("/user", this.model.id, this.model)
                            .then((res) => {
                                this.disableBtnSave = false;
                                if(res.code == 200){
                                    this.$refs.notification.success(this.titleMessage + " " + InformationUtil.update);
                                    this.loadData();
                                    this.$refs.dlg.close();
                                } else {
                                    this.$messager.alert({
                                        title: "Error",
                                        icon: "error",
                                        msg: res.message
                                    });
                                }
                            }).catch((error) => {
                                this.disableBtnSave = false;
                                this.$refs.notification.error(error);
                            });
                    }
                    this.$refs.dataGrid.unselectRow(this.$refs.dataGrid.selectedRows[0])
                } else {
                    for(let key in valid){
                        this.textError[key] = this.getError(key);
                    }
                }
            });
            
        },
        
        // handle data grid
        onRowDblClick(){
            if(this.action.edit){
                this.onEdit();
            }
        },
        async onRefresh(){
            this.dataGrid.pageNumber = 1;       
            this.$refs.dataGrid.unselectRow(this.$refs.dataGrid.selectedRows[0]);
            await this.loadData();
            this.$refs.notification.success(InformationUtil.refresh);
        },
        onSearch(){
            this.loadData();
        },
        onSortChange(event){
            if(event.length > 0){
                this.dataGrid.pageNumber = 1;
                this.dataGrid.sortField = event[0].field;
                this.dataGrid.sortOrder = event[0].order;
                this.loadData();
            }
        },
        onPageChange(event) {
            this.$refs.dataGrid.unselectRow(this.$refs.dataGrid.selectedRows[0]);
            this.dataGrid.pageNumber = event.pageNumber;
            this.dataGrid.pageSize = event.pageSize;
            if(event.refresh) this.dataGrid.pageNumber = 1;
            this.loadData();
            if(event.refresh){
                this.$refs.notification.success(InformationUtil.refresh);
            }
        },

        async loadData(){
            this.dataGrid.loading = true;
            return UserManagementService.getData('/user', this.dataGrid)
                .then((response) => {
                    if(response.data !== "" && response.data !== null){
                        let data = response.data; 
                        this.dataGrid.total = data.total;
                        this.dataGrid.rows = data.rows;
                    }
                    
                    this.dataGrid.loading = false;
                });
        },

        // combo box data
        async optionRole(event){
            let search = event == null ? "" : event.filterValue;
            return await OptionService.getOption("/role", search)
                .then((response) => {
                    this.roles = response.data;
                }).catch((error) => {
                    this.$refs.notification.error(error);
                });
        },

        // combo client data
        async optionClient(event){
            let search = event == null ? "" : event.filterValue;
            return await OptionService.getOption("/clientAll", search)
                .then((response) => {
                    this.clients = response.data;
                }).catch((error) => {
                    this.$refs.notification.error(error);
                });
        },
    },
    async mounted(){
        this.action = AccessUtil.getAccessAction('users');
        await this.loadData();
        this.$refs.notification.success(InformationUtil.loadGrid + " " + this.dataGrid.total + " " + InformationUtil.successLoadGrid);
    },
    created(){
        GlobalUtil.removeWindow();
    }
};
</script>