<template>
    <div>

    </div>
</template>

<script>
    import TransportService from "@/services/TransportService";


    export default{
        name: "PrintSalesInvoiceComponent",
        props: {
            id: {
                type: Number,
                required: true
            }
        },
        data(){
            return{
            }
        },
        methods: {
            print(){
                if(this.$props.id != 0 && this.$props.id != null){
                    let param = {id: this.$props.id};
                    TransportService.downloadFile("/salesInvoice/print", param)
                        .then((res) => {
                            if(res.type === 'application/pdf' && res != null){
                                let blob = new Blob([res], { type: 'application/pdf' })
                                let objectUrl = window.URL.createObjectURL(blob)
                                window.open(objectUrl)
                            } else {
                                this.$messager.alert({
                                            title: "Error",
                                            icon: "error",
                                            msg: res.message
                                        });
                            }
                        }).catch((error) => {
                            this.$refs.notification.error(error);
                        });
                }
            }
        },
    }
</script>