export default {
    id: null,
    client_id : "",
    name : "",
    address1 : "",
    address2 : "",
    city : "",
    postal_code : "",
    phone : "",
    fax : "",
    pic : "",
    email : "",
    additional_information : "",
    payment_term : "",
    is_by_type : "0",

    // handling error
    attributeDescription : {
        area_id: "Client ID",
        name: "Client Name",
    },
}