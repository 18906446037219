export default {
    id: null,
    customer_id  : "",
    name  : "",
    address1  : "",
    address2  : "",
    type  : "",
    city  : "",
    position  : "",
    region_id  : "",
    area_id  : "",
    postal_code  : "",
    phone  : "",
    fax  : "",
    pic  : "",
    email  : "",
    additional_information  : "",
    latitude  : "",
    longitude  : "",
    radius  : "",
    geofence_area  : "",

    postal_code_id  : "",
    item_postal_code  : "",
    province  : "",
    district  : "",
    sub_district  : "",
    urban_village  : "",

    vehicle_type_id  : "",
    max_weight  : 0,
    max_volume  : 0,
}