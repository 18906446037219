<template>
    <div>
        <Panel :title="title" style="margin:5px 1px;">
            <div class="datagrid-toolbar">
                <LinkButton :plain="true" :iconCls="iconUtil.iconRefresh" @click="onRefresh()">{{ btnText.refresh }}</LinkButton>
                <LinkButton :plain="true" :iconCls="iconUtil.iconSave" v-if="action.add || action.edit" @click="onGenerateData()" :disabled="$props.disableBtnSave" style="width:89px">{{ btnText.generate }}</LinkButton>
            </div>
            <DataGrid
                    ref="dataGrid"
                    :columnResizing="true"
                    :border="false"
                    :lazy="true"
                    :loading="dataGrid.loading"                                    
                    :data="dataGrid.rows"
                    :pagination="false"
                    :total="dataGrid.total"
                    :pageSize="dataGrid.pageSize"
                    :pageNumber="dataGrid.pageNumber"
                    :pageLayout="dataGrid.pageLayout" 
                    @pageChange="onPageChange($event)"
                    >
                <GridColumn :expander="true" width="30"></GridColumn>
                <GridColumn field="area_id" title="Area" :sortable="false">
                    <template slot="body" slot-scope="scope">
                        <div class="item">
                            <div class="title-suggestion">{{scope.row.area_id}}</div>
                        </div>
                    </template>
                </GridColumn>
                <GridColumn field="total_weight_remain" title="Total Weight" :sortable="false"></GridColumn>
                <template slot="detail" slot-scope="scope">
                    <div style="padding:2px 2px 2px 30px">
                        <DataGrid :data="scope.row.children">
                            <GridColumn :expander="true" width="30"></GridColumn>
                            <GridColumn field="suggest" title="Name">
                                <template slot="body" slot-scope="scope">
                                    <div class="item">
                                        <div class="title-suggestion">{{scope.row.suggest}}</div>
                                    </div>
                                </template>
                            </GridColumn>
                            <GridColumn field="total_weight_used" title="Loading Summary"></GridColumn>
                            <GridColumn field="total_weight_not_used" title="Under Utilization"></GridColumn>
                            <GridColumn field="total_price_desc" title="Total Price"></GridColumn>
                            <GridColumn field="net_per_kg" title="Net Per Kg"></GridColumn>
                            <template slot="detail" slot-scope="scope">
                                <div style="padding:2px 2px 2px 30px">
                                    <DataGrid :data="scope.row.children">   
                                        <GridColumn :expander="true" width="30"></GridColumn>
                                        <GridColumn field="ck" :width="50" align="center">
                                            <template slot="body" slot-scope="scope">
                                                <div class="dot" :class="{ 'dot-red' : scope.row.status_utilization == 0, 'dot-green' : scope.row.status_utilization == 1}"></div>&nbsp;&nbsp;
                                                <CheckBox v-model="scope.row.checked" @checkedChange="onCheckedChange($event)"></CheckBox>
                                            </template>
                                        </GridColumn>
                                        <GridColumn field="vehicle_type_desc" title="Vehicle">
                                            <template slot="body" slot-scope="scope">
                                                <div class="item">
                                                    <div class="title-suggestion">{{scope.row.vehicle_type_desc}}</div>
                                                </div>
                                            </template>
                                        </GridColumn>
                                        <GridColumn field="total_weight_vehicle" title="Total Load"></GridColumn>
                                        <GridColumn field="max_weight" title="Weight Capacity Of Vehicle"></GridColumn>
                                        <GridColumn field="max_volume" title="Volume Capacity Of Vehicle"></GridColumn>
                                        <GridColumn field="calculated_qty_vehicle" title="Number Of Vehicle"></GridColumn>
                                        <template slot="detail" slot-scope="scope">
                                            <div style="padding:2px 2px 2px 30px">
                                                <DataGrid :data="scope.row.children">
                                                    <GridColumn align="center" cellCss="datagrid-td-rownumber" width="30">
                                                        <template slot="body" slot-scope="scope">
                                                            {{scope.rowIndex + 1}}
                                                        </template>
                                                    </GridColumn>
                                                    <GridColumn :expander="true" width="30"></GridColumn>
                                                    <GridColumn field="vehicle_type_desc" title="Vehicle"></GridColumn>
                                                    <GridColumn field="nopol" title="Number Vehicle"></GridColumn>
                                                    <GridColumn field="origin_desc" title="Origin"></GridColumn>
                                                    <GridColumn field="dest_desc" title="Destination"></GridColumn>
                                                    <GridColumn field="rate_desc" title="Rate"></GridColumn>
                                                    <template slot="detail" slot-scope="scope">
                                                        <div style="padding:2px 2px 2px 30px">
                                                            <DataGrid :data="scope.row.children">
                                                                <GridColumn align="center" cellCss="datagrid-td-rownumber" width="30">
                                                                    <template slot="body" slot-scope="scope">
                                                                    {{scope.rowIndex + 1}}
                                                                    </template>
                                                                </GridColumn>
                                                                <GridColumn field="so_number" title="SO Number"></GridColumn>
                                                                <GridColumn field="req_delivery_date_desc" title="Req. Delivery Date" :sortable="true"></GridColumn>
                                                                <GridColumn field="created_date" title="Created Date" :sortable="true"></GridColumn>
                                                                <GridColumn field="origin_desc" title="Origin Id" :sortable="true"></GridColumn>
                                                                <GridColumn field="origin_name" title="Origin Name" :sortable="true"></GridColumn>
                                                                <GridColumn field="origin_address" title="Origin Address" :sortable="true"></GridColumn>
                                                                <GridColumn field="dest_desc" title="Destination Id" :sortable="true"></GridColumn>
                                                                <GridColumn field="dest_name" title="Destination Name" :sortable="true"></GridColumn>
                                                                <GridColumn field="dest_address" title="Destination Address" :sortable="true"></GridColumn>
                                                                <GridColumn field="client_desc" title="Client ID" :sortable="true"></GridColumn>
                                                                <GridColumn field="order_type_desc" title="Order Type" :sortable="true"></GridColumn>
                                                                <GridColumn field="qty" title="Qty"></GridColumn>
                                                                <GridColumn field="weight" title="Weight"></GridColumn>
                                                                <GridColumn field="volume" title="Volume"></GridColumn>
                                                                <GridColumn field="price_per_weight_desc" title="Price /KG"></GridColumn>
                                                            </DataGrid>
                                                        </div>
                                                    </template>
                                                </DataGrid>
                                            </div>
                                        </template>
                                    </DataGrid>
                                </div>
                            </template>
                        </DataGrid>
                    </div>
                </template>
            </DataGrid>
        </Panel>

        <NotificationComponent ref="notification"></NotificationComponent>
    </div>
</template>
<script>
    import NotificationComponent from '@/components/NotificationComponent.vue';

    import OutboundService from '@/services/OutboundService';
    import InformationUtil from '@/utils/InformationUtil';
    import AccessUtil from '@/utils/AccessUtil';
    import IconUtil from '@/utils/IconUtil';
    import GlobalUtil from '@/utils/GlobalUtil';

    export default {
        name: "GridSuggestionSalesOrderComponent",
        components : {
            NotificationComponent
        },
        props : {
            actionData : {
                type : String,
                default : ''
            },
            disableBtnSave : {
                type : Boolean,
                default : false
            },
            multiSearch : {
                type : Object,
                default : null
            },
            searchField : {
                type : String,
                default : ''
            },
            searchStatusData : {
                type : Number,
                default : 0
            }
        },
        data() {
            return {
                iconUtil : IconUtil,
                icon : IconUtil.iconAdd,
                title: InformationUtil.suggestionSalesOrder,
                btnText :{
                    refresh : InformationUtil.refreshText,
                    add : InformationUtil.addText,
                    edit : InformationUtil.editText,
                    delete : InformationUtil.removeText,
                    save : InformationUtil.saveText,
                    cancel : InformationUtil.cancelText,
                    search : InformationUtil.searchText,
                    generate : InformationUtil.generateText
                },

                search : {
                    text : InformationUtil.searching,
                    placeholder : InformationUtil.searchHolder
                },

                action : {
                    view : false,
                    add : false,
                    edit : false,
                    delete : false
                },
                dataGrid : {
                    loading: false,
                    rows : [],
                    total: 0,
                    start: 0,
                    pageNumber: 1,
                    pageSize: 10,
                    selectionMode : 'single',
                    pageLayout: ['list','sep','first','prev','next','last','sep','refresh','info'],

                    // filter
                    search : "",
                    sortField : "",
                    sortOrder : ""
                },
                dataChecked : []
            }
        },
        methods: {
            onCheckedChange() {
                this.dataGrid.rows.map((item) => {
                    if(item.children.length > 0) {
                        item.children.map((itemDetail) => {
                            if(itemDetail.children.length > 0) {
                                itemDetail.children.map((itemChild) => {
                                    if(itemChild.checked){
                                        // if not exist in array
                                        if(!this.dataChecked.includes(itemChild)){
                                            this.dataChecked.push(itemChild);
                                        }
                                    } else {
                                        // if exist in array
                                        if(this.dataChecked.includes(itemChild)){
                                            this.dataChecked.splice(this.dataChecked.indexOf(itemChild), 1);
                                        }
                                    }
                                });
                            }
                        });
                    }
                });

                if(this.dataChecked.length > 0){
                    // get first index checked in array
                    let firstIndex = this.dataChecked[0];

                    // get last data checked
                    let lastIndex = this.dataChecked[this.dataChecked.length - 1];
                    if(firstIndex.area_id === lastIndex.area_id && firstIndex.suggest === lastIndex.suggest){
                        console.log("");
                    } else {
                        this.dataChecked.map((itemChecked) => {
                            if(itemChecked !== lastIndex) {
                                // set checked false on data grid
                                this.dataGrid.rows.map((item) => {
                                    if(item.children.length > 0) {
                                        item.children.map((itemDetail) => {
                                            if(itemDetail.children.length > 0) {
                                                itemDetail.children.map((itemChild) => {
                                                    if(itemChild === itemChecked){
                                                        itemChild.checked = false;
                                                    }
                                                });
                                            }
                                        });
                                    }
                                });

                                // delete data in array
                                this.dataChecked.splice(this.dataChecked.indexOf(itemChecked), 1);
                            }
                        });
                    }
                }
            },
            onPageShow(){
                this.dataChecked = [];
                this.action = AccessUtil.getAccessAction(this.$props.actionData);
                this.loadData()

                // this.$refs.dlg.open();
            },
            onPageClose(){
                this.dataChecked = [];
                // this.$refs.dlg.close();
            },
            onGenerateData(){
                let itemChecked = [];
                this.dataGrid.rows.map((item) => {
                                if(item.children.length > 0) {
                                    item.children.map((itemDetail) => {
                                        if(itemDetail.children.length > 0) {
                                            itemDetail.children.map((itemChild) => {
                                                if(itemChild.checked){
                                                    itemChecked.push(itemChild);
                                                }
                                            });
                                        }
                                    });
                                }
                            });
                if(itemChecked.length > 0){
                    this.$messager.confirm({
                            title: 'Confirm',
                            msg: InformationUtil.confirmGenerate,
                            result: (r) => {
                                if (r){
                                    this.$emit('onGenerateData', itemChecked);
                                }
                            }
                        })
                }else{
                    this.$messager.alert({
                                        title: "Error",
                                        icon: "error",
                                        msg: InformationUtil.checkedRow
                                    });
                }
            },
            async onRefresh(){
                this.dataChecked = [];
                this.dataGrid.pageNumber = 1;
                await this.loadData();
                this.$refs.notification.success(InformationUtil.refresh);
            },
            onPageChange(event) {
                this.dataChecked = [];
                this.dataGrid.pageNumber = event.pageNumber;
                this.dataGrid.pageSize = event.pageSize;
                if(event.refresh) this.dataGrid.pageNumber = 1;
                this.loadData();            
            },
            async loadData(){
                this.dataChecked = [];
                this.dataGrid.loading = true;   
                let param = {
                    statusData : this.$props.searchStatusData,
                    multiSearch : this.$props.multiSearch,
                    search : this.$props.searchField
                }
                return OutboundService.getData('/salesOrder/suggestionData', this.dataGrid, param)
                    .then((response) => {
                        if(response.data !== "" && response.data !== null){
                            let data = response.data; 
                            this.dataGrid.total = data.total;
                            this.dataGrid.rows = data.rows;
                        }
                        this.dataGrid.loading = false;
                    });
            },
        },
        created(){
            GlobalUtil.removeWindow();
        }
    }
</script>