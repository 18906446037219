<template>
    <div>
        <Dialog ref="dlg" bodyCls="f-column" :iconCls="icon" :title="title" :draggable="true" :modal="true" closed :dialogStyle="{width:'768px', height:'390px'}">
            <div class="f-full" style="overflow:auto">
                <div class="datagrid-toolbar">
                    <LinkButton v-if="action.edit" :plain="true" :iconCls="iconUtil.iconEdit" @click="onEdit()">{{ btnText.edit }}</LinkButton>
                </div>
                <DataGrid
                        ref="dataGrid"
                        :columnResizing="true"
                        :border="false"                                   
                        :data="dataGrid.rows"
                        :pagination="true"
                        :selectionMode="dataGrid.selectionMode"
                        :pageSize="dataGrid.pageSize"
                        :pageNumber="dataGrid.pageNumber"   
                        :pageLayout="dataGrid.pageLayout"
                        @rowDblClick="onRowDblClick()"
                        >
                    
                    <GridColumn align="center" cellCss="datagrid-td-rownumber" width="30">
                        <template slot="body" slot-scope="scope">
                            {{scope.rowIndex + 1}}
                        </template>
                    </GridColumn>
                    <GridColumn field="ck" :width="50" align="center">
                        <template slot="header">
                            <CheckBox ref="allDataChecked" v-model="dataGrid.allChecked" @checkedChange="onAllCheckedChange($event)"></CheckBox>
                        </template>
                        <template slot="body" slot-scope="scope">
                            <CheckBox v-model="scope.row.checked" @checkedChange="onCheckedChange($event)"></CheckBox>
                        </template>
                    </GridColumn>
                    <GridColumn field="product_name" title="Product"></GridColumn>
                    <GridColumn field="qty_remain" title="Qty Remain"></GridColumn>
                    <GridColumn field="qty_delivery" title="Qty Delivery"></GridColumn>
                </DataGrid>
            </div>
            <div class="dialog-button">
                <LinkButton :iconCls="iconUtil.iconSave" v-if="action.add || action.edit" @click="onSaveData()" :disabled="$props.disableBtnSave" style="width:89px">{{ btnText.save }}</LinkButton>
                <LinkButton :iconCls="iconUtil.iconCancel" @click="$refs.dlg.close()" style="width:89px">{{ btnText.cancel }}</LinkButton>
            </div>
        </Dialog>

        <Dialog ref="dlgDetailData" :iconCls="iconFormData" bodyCls="f-column" :title="titleFormData" :draggable="true" :modal="true" closed :dialogStyle="{width:'380px', height:'auto'}">
            <div class="f-full" style="overflow:auto">
                <table width="100%" cellspacing="1" cellpadding="5" border="0">
                    <tbody>
                        <tr>
                            <td class="bg_subtitle">Produc Name</td>
                            <td class="bg_grid2">
                                {{ itemDetailData.product_name }}
                            </td>
                        </tr>
                        <tr>
                            <td class="bg_subtitle">Qty Remain</td>
                            <td class="bg_grid2">
                                {{ itemDetailData.qty_remain }}
                            </td>
                        </tr>
                        <tr>
                            <td class="bg_subtitle">Qty Delivery</td>
                            <td class="bg_grid2">
                                <NumberBox inputId="start" name="start" class="f-field-text" v-model="itemDetailData.qty_delivery" :spinners="false"></NumberBox>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="dialog-button">
                <LinkButton :iconCls="iconUtil.iconSave" @click="onSaveDetailData()" :disabled="disableBtnSaveData" style="width:89px">{{ btnText.save }}</LinkButton>
                <LinkButton :iconCls="iconUtil.iconCancel" @click="$refs.dlgDetailData.close()" style="width:89px">{{ btnText.cancel }}</LinkButton>
            </div>
        </Dialog>

        <NotificationComponent ref="notification"></NotificationComponent>
    </div>
</template>
<script>
import NotificationComponent from '@/components/NotificationComponent.vue';
import InformationUtil from '@/utils/InformationUtil';
import AccessUtil from '@/utils/AccessUtil';
import IconUtil from '@/utils/IconUtil';
import GlobalUtil from '@/utils/GlobalUtil';
import ModelUtil from '@/utils/ModelUtil';

export default {
        name : "GridItemOrderDetailComponent",
        props : {
            actionData : {
                type : String,
                default : ''
            },
            disableBtnSave : {
                type : Boolean,
                default : false
            },
            itemData : {
                type : Array
            }
        },
        components : {
            NotificationComponent
        },
        data(){
            return {
                iconUtil : IconUtil,
                icon : IconUtil.iconAdd,
                iconFormData : IconUtil.iconEdit,
                title: InformationUtil.deliveryOrderItemDetail,
                titleFormData: InformationUtil.deliveryOrderItemDetail,
                titleMessage : InformationUtil.deliveryOrderItemDetail,
                titleForm :{
                    add : InformationUtil.addText,
                    edit : InformationUtil.editText
                },
                iconForm :{
                    add : IconUtil.iconAdd,
                    edit : IconUtil.iconEdit
                },
                btnText :{
                    refresh : InformationUtil.refreshText,
                    add : InformationUtil.addText,
                    edit : InformationUtil.editText,
                    delete : InformationUtil.removeText,
                    save : InformationUtil.saveText,
                    cancel : InformationUtil.cancelText,
                    search : InformationUtil.searchText
                },

                search : {
                    text : InformationUtil.searching,
                    placeholder : InformationUtil.searchHolder
                },

                action : {
                    view : false,
                    add : false,
                    edit : false,
                    delete : false
                },
                dataGrid : {
                    loading: false,
                    rows : [],
                    total: 0,
                    start: 0,
                    pageNumber: 1,
                    pageSize: 10,
                    selectionMode : 'single',
                    pageLayout: ['list','sep','first','prev','next','last','info'],

                    // filter
                    search : "",
                    sortField : "",
                    sortOrder : "",
                    allChecked : false,
                    rowClicked : false
                },
                disableBtnSaveLocal : false,
                disableBtnSaveData : false,
                itemDetailData : {
                    value : "",
                    text : "",
                    so_number : "",
                    product_name : "",
                    uom : "",
                    uom_desc : "",
                    qty : "",
                    qty_remain : 0,
                    qty_delivery : 0,
                    volume : "",
                    weight : "",
                    amount : "",
                    volume_per_qty : "",
                    weight_per_qty : "",
                    max_volume : "",
                    max_weight : "",
                    vehicle_type_id : "",
                    vehicle_type_desc : ""
                }   
            }
        },
        methods: {
            onPageShow(){
                this.action = AccessUtil.getAccessAction(this.$props.actionData);
                this.dataGrid.rows = []
                this.dataGrid.rows = this.$props.itemData;
                this.dataGrid.allChecked = false;
                this.disableBtnSaveLocal = false;
                this.$refs.dlg.open();
            },
            onPageClose(){
                this.$refs.dlg.close();
            },
            onSaveData(){
                this.disableBtnSaveLocal = true;
                let itemSelected = []
                this.dataGrid.rows.forEach((item) => {
                    if(item.checked) itemSelected.push(item)
                })
                this.$emit('onSaveData', itemSelected);
                this.$refs.dlg.close();                
                this.$refs.notification.success(this.titleMessage + " " + InformationUtil.add);
            },
            onEdit(){
                this.disableBtnSaveData = false;
                ModelUtil.resetModel(this.itemDetailData);
                // method for get data to edit
                if(this.action.add || this.action.edit){
                    if(this.$refs.dataGrid.selectedRows.length > 0){
                        this.titleFormData = this.titleForm.edit + " " + this.title;
                        this.iconFormData = this.iconForm.edit;
                        this.itemDetailData = ModelUtil.resetModel(this.itemDetailData);
                        this.itemDetailData = ModelUtil.mapModel(this.itemDetailData, this.$refs.dataGrid.selectedRows[0])
                        this.$refs.dlgDetailData.open();
                    }else{
                        this.$refs.notification.centerTop(InformationUtil.selectRow);
                    }
                } else {
                    this.disableBtnSaveData = true;
                }
            },
            onRowDblClick(){
                this.disableBtnSaveData = false;
                if(this.action.add || this.action.edit){
                    this.titleFormData = this.titleForm.edit + " " + this.title;
                    this.iconFormData = this.iconForm.edit;
                    this.itemDetailData = ModelUtil.resetModel(this.itemDetailData);
                    this.itemDetailData = ModelUtil.mapModel(this.itemDetailData, this.$refs.dataGrid.selectedRows[0])
                    this.$refs.dlgDetailData.open();
                } else {
                    this.disableBtnSaveData = true;
                }
            },
            onAllCheckedChange(checked) {
                if (this.dataGrid.rowClicked) {
                    return;
                }
                this.dataGrid.rows = this.dataGrid.rows.map(row => {
                    return Object.assign({}, row, {
                        checked: checked
                    });
                });
            },
            onCheckedChange(){
                let rowSelected = this.dataGrid.rows.filter(row => row.checked);
                if(rowSelected.length == this.dataGrid.rows.length){
                    this.$refs.allDataChecked.checked = true;
                    this.dataGrid.allChecked = true;
                }else{
                    this.$refs.allDataChecked.checked = false;
                    this.dataGrid.allChecked = false;
                }
                this.dataGrid.rowClicked = true;
                this.$nextTick(() => (this.dataGrid.rowClicked = false));
            },
            onSaveDetailData(){
                // validation qty actual must > 0 and <= qty reamin
                let data = this.itemDetailData
                if(data.qty_delivery < 1) {
                    this.$messager.alert({
                        title: "Error",
                        icon: "error",
                        msg: "Qty Delivery must > 0"
                    });
                    return;
                }

                if(data.qty_delivery > data.qty_remain) {
                    this.$messager.alert({
                        title: "Error",
                        icon: "error",
                        msg: "Qty Delivery must < Qty Remain : " + data.qty_remain
                    });
                    return;
                }
                
                // update data grid
                this.dataGrid.rows.map(row => {
                    if(row.product_name == data.product_name){
                        row.qty_delivery = data.qty_delivery
                    }
                    return row;
                });

                this.$refs.dlgDetailData.close();                
                this.$refs.notification.success(this.titleMessage + " " + InformationUtil.update);
            }
        },
        created(){
            GlobalUtil.removeWindow();
        }
    }
</script>   