<template>
    <div>
        <div class="form-body">
            <div class="img-holder">
                <div class="info-holder">

                </div>
            </div>
            <div class="form-holder">
                <div class="form-content">
                    <div class="form-items">
                        <div class="text-center">
                            <img src="../public/logo.png" style="width: 65%;">
                        </div>
						<br>
                        <div ref="loginMessage" class="">{{ this.error_msg }}</div>
                        <div class="page-links">
                            <a href="#" class="active">
                                Login
                                <div class="under-login"></div>
                            </a>
                        </div>
                        <div>
                            <input ref="email" class="form-control required" type="email" name="email" id="email" v-model="input.email" placeholder="E-mail Address" required v-on:keyup="onEnterEmail">
                            <input ref="password" class="form-control required" type="password" id="password" name="password" v-model="input.password" placeholder="Password" required v-on:keyup="onEnterPassword">
                            <div class="form-button">
                                <button @click="onAuth()" class="ibtn">Login</button> <a href="#">Forget password?</a>
                            </div>
                        </div>                        
                        <hr style="padding:2px;margin:0px">
                        <div class="other-links">
                            <p style="font-size: 10px;" class="text-center"> Copyrights &copy; 2022 | All Rights Reserved. </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import GlobalUtil from './utils/GlobalUtil'
// import AppService from './services/AppService';

export default {
    name: 'AuthLogin',
    data() {
        return {
            input: {
                email: "",
                password: ""
            },
            error_msg: ""
        }
    },
    mounted(){
        this.$refs.email.focus()
        // if(localStorage.getItem('token') != null) {
        //     AppService.setHeaderToken(localStorage.getItem('token'))
        //     this.$store.dispatch('getUser');
        //     this.$router.push({ name: 'application' })
        // }
    },
    computed: {
      ...mapGetters({ 
        isLoggedIn: 'isLoggedIn',
        user: 'user',
      })
    },
    methods: {
        onEnterEmail(e){
            if (e.keyCode === 13) {
                this.$refs.password.focus()
            }
        },
        onEnterPassword(e){
            if (e.keyCode === 13) {
                this.onAuth();
            }
        },
        async onAuth(){
            this.$store.dispatch('login', this.input)
                .then(response => {
                        if(response.status == "success"){
                            this.$store.dispatch('getUser');
                            this.$router.push({ name: 'application' });
                        }else{
                            this.error_msg = response.message;
                            this.$refs.loginMessage.classList.value = 'error-login';
                        }
                }).catch(error => {
                    this.error_msg = error;
                });
        }
    },
    created(){
        GlobalUtil.removeWindow();
    }
}
</script>

<style>
    @import url("https://fonts.googleapis.com/css?family=Lato:100,300,400,700,900");
    
    .form-body {
        height: 100vh;
        font-family: 'Lato', sans-serif;
        font-weight: 400;
        -webkit-font-smoothing: antialiased;
        text-rendering: optimizeLegibility;
        -moz-osx-font-smoothing: grayscale;
    }

    .img-holder {
        background-image: url('./assets/images/bg_login.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        display: inline-block;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        overflow: hidden;
        padding: 60px;
        text-align: center;
        z-index: 999;
        width: 70%;
    }

    .form-holder {
        background-color: #fff;
        margin-left: 70%;
        height: 100vh;
    }

    .form-content {
        position: relative;
        text-align: center;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-align-items: center;
        align-items: center;
        padding: 60px;
        padding: 5% 20% 25% 20%;
        min-height: 100%;
    }

    

    .form-items {
        display: inline-block;
        width: 100%;
        max-width: 340px;
        text-align: left;
        -webkit-transition: all 0.4s ease;
        transition: all 0.4s ease;
    }

    .text-center {
        text-align: center!important;
    }

    .page-links {
        margin-bottom: 25px;
    }

    .active {
        font-weight: 700;
    }

    .under-login{
        width: 10%;
        background-color: rgb(0, 146, 254) !important;
        border: 1px solid rgb(0, 146, 254);
        height: 2px;
        margin-top: 10px;
    }

    .form-content input, .form-content .dropdown-toggle.btn-default {
        width: 100%;
        padding: 9px 20px;
        text-align: left;
        border: 0;
        outline: 0;
        border-radius: 6px;
        background-color: #fff;
        font-size: 15px;
        font-weight: 300;
        color: #8D8D8D;
        -webkit-transition: all 0.3s ease;
        transition: all 0.3s ease;
        margin-bottom: 14px;
    }

    .form-content .form-button {
        margin-top: 30px;
        margin-bottom: 25px;
    }

    .form-content input, .form-content .dropdown-toggle.btn-default {
        border: 1px solid rgba(0, 149, 255, 0);
        background-color: #F7F7F7;
        color: #000;
    }

    .form-content .form-button .ibtn {
        border-radius: 6px;
        border: 0;
        padding: 6px 28px;
        background-color: #fff;
        color: #29A4FF;
        font-size: 14px;
        font-weight: 700;
        text-decoration: none;
        cursor: pointer;
        margin-right: 10px;
        outline: none;
        -webkit-transition: all 0.3s ease;
        transition: all 0.3s ease;
        -webkit-box-shadow: 0 0 0 rgb(0 0 0 / 16%);
        box-shadow: 0 0 0 rgb(0 0 0 / 16%);
    }

    .form-content .form-button .ibtn {
        background-color: #0093FF;
        color: #fff;
        -webkit-box-shadow: 0 0 0 rgb(80 182 255 / 31%);
        box-shadow: 0 0 0 rgb(80 182 255 / 31%);
    }

    hr {
        box-sizing: content-box;
        height: 0;
        overflow: visible;
        margin-top: 1rem;
        margin-bottom: 1rem;
        border: 0;
        border-top: 1px solid rgba(0,0,0,.1);
    }

    @media (max-width: 992px)
    {
        .img-holder {
            display: none;
        }
        .form-holder {
            margin: 0;
            height: 100%;
            width: 100%;
        }
    }    

    .error-login{
        color: black !important;
        font-size: 14px;
        border : 1px solid red;
        border-radius: 5px;
        padding: 4px;
        font-weight: bold;
        margin-bottom: 8px;
    }
</style>