import AuthService from "@/services/AuthService"

export default {
    state: { 
        menu: null,
        menuAccess: null,
        accessApp: null,
    },
    mutations: { 
        set_menu (state, data) {
            state.menu = data
        }, 
        reset_menu (state) {
            state.menu = null
        },

        set_menu_access (state, data) {
            state.menuAccess = data
        },
        reset_menu_access (state) {
            state.menuAccess = null
        },
        
        set_access_app (state, data) {
            state.accessApp = data
        },
        reset_access_app (state) {
            state.accessApp = null
        },
    },
    getters:{ 
        menu (state){
            return state.menu
        },
        menuAccess (state) {
            return state.menuAccess
        },
        accessApp (state) {
            return state.accessApp
        }
    },
    actions:{
        async getMenuAccess({ commit }){
            return new Promise((resolve, reject) => { 
                AuthService.getMenuAccess()
                  .then(response => {
                      if(response.status == 'success'){
                        const data = response.data;
                        commit('set_menu', data.menu)
                        commit('set_menu_access', data.access)
                      }
                      resolve(response)
                  })
                  .catch(err => { 
                    commit('reset_menu')
                    commit('reset_menu_access')
                    reject(err)
                  })
              })
        },
        getAccessApp({ commit }){
            return new Promise((resolve, reject) => { 
                AuthService.getAccessApp()
                  .then(response => {
                      if(response.status == 'success'){
                          const data = response.data
                          commit('set_access_app', data)
                      }
                      resolve(response)
                  })
                  .catch(err => { 
                      commit('reset_access_app')
                      reject(err)
                  })
              })
        }
    }
}