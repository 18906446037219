<template>
    <div>
        <Dialog ref="dlg" bodyCls="f-column" :iconCls="icon" :title="title" :draggable="true" :modal="true" closed :dialogStyle="{width:'768px', height:'390px'}">
            <div class="f-full" style="overflow:auto">
                <div class="datagrid-toolbar">
                    <LinkButton :plain="true" :iconCls="iconUtil.iconRefresh" @click="onRefresh()">{{ btnText.refresh }}</LinkButton>
                    <LinkButton :plain="true" :iconCls="iconUtil.iconAdd" @click="onAdd()">{{ btnText.add }}</LinkButton>
                </div>
                <DataGrid
                        ref="dataGrid"
                        :columnResizing="true"
                        :border="false"
                        :loading="dataGrid.loading"                                    
                        :data="dataGrid.rows"
                        :selectionMode="dataGrid.selectionMode"
                        :total="dataGrid.total"
                        :pagination="true"
                        :pageSize="dataGrid.pageSize"
                        :pageNumber="dataGrid.pageNumber"   
                        :pageLayout="dataGrid.pageLayout" 
                        style="width:1700px;height:390px" 
                        >
                        <GridColumn :expander="true" width="30"></GridColumn>
                        <GridColumn field="ck" :width="50" align="center">
                            <template slot="header">
                                <CheckBox ref="allDataChecked" v-model="dataGrid.allChecked" @checkedChange="onAllCheckedChange($event)"></CheckBox>
                            </template>
                            <template slot="body" slot-scope="scope">
                                <CheckBox v-model="scope.row.checked" @checkedChange="onCheckedChange($event)"></CheckBox>
                            </template>
                        </GridColumn>
                        <GridColumn field="btn-add" :width="40" align="center">
                            <template slot="body" slot-scope="scope">
                                <LinkButton :iconCls="iconUtil.iconAdd" @click="onAddItemOrderDetail(scope.row)" style="width:30px"></LinkButton>
                            </template>
                        </GridColumn>
                        <GridColumn field="so_number" title="So Number" :sortable="false" :width="100"></GridColumn>
                        <GridColumn field="req_delivery_date_desc" title="Req. Delivery Date" :sortable="false" :width="100"></GridColumn>
                        <GridColumn field="created_date" title="Created Date" :sortable="false" :width="100"></GridColumn>
                        <GridColumn field="origin_desc" title="Origin Id" :sortable="false" :width="100"></GridColumn>
                        <GridColumn field="origin_name" title="Origin Name" :sortable="false" :width="100"></GridColumn>
                        <GridColumn field="origin_address" title="Origin Address" :sortable="false" :width="100"></GridColumn>
                        <GridColumn field="dest_desc" title="Destination Id" :sortable="false" :width="100"></GridColumn>
                        <GridColumn field="dest_name" title="Destination Name" :sortable="false" :width="100"></GridColumn>
                        <GridColumn field="dest_address" title="Destination Address" :sortable="false" :width="100"></GridColumn>
                        <GridColumn field="client_desc" title="Client ID" :sortable="false" :width="100"></GridColumn>
                        <GridColumn field="order_type_desc" title="Order Type" :sortable="false" :width="100"></GridColumn>
                        <GridColumn field="total_qty" title="Total Qty" :sortable="false" :width="100"></GridColumn>
                        <GridColumn field="total_qty_remain" title="Total Qty Remain" :sortable="false" :width="100"></GridColumn>
                        <GridColumn field="total_weight" title="Total Tonase" :sortable="false" :width="100"></GridColumn>
                        <GridColumn field="total_volume" title="Total M3" :sortable="false" :width="100"></GridColumn>
                        <GridColumn field="status_desc" title="Status" :sortable="false" :width="100"></GridColumn>
                        <template slot="detail" slot-scope="scope">
                            <div style="padding:2px 2px 2px 30px">
                                <DataGrid :data="scope.row.item_add" 
                                    style="width:450px;">
                                    <GridColumn :width="30"></GridColumn>
                                    <GridColumn :width="40"></GridColumn>
                                    <GridColumn field="product_name" title="Product Name"></GridColumn>
                                    <GridColumn field="qty_remain" title="Qty Remain"></GridColumn>
                                    <GridColumn field="qty_delivery" title="Qty Delivery"></GridColumn>
                                </DataGrid>
                            </div>
                        </template>
                </DataGrid>
            </div>
            <div class="dialog-button">
                <LinkButton :iconCls="iconUtil.iconSave" v-if="action.add || action.edit" @click="onSaveData()" :disabled="disableBtnSaveLocal" style="width:89px">{{ btnText.save }}</LinkButton>
                <LinkButton :iconCls="iconUtil.iconCancel" @click="$refs.dlg.close()" style="width:89px">{{ btnText.cancel }}</LinkButton>
            </div>
        </Dialog>

        <NotificationComponent ref="notification"></NotificationComponent>
        <GridItemOrderDetailComponent ref="gridItemOrderDetail" actionData="delivery_order" :itemData="itemDetailData" :disableBtnSave="disableBtnSaveLocal" @onSaveData="onSaveDataItemDetail($event)"></GridItemOrderDetailComponent>
    </div>
</template>
<script>    
    import OutboundService from '@/services/OutboundService';
    import InformationUtil from '@/utils/InformationUtil';
    import AccessUtil from '@/utils/AccessUtil';
    import IconUtil from '@/utils/IconUtil';
    import GlobalUtil from '@/utils/GlobalUtil';

    import NotificationComponent from '@/components/NotificationComponent.vue';
    import GridItemOrderDetailComponent from './GridItemOrderDetailComponent.vue'
    export default {
        name : "GridItemOrderComponent",
        components : {
            NotificationComponent,
            GridItemOrderDetailComponent
        },
        props : {
            actionData : {
                type : String,
                default : ''
            },
            disableBtnSave : {
                type : Boolean,
                default : false
            },
            clientId : {
                type : Number
            },
            itemData : {
                type : Array
            }
        },
        data(){
            return {
                iconUtil : IconUtil,
                icon : IconUtil.iconAdd,
                title: InformationUtil.deliveryOrderItem,
                btnText :{
                    refresh : InformationUtil.refreshText,
                    add : InformationUtil.addText,
                    edit : InformationUtil.editText,
                    delete : InformationUtil.removeText,
                    save : InformationUtil.saveText,
                    cancel : InformationUtil.cancelText,
                    search : InformationUtil.searchText
                },

                search : {
                    text : InformationUtil.searching,
                    placeholder : InformationUtil.searchHolder
                },
                disableBtnSaveLocal : false,

                action : {
                    view : false,
                    add : false,
                    edit : false,
                    delete : false
                },
                dataGrid : {
                    loading: false,
                    rows : [],
                    total: 0,
                    start: 0,
                    pageNumber: 1,
                    pageSize: 10,
                    selectionMode : 'single',
                    pageLayout: ['list','sep','first','prev','next','last','info'],

                    // filter
                    search : "",
                    sortField : "",
                    sortOrder : "",
                    allChecked : false
                },
                itemAdd : {},
                itemDetailData : []
            }
        },
        methods : {
            onPageShow(){
                this.action = AccessUtil.getAccessAction(this.$props.actionData);
                this.loadData()
                this.dataGrid.allChecked = false;
                this.disableBtnSaveLocal = false;
                this.$refs.dlg.open();
            },
            onPageClose(){
                this.$refs.dlg.close();
            },
            onSaveData(){
                let itemSelected = []
                this.dataGrid.rows.forEach((item) => {
                    if(item.checked && item.item_add.length > 0) itemSelected.push(item)
                })
                this.$emit('onSaveData', itemSelected);
                this.$refs.dlg.close();                
                this.$refs.notification.success(this.titleMessage + " " + InformationUtil.add);
            },
            async onRefresh(){
                this.dataGrid.pageNumber = 1;       
                this.$refs.dataGrid.unselectRow(this.$refs.dataGrid.selectedRows[0]);
                await this.loadData();
                this.$refs.notification.success(InformationUtil.refresh);
            },
            async loadData(){
                this.dataGrid.loading = true;
                let param = {
                    clientId : this.$props.clientId
                }

                return OutboundService.getData('/salesOrder/get-item', this.dataGrid, param)
                    .then((response) => {
                        if(response.data !== "" && response.data !== null){
                            let data = response.data; 
                            let itemExist = this.$props.itemData;
                            if(itemExist.length > 0) {
                                data.rows.filter((item, idx) => {
                                    itemExist.forEach((exist) => {
                                        if(item.id == exist.id_so_number) {
                                            item.item_detail.forEach((itmDtl, idx3) => {
                                                if(itmDtl.product_name == exist.product_name) {
                                                    item.item_detail.splice(idx3, 1);
                                                }
                                            });
                                        }
                                    })

                                    if(item.item_detail.length == 0){
                                        data.rows.splice(idx, 1)
                                    }
                                })
                            }
                            this.dataGrid.total = data.total;
                            this.dataGrid.rows = data.rows;
                        }
                        this.dataGrid.loading = false;
                    });
            },
            onAdd(){
                // method for get data to edit
                if(this.action.add || this.action.edit){
                    if(this.$refs.dataGrid.selectedRows.length > 0){
                        let row = this.$refs.dataGrid.selectedRows[0];
                        this.onAddItemOrderDetail(row)
                    }else{
                        this.$refs.notification.centerTop(InformationUtil.selectRow);
                    }
                }
            },
            onAddItemOrderDetail(row){
                if(this.action.add || this.action.edit){
                    this.itemAdd = row;
                    row.item_detail.forEach((val) => {
                        val.checked = false;
                        row.item_add.forEach((dtl) => {
                            if(dtl.product_name == val.product_name) {
                                val.checked = true;
                            }
                        })
                        this.itemDetailData.push(val)
                    });
                    this.$refs.gridItemOrderDetail.onPageShow();
                    this.itemDetailData = []
                }
                
            },
            onSaveDataItemDetail(row) {
                this.dataGrid.rows.forEach((item) => {
                    if(item.so_number == this.itemAdd.so_number) {
                        item.item_add = row;
                    }
                })

                this.$refs.dataGrid.expandRow(0)
            },
            onAllCheckedChange(checked) {
                if (this.dataGrid.rowClicked) {
                    return;
                }
                this.dataGrid.rows = this.dataGrid.rows.map(row => {
                    return Object.assign({}, row, {
                        checked: checked
                    });
                });
                // set data grid detail auto check product
                this.dataGrid.rows.forEach((item) => {
                    if(checked) {
                        item.item_add = item.item_detail;
                    } else {
                        item.item_add = []
                    }
                })
            },
            onCheckedChange(){
                let rowSelected = this.dataGrid.rows.filter(row => row.checked);
                if(rowSelected.length == this.dataGrid.rows.length){
                    this.$refs.allDataChecked.checked = true;
                    this.dataGrid.allChecked = true;
                }else{
                    this.$refs.allDataChecked.checked = false;
                    this.dataGrid.allChecked = false;
                }
                this.dataGrid.rowClicked = true;
                this.$nextTick(() => (this.dataGrid.rowClicked = false));
            },
        },
        created(){
            GlobalUtil.removeWindow();
        }
    }
</script>