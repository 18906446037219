import Vue from 'vue'
import App from './App.vue'
import 'vx-easyui/dist/themes/metro/easyui.css'
import 'vx-easyui/dist/themes/icon.css'
import 'vx-easyui/dist/themes/vue.css'
import EasyUI from 'vx-easyui'
import './assets/css/style.css'
import router from './router'
import store from './store'

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'


import './assets/fonts/feather/iconfont.css' // For form-wizard
import FeatherIcon from './components/icons/feather-icon/FeatherIcon.vue'
Vue.component(FeatherIcon.name, FeatherIcon)

require('./assets/scss/core.scss')

import Notifications from 'vue-notification'
import AppService from './services/AppService'

import VueMoment from 'vue-moment'

import GmapVue from 'gmap-vue'
 
Vue.use(VueMoment)

Vue.use(GmapVue, {
  load: {
    // [REQUIRED] This is the unique required value by Google Maps API
    key: 'AIzaSyDFkqOKuLYAMPurFv79HSviyr9K2WSgGak',
    // [OPTIONAL] This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)
    libraries: 'places,drawing,geometry',

    // [OPTIONAL] If you want to set the version, you can do so:
    v: '3.26',

    // This option was added on v3.0.0 but will be removed in the next major release.
    // If you already have an script tag that loads Google Maps API and you want to use it set you callback
    // here and our callback `GoogleMapsCallback` will execute your custom callback at the end; it must attached
    // to the `window` object, is the only requirement.
    customCallback: 'MyCustomCallback',
  },

  // [OPTIONAL] If you intend to programmatically custom event listener code
  // (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
  // instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
  // you might need to turn this on.
  autoBindAllEvents: true,

  // [OPTIONAL] If you want to manually install components, e.g.
  // import {GmapMarker} from 'gmap-vue/src/components/marker'
  // Vue.component('GmapMarker', GmapMarker)
  // then set installComponents to 'false'.
  // If you want to automatically install all the components this property must be set to 'true':
  installComponents: true,

  // Load the Google Maps API dynamically, if you set this to `true` the plugin doesn't load the Google Maps API
  dynamicLoad: false,
})

Vue.use(EasyUI)
Vue.use(Notifications)
Vue.config.productionTip = process.env.VUE_IS_PROD

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

// set interceptor for handling error
AppService.setInterceptorApi(store, router);


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
