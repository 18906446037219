<template>
    <div>
        <Panel :title="titleView"  style="margin:0px 1px; ">
            <div class="datagrid-toolbar">
                <LinkButton :plain="true" :iconCls="iconUtil.iconRefresh" @click="onRefresh()">{{ btnText.refresh }}</LinkButton>
                <LinkButton v-if="action.add" :plain="true" :iconCls="iconUtil.iconAdd" @click="onAdd()">{{ btnText.add }}</LinkButton>
                <LinkButton v-if="action.edit" :plain="true" :iconCls="iconUtil.iconEdit" @click="onEdit()">{{ btnText.edit }}</LinkButton>
                <LinkButton v-if="action.delete" :plain="true" :iconCls="iconUtil.iconDelete" @click="onDelete()">{{ btnText.delete }}</LinkButton>
                <LinkButton v-if="action.add" :plain="true" :iconCls="iconUtil.iconCopy" @click="onDuplicate()">{{ btnText.duplicate }}</LinkButton>
                <LinkButton v-if="action.add" :plain="true" :iconCls="iconUtil.iconUpload" @click="onImport()">{{ btnText.import }}</LinkButton>
                <span class="button-sep"></span> Status : 
                <ComboBox  :data="statusData" inputId="searchStatusData" name="searchStatusData" class="f-field-text" style="width:75px; margin-left: 5px; margin-right: 5px;" v-model="searchStatusData" @selectionChange="onSelectionStatusData($event)"></ComboBox>
                <span class="button-sep"></span> {{ search.text }} : 
                <SearchBox style="width:300px"
                    :placeholder="search.placeholder" 
                    v-model="dataGrid.search"
                    @search="onSearch()">
                    <Addon>
                        <span v-if="dataGrid.search" class="textbox-icon icon-clear" title="Clear value" @click="dataGrid.search=null;onSearch();"></span>
                    </Addon>
                </SearchBox>
                <LinkButton :plain="true" :iconCls="iconUtil.iconMultiSearch" @click="onMultiSearch()">{{ informationUtil.multiSearchText }}</LinkButton>
            </div>
            <DataGrid
                    ref="dataGrid"
                    :columnResizing="true"
                    :border="false"
                    :lazy="true"
                    :loading="dataGrid.loading"
                    :data="dataGrid.rows"
                    :pagination="true"
                    :selectionMode="dataGrid.selectionMode"
                    :total="dataGrid.total"
                    :pageSize="dataGrid.pageSize"
                    :pageNumber="dataGrid.pageNumber"
                    :pageLayout="dataGrid.pageLayout" 
                    @pageChange="onPageChange($event)"
                    @sortChange="onSortChange($event)"
                    @rowDblClick="onRowDblClick()"
                    >
                    <GridColumn align="center" cellCss="datagrid-td-rownumber" width="30">
                    <template slot="body" slot-scope="scope">
                        {{scope.rowIndex + 1}}
                    </template>
                </GridColumn>
                <GridColumn field="reference_id" title="Reference ID" :sortable="true"></GridColumn>
                <GridColumn field="do_number" title="Do Number" :sortable="true"></GridColumn>
                <GridColumn field="so_number" title="So Number" :sortable="true"></GridColumn>
                <GridColumn field="delivery_date_desc" title="Delivery Date" :sortable="true"></GridColumn>
                <GridColumn field="req_arrival_date_desc" title="Req. Arrival Date" :sortable="true"></GridColumn>
                <GridColumn field="created_date" title="Created Date" :sortable="true"></GridColumn>
                <GridColumn field="origin_desc" title="Origin Id" :sortable="true"></GridColumn>
                <GridColumn field="origin_name" title="Origin Name" :sortable="true"></GridColumn>
                <GridColumn field="origin_address" title="Origin Address" :sortable="true"></GridColumn>
                <GridColumn field="dest_desc" title="Destination Id" :sortable="true"></GridColumn>
                <GridColumn field="dest_name" title="Destination Name" :sortable="true"></GridColumn>
                <GridColumn field="dest_address" title="Destination Address" :sortable="true"></GridColumn>
                <GridColumn field="client_desc" title="Client ID" :sortable="true"></GridColumn>
                <GridColumn field="order_type_desc" title="Order Type" :sortable="true"></GridColumn>
                <GridColumn field="order_status_desc" title="Status" :sortable="true"></GridColumn>
            </DataGrid>
        </Panel>

        <!-- Multi Search -->
        <Dialog ref="dlgSearch" :iconCls="iconUtil.iconMultiSearch" bodyCls="f-column" :title="informationUtil.multiSearchText" :draggable="true" :modal="true" closed :dialogStyle="{width:'500px', height:'auto'}">
            <div class="f-full" style="overflow:auto">
                <table width="100%" cellspacing="1" cellpadding="5" border="0">
                    <tbody>
                        <tr>
                            <td style="width : 20%" class="bg_subtitle">DO Number</td>
                            <td class="bg_grid2" colspan="3">
                                <TextBox inputId="do_number" name="do_number" class="f-field-text" v-model="modelMultiSearch.do_number">
                                    <Addon>
                                        <span v-if="modelMultiSearch.do_number" class="textbox-icon icon-clear" title="Clear value" @click="modelMultiSearch.do_number=null;"></span>
                                    </Addon>
                                </TextBox>
                            </td>
                        </tr>
                        <tr>
                            <td class="bg_subtitle">SO Number</td>
                            <td class="bg_grid2" colspan="3">
                                <TextBox inputId="so_number" name="so_number" class="f-field-text" v-model="modelMultiSearch.so_number">
                                    <Addon>
                                        <span v-if="modelMultiSearch.so_number" class="textbox-icon icon-clear" title="Clear value" @click="modelMultiSearch.so_number=null;"></span>
                                    </Addon>
                                </TextBox>
                            </td>
                        </tr>
                        <tr>
                            <td class="bg_subtitle">Delivery Date</td>
                            <td class="bg_grid2">
                                <DateBox inputId="delivery_date_from" name="delivery_date_from" class="f-field-text" v-model="modelMultiSearch.delivery_date_from" format="dd/MM/yyyy">
                                    <Addon>
                                        <span v-if="modelMultiSearch.delivery_date_from" class="textbox-icon icon-clear" title="Clear value" @click="modelMultiSearch.delivery_date_from=null;"></span>
                                    </Addon>
                                </DateBox>
                            </td>
                            <td>to</td>
                            <td class="bg_grid2">
                                <DateBox inputId="delivery_date_to" name="delivery_date_to" class="f-field-text" v-model="modelMultiSearch.delivery_date_to" format="dd/MM/yyyy">
                                    <Addon>
                                        <span v-if="modelMultiSearch.delivery_date_to" class="textbox-icon icon-clear" title="Clear value" @click="modelMultiSearch.delivery_date_to=null;"></span>
                                    </Addon>
                                </DateBox>
                            </td>
                        </tr>

                        <tr>
                            <td class="bg_subtitle">Req Arrival Date</td>
                            <td class="bg_grid2">
                                <DateBox inputId="req_arrival_date_from" name="req_arrival_date_from" class="f-field-text" v-model="modelMultiSearch.req_arrival_date_from" format="dd/MM/yyyy">
                                    <Addon>
                                        <span v-if="modelMultiSearch.req_arrival_date_from" class="textbox-icon icon-clear" title="Clear value" @click="modelMultiSearch.req_arrival_date_from=null;"></span>
                                    </Addon>
                                </DateBox>
                            </td>
                            <td>to</td>
                            <td class="bg_grid2">
                                <DateBox inputId="req_arrival_date_to" name="req_arrival_date_to" class="f-field-text" v-model="modelMultiSearch.req_arrival_date_to" format="dd/MM/yyyy">
                                    <Addon>
                                        <span v-if="modelMultiSearch.req_arrival_date_to" class="textbox-icon icon-clear" title="Clear value" @click="modelMultiSearch.req_arrival_date_to=null;"></span>
                                    </Addon>
                                </DateBox>
                            </td>
                        </tr>

                        <tr>
                            <td class="bg_subtitle">Created Date</td>
                            <td class="bg_grid2">
                                <DateBox inputId="created_date_from" name="created_date_from" class="f-field-text" v-model="modelMultiSearch.created_date_from" format="dd/MM/yyyy">
                                    <Addon>
                                        <span v-if="modelMultiSearch.created_date_from" class="textbox-icon icon-clear" title="Clear value" @click="modelMultiSearch.created_date_from=null;"></span>
                                    </Addon>
                                </DateBox>
                            </td>
                            <td>to</td>
                            <td class="bg_grid2">
                                <DateBox inputId="created_date_to" name="created_date_to" class="f-field-text" v-model="modelMultiSearch.created_date_to" format="dd/MM/yyyy">
                                    <Addon>
                                        <span v-if="modelMultiSearch.created_date_to" class="textbox-icon icon-clear" title="Clear value" @click="modelMultiSearch.created_date_to=null;"></span>
                                    </Addon>
                                </DateBox>
                            </td>
                        </tr>

                        <tr>
                            <td class="bg_subtitle">Origin ID</td>
                            <td class="bg_grid2" colspan="3">
                                <ComboBox  :data="customer_origins" @filterChange="optionCustomerOrigin($event)" inputId="origin_id" name="origin_id" class="f-field-text" v-model="modelMultiSearch.origin_id">
                                    <template slot="item" slot-scope="scope">
                                        <span style="font-weight:bold">{{scope.row.text}}</span><br/>
                                        <span style="color:#888">{{scope.row.name}}</span><br/>
                                        <span style="color:#888">{{scope.row.address}}</span><br/>
                                    </template>
                                    <Addon>
                                        <span v-if="modelMultiSearch.origin_id" class="textbox-icon icon-clear" title="Clear value" @click="modelMultiSearch.origin_id=null;"></span>
                                    </Addon>
                                </ComboBox>
                            </td>
                        </tr>

                        <tr>
                            <td class="bg_subtitle">Destination ID</td>
                            <td class="bg_grid2" colspan="3">
                                <ComboBox  :data="customer_destinations" @filterChange="optionCustomerDestination($event)" inputId="dest_id" name="dest_id" class="f-field-text" v-model="modelMultiSearch.dest_id"> 
                                    <template slot="item" slot-scope="scope">
                                        <span style="font-weight:bold">{{scope.row.text}}</span><br/>
                                        <span style="color:#888">{{scope.row.name}}</span><br/>
                                        <span style="color:#888">{{scope.row.address}}</span><br/>
                                    </template>
                                    <Addon>
                                        <span v-if="modelMultiSearch.dest_id" class="textbox-icon icon-clear" title="Clear value" @click="modelMultiSearch.dest_id=null;"></span>
                                    </Addon>
                                </ComboBox>
                            </td>
                        </tr>

                        <tr>
                            <td class="bg_subtitle">Order Type </td>
                            <td class="bg_grid2" colspan="3">
                                <ComboBox  :data="orderTypes" inputId="order_type" name="order_type" class="f-field-text" v-model="modelMultiSearch.order_type">
                                    <Addon>
                                        <span v-if="modelMultiSearch.order_type" class="textbox-icon icon-clear" title="Clear value" @click="modelMultiSearch.order_type=null;"></span>
                                    </Addon>
                                </ComboBox>
                            </td>
                        </tr>

                        <tr>
                            <td class="bg_subtitle">
                                Client ID
                            </td>
                            <td class="bg_grid2" colspan="3"> 
                                <ComboBox  :data="clients" @filterChange="optionClient($event)" inputId="client_id" name="client_id" class="f-field-text" v-model="modelMultiSearch.client_id">
                                    <template slot="item" slot-scope="scope">
                                        <span style="font-weight:bold">{{scope.row.text}}</span><br/>
                                        <span style="color:#888">{{scope.row.address}}</span><br/>
                                    </template>
                                    <Addon>
                                        <span v-if="modelMultiSearch.client_id" class="textbox-icon icon-clear" title="Clear value" @click="modelMultiSearch.client_id=null;"></span>
                                    </Addon>
                                </ComboBox>
                            </td>
                        </tr>
                        <tr>
                            <td class="bg_subtitle">Status Data</td>
                            <td class="bg_grid2" colspan="3">
                                <ComboBox  :data="statusDataSearch" inputId="order_status" name="order_status" class="f-field-text" v-model="modelMultiSearch.order_status">
                                    <Addon>
                                        <span v-if="modelMultiSearch.order_status" class="textbox-icon icon-clear" title="Clear value" @click="modelMultiSearch.order_status=null;"></span>
                                    </Addon>
                                </ComboBox>
                            </td>
                        </tr>                        
                    </tbody>
                </table>
            </div>
            <div class="dialog-button">
                <LinkButton :iconCls="iconUtil.iconSearch" @click="onSearchMulti()" style="width:89px">{{ informationUtil.searchText }}</LinkButton>
                <LinkButton :iconCls="iconUtil.iconCancel" @click="onResetSearchMulti()" style="width:89px">{{ informationUtil.resetText }}</LinkButton>
            </div>
        </Dialog>

        <Dialog ref="dlgImport" :iconCls="iconUtil.iconUpload" bodyCls="f-column" :title="informationUtil.importText" :draggable="true" :modal="true" closed :dialogStyle="{width:'480px', height:'auto'}">
            <div class="f-full" style="overflow:auto">
                <Form ref="formFile">
                    <table width="100%" cellspacing="1" cellpadding="5" border="0">
                        <tbody>
                            <tr>
                                <td> 
                                    <FileButton style="width:100px" @select="onSelectedFile($event)">Choose File</FileButton> &nbsp;&nbsp;{{fileName}}
                                </td>
                                <td> 
                                    <LinkButton :plain="true" :iconCls="iconUtil.iconDownload" @click="onDownloadFile()">{{ btnText.downloadTemplate }}</LinkButton>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <b>(Total Max Upload file size 5MB, allowed file type .xls)</b>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </Form>
            </div>
            <div class="dialog-button">
                <LinkButton :iconCls="iconUtil.iconSave" @click="onSaveImport()" style="width:89px">{{ informationUtil.saveText }}</LinkButton>
                <LinkButton :iconCls="iconUtil.iconCancel" @click="onCancelImport()" style="width:89px">{{ informationUtil.cancelText }}</LinkButton>
            </div>
        </Dialog>


        <!-- Form -->
        <Dialog ref="dlg" :iconCls="icon" bodyCls="f-column" :title="title" :draggable="true" :modal="true" closed :dialogStyle="{width:'750px', height:'600px'}">
            <div class="f-full" style="overflow:auto">
                <Form ref="form" :model="model" :rules="rules" @validate="errors=$event">
                    <fieldset class="c-fieldset">
                        <legend class="c-legend"><b>{{ titleMessage }}</b></legend>
                        <table width="100%" cellspacing="1" cellpadding="5" border="0">
                            <tbody>
                                <tr>
                                    <td>Reference ID</td>
                                    <td style="width:35%;">
                                        <TextBox inputId="reference_id" name="reference_id" class="f-field-text" v-model="modelNotMapping.reference_id" :disabled="true" :spinners="false"></TextBox>
                                    </td>
                                    <td>
                                        Client ID <font color="red">*</font>
                                    </td>
                                    <td> 
                                        <ComboBox  :data="clients" inputId="client_id" name="client_id" @filterChange="optionClient($event)" class="f-field-text" v-model="model.client_id" :disabled="disabledClosed">
                                            <template slot="item" slot-scope="scope">
                                                <span style="font-weight:bold">{{scope.row.text}}</span><br/>
                                                <span style="color:#888">{{scope.row.address}}</span><br/>
                                            </template>
                                        </ComboBox>
                                        <div class="error">{{textError.client_id}}</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>DO Number <font color="red">*</font></td>
                                    <td>
                                        <TextBox inputId="do_number" name="do_number" class="f-field-text" v-model="model.do_number" :disabled="disabledClosed"></TextBox>
                                        <div class="error">{{textError.do_number}}</div>
                                    </td>
                                    <td>SO Number <font color="red">*</font></td>
                                    <td>
                                        <TextBox inputId="so_number" name="so_number" class="f-field-text" v-model="model.so_number" :disabled="disabledClosed"></TextBox>
                                        <div class="error">{{textError.so_number}}</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Delivery Date <font color="red">*</font></td>
                                    <td>
                                        <DateBox inputId="delivery_date" name="delivery_date" class="f-field-text" v-model="model.delivery_date" format="dd/MM/yyyy" :disabled="disabledClosed"></DateBox>
                                        <div class="error">{{textError.delivery_date}}</div>
                                    </td>
                                    <td>Order Type <font color="red">*</font></td>
                                    <td>
                                        <ComboBox  :data="orderTypes" inputId="order_type" name="order_type" class="f-field-text" v-model="model.order_type" :disabled="disabledClosed"></ComboBox>
                                        <div class="error">{{textError.order_type}}</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Req. Arrival Date <font color="red">*</font></td>
                                    <td>
                                        <DateBox inputId="req_arrival_date" name="req_arrival_date" class="f-field-text" v-model="model.req_arrival_date" format="dd/MM/yyyy" :disabled="disabledClosed"></DateBox>
                                        <div class="error">{{textError.req_arrival_date}}</div>
                                    </td>
                                    <td>Status</td>
                                    <td>
                                        <TextBox inputId="order_status_desc" name="order_status_desc" class="f-field-text" v-model="modelNotMapping.order_status_desc" :disabled="true"></TextBox>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Origin <font color="red">*</font></td>
                                    <td>
                                        <ComboBox  :data="customer_origins" inputId="origin_id" name="origin_id" @filterChange="optionCustomerOrigin($event)" @selectionChange="onSelectionCustomerOrigin($event)" class="f-field-text" v-model="model.origin_id" :disabled="disabledClosed">
                                            <template slot="item" slot-scope="scope">
                                                <span style="font-weight:bold">{{scope.row.text}}</span><br/>
                                                <span style="color:#888">{{scope.row.name}}</span><br/>
                                                <span style="color:#888">{{scope.row.address}}</span><br/>
                                            </template>
                                        </ComboBox>
                                        <div class="error">{{textError.origin_id}}</div>
                                    </td>
                                    <td>Destination <font color="red">*</font></td>
                                    <td>
                                        <ComboBox  :data="customer_destinations" inputId="dest_id" name="dest_id" @filterChange="optionCustomerDestination($event)" @selectionChange="onSelectionCustomerDestination($event)" class="f-field-text" v-model="model.dest_id" :disabled="disabledClosed">
                                            <template slot="item" slot-scope="scope">
                                                <span style="font-weight:bold">{{scope.row.text}}</span><br/>
                                                <span style="color:#888">{{scope.row.name}}</span><br/>
                                                <span style="color:#888">{{scope.row.address}}</span><br/>
                                            </template>
                                        </ComboBox>
                                        <div class="error">{{textError.dest_id}}</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Origin Name</td>
                                    <td>
                                        <TextBox inputId="origin_customer_name" name="origin_customer_name" class="f-field-text" v-model="modelNotMapping.origin_customer_name" :disabled="true"></TextBox>
                                    </td>
                                    <td>Destination Name</td>
                                    <td>
                                        <TextBox inputId="dest_customer_name" name="dest_customer_name" class="f-field-text" v-model="modelNotMapping.dest_customer_name" :disabled="true"></TextBox>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Origin Address</td>
                                    <td>
                                        <TextBox inputId="origin_customer_address" name="origin_customer_address" class="f-field-text" v-model="modelNotMapping.origin_customer_address" :disabled="true"></TextBox>
                                    </td>
                                    <td>Destination Address</td>
                                    <td>
                                        <TextBox inputId="dest_customer_address" name="dest_customer_address" class="f-field-text" v-model="modelNotMapping.dest_customer_address" :disabled="true"></TextBox>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </fieldset>
                    <fieldset class="c-fieldset">
                        <legend class="c-legend"><b>Details Information</b></legend>
                        <Tabs style="height:195px">
                            <TabPanel :title="'Order Summary'">
                                <table width="100%" cellspacing="1" cellpadding="5" border="0">
                                    <tbody>
                                        <tr>
                                            <td>Order Load <font color="red">*</font></td>
                                            <td>
                                                <NumberBox inputId="order_qty" name="order_qty" class="f-field-text" v-model="model.order_qty" :spinners="false" :disabled="disabledClosed"></NumberBox>
                                                <div class="error">{{textError.order_qty}}</div>
                                            </td>
                                            <td>UoM <font color="red">*</font></td>
                                            <td>
                                                <ComboBox  :data="uomList" inputId="uom" name="uom" @filterChange="optionUom($event)" class="f-field-text" v-model="model.uom" :disabled="disabledClosed"></ComboBox>
                                                <div class="error">{{textError.uom}}</div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Order Qty</td>
                                            <td>
                                                <NumberBox inputId="order_qty_v2" name="order_qty_v2" class="f-field-text" v-model="model.order_qty_v2" :spinners="false" :disabled="disabledClosed"></NumberBox>
                                                <div class="error">{{textError.order_qty_v2}}</div>
                                            </td>
                                            <td>UoM <font color="red">*</font></td>
                                            <td>
                                                <ComboBox  :data="uomListV2" inputId="uom_v2" name="uom_v2" @filterChange="optionUomV2($event)" class="f-field-text" v-model="model.uom_v2" :disabled="disabledClosed"></ComboBox>
                                                <div class="error">{{textError.uom_v2}}</div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </TabPanel>
                            <TabPanel :title="'Product Overview'">
                                <div class="datagrid-toolbar" style="margin-bottom: 10px;">
                                    {{ search.text }} : 
                                    <SearchBox style="width:300px"
                                        :placeholder="search.placeholder" 
                                        v-model="dataGridDetail.search"
                                        @search="onSearchDetail()">
                                        <Addon>
                                            <span v-if="dataGridDetail.search" class="textbox-icon icon-clear" title="Clear value" @click="dataGridDetail.search=null;onSearchDetail();"></span>
                                        </Addon>
                                    </SearchBox>
                                </div>
                                <DataGrid
                                    ref="dataGridDetail"
                                    :columnResizing="true"
                                    :border="false"
                                    :loading="dataGridDetail.loading"                                    
                                    :data="dataGridDetail.rows"
                                    :pagination="true"
                                    :selectionMode="dataGridDetail.selectionMode"
                                    :total="dataGridDetail.total"
                                    :pageSize="dataGridDetail.pageSize"
                                    :pageNumber="dataGridDetail.pageNumber"
                                    :pageLayout="dataGridDetail.pageLayout"
                                    >
                                    <GridColumn align="center" cellCss="datagrid-td-rownumber" width="30">
                                        <template slot="body" slot-scope="scope">
                                            {{scope.rowIndex + 1}}
                                        </template>
                                    </GridColumn>
                                    <GridColumn field="product_name" title="Product Name" :sortable="true"></GridColumn>
                                    <GridColumn field="qty" title="Qty" :sortable="true"></GridColumn>
                                    <GridColumn field="uom_desc" title="UOM" :sortable="true"></GridColumn>
                                    <GridColumn field="weight" title="Weight" :sortable="true"></GridColumn>
                                    <GridColumn field="volume" title="Volume" :sortable="true"></GridColumn>
                                </DataGrid>
                            </TabPanel>
                            <TabPanel :title="'Notes'">
                                <table width="100%" cellspacing="1" cellpadding="5" border="0">
                                    <tbody>
                                        <tr>
                                            <td>
                                                Remark
                                                <TextBox inputId="remark" name="remark" :multiline="true" class="f-field-text f-field-text-area" v-model="model.remark"></TextBox>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </TabPanel>
                            <TabPanel :title="'Log'">
                                <table width="100%" cellspacing="1" cellpadding="5" border="0">
                                    <tbody>
                                        <tr>
                                            <td>
                                                Created By
                                            </td>
                                            <td>
                                                <TextBox inputId="created_by" name="created_by" class="f-field-text" :value="modelNotMapping.created_by" :disabled="true"></TextBox>
                                            </td>
                                            <td>
                                                Created Date
                                            </td>
                                            <td>
                                                <TextBox inputId="created_date" name="created_date" class="f-field-text" :value="modelNotMapping.created_date" :disabled="true"></TextBox>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Updated By
                                            </td>
                                            <td>
                                                <TextBox inputId="updated_by" name="updated_by" class="f-field-text" :value="modelNotMapping.updated_by" :disabled="true"></TextBox>
                                            </td>
                                            <td>
                                                Updated Date
                                            </td>
                                            <td>
                                                <TextBox inputId="updated_date" name="updated_date" class="f-field-text" :value="modelNotMapping.updated_date" :disabled="true"></TextBox>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </TabPanel>
                        </Tabs>
                    </fieldset>
                </Form>
            </div>
            <div class="dialog-button">
                <LinkButton :iconCls="iconUtil.iconSave" v-if="action.add || action.edit" @click="onSave()" :disabled="disableBtnSave" style="width:89px">{{ btnText.save }}</LinkButton>
                <LinkButton :iconCls="iconUtil.iconCancel" @click="$refs.dlg.close()" style="width:89px">{{ btnText.cancel }}</LinkButton>
            </div>
        </Dialog>

        <NotificationComponent ref="notification"></NotificationComponent>
    </div>
    
</template>

<script>
import NotificationComponent from '@/components/NotificationComponent.vue';
import TransportOrder from '@/models/TransportOrder';
import TransportService from '@/services/TransportService';
import ModelUtil from '@/utils/ModelUtil';
import InformationUtil from '@/utils/InformationUtil';
import AccessUtil from '@/utils/AccessUtil';
import IconUtil from '@/utils/IconUtil';
import GlobalUtil from '@/utils/GlobalUtil';

import OptionService from '@/services/OptionService';

export default {
    name: 'TransportOrderView',
    components : {
        NotificationComponent
    },
    data() {
        return {
            titleView : InformationUtil.transportOrder + "s",
            titleMessage : InformationUtil.transportOrder,
            iconUtil : IconUtil,
            informationUtil : InformationUtil,

            titleForm :{
                add : InformationUtil.addText,
                edit : InformationUtil.editText
            },

            iconForm :{
                add : IconUtil.iconAdd,
                edit : IconUtil.iconEdit
            },
            
            action : {
                view : false,
                add : false,
                edit : false,
                delete : false
            },

            filter : {
                viewStatusMessage : false,
            },

            btnText :{
                refresh : InformationUtil.refreshText,
                add : InformationUtil.addText,
                edit : InformationUtil.editText,
                delete : InformationUtil.removeText,
                save : InformationUtil.saveText,
                cancel : InformationUtil.cancelText,
                search : InformationUtil.searchText,
                duplicate : InformationUtil.duplicateText,
                import : InformationUtil.importText,
                upload : InformationUtil.uploadText,
                downloadTemplate : InformationUtil.downloadTemplateText
            },

            search : {
                text : InformationUtil.searching,
                placeholder : InformationUtil.searchHolder
            },
            
            modelMultiSearch : {
                do_number : "",
                so_number : "",
                delivery_date_from : null,
                delivery_date_to : null,
                req_arrival_date_from : null,
                req_arrival_date_to : null,
                created_date_from : null,
                created_date_to : null,
                origin_id : "",
                dest_id : "",
                order_type : null,
                client_id : "",
                order_status : null,
            },

            dataGrid : {
                loading: false,
                rows : [],
                total: 0,
                start: 0,
                pageNumber: 1,
                pageSize: 10,
                selectionMode : 'single',
                pageLayout: ['list','sep','first','prev','next','last','sep','refresh','info'],

                // filter
                search : null,
                sortField : "",
                sortOrder : ""
            },

            dataGridDetail : {
                loading: false,
                rows : [],
                rowsTemp : [],
                total: 0,
                start: 0,
                pageNumber: 1,
                pageSize: 10,
                selectionMode : 'single',
                pageLayout: ['list','sep','first','prev','next','last','sep','refresh','info'],

                // filter
                search : null,
                sortField : "",
                sortOrder : "",
            },

            // form
            title: '',
            icon: '',
            model: TransportOrder,
            disabled : false,
            disabledClosed : false,
            disableBtnSave : false,
            modelNotMapping : {
                reference_id : "",
                created_by : "",
                updated_by : "",
                created_date : "",
                updated_date : "",
                origin_customer_name : "",
                dest_customer_name : "",
                origin_customer_address : "",
                dest_customer_address : "",
                order_status_desc : "",
            },
            searchStatusData : 0,

            // validation
            rules: {
                client_id : 'required',
                do_number : 'required',
                so_number : 'required',
                order_type : 'required',
                origin_id : 'required',
                dest_id : 'required',
                delivery_date : 'required',
                req_arrival_date : 'required',
                order_qty : 'required',
                uom : 'required',
                order_qty_v2 : 'required',
                uom_v2 : 'required',
            },
            errors: {},
            textError :{
                client_id : '',
                do_number : '',
                so_number : '',
                order_type : '',
                origin_id : '',
                dest_id : '',
                delivery_date : '',
                req_arrival_date : '',
                order_qty : '',
                uom : '',
                order_qty_v2 : '',
                uom_v2 : '',
            },

            // combobox
            statusData : [
                { value: 0 , text: 'Open' },
                { value: 1 , text: 'Closed' },
                { value: 'all' , text: 'All' },
            ],
            uomList : [],
            uomListV2 : [],
            orderTypes: [],
            clients : [],
            customer_origins : [],
            customer_destinations : [],
            
            statusDataSearch : [
                { value: 0 , text: 'OPEN' },
                { value: 1 , text: 'CLOSE' },
            ],

            
            // file
            file : '',
            fileName : '',
        }
    },
    methods:{
        onTabSelected(){
            this.dataGrid.search = null;
            this.onRefresh();
        },
        getError(name) {
            return this.errors[name] && this.errors[name].length
            ? this.errors[name][0]
            : null;
        },
        onAdd(){
            ModelUtil.resetModel(this.textError);
            // method for add data
            this.disabled = false;
            this.$refs.dataGrid.unselectRow(this.$refs.dataGrid.selectedRows[0]);
            this.model = ModelUtil.resetModel(this.model);
            this.modelNotMapping = ModelUtil.resetModel(this.modelNotMapping);
            this.onCallAllOption();

            // set data default
            this.model.order_type = 1;
            this.model.uom = 1;
            this.model.uom_v2 = 1;
            this.model.delivery_date = new Date();
            this.model.req_arrival_date = new Date();
            if(this.model.order_status == 1){
                this.disabledClosed = true;
            }else{
                this.disabledClosed = false;
            }
            
            this.model.order_qty = parseInt(0);
            this.model.order_qty_v2 = parseInt(0);

            //  reset grid details
            this.dataGridDetail.rows = [];
            this.dataGridDetail.rowsTemp = [];

            this.title = this.titleForm.add;
            this.icon = this.iconForm.add;
            this.$refs.dlg.open();
        },
        onEdit(){
            ModelUtil.resetModel(this.textError);
            // method for get data to edit
            if(this.$refs.dataGrid.selectedRows.length > 0){
                this.disabled = true;
                this.title = this.titleForm.edit;
                this.icon = this.iconForm.edit;
                this.onCallAllOption();

                // additional for combo box
                this.clients = [{value: this.$refs.dataGrid.selectedRows[0].client_id, text: this.$refs.dataGrid.selectedRows[0].client_desc, address : this.$refs.dataGrid.selectedRows[0].client_address}];
                this.customer_origins = [{value: this.$refs.dataGrid.selectedRows[0].origin_id, text: this.$refs.dataGrid.selectedRows[0].origin_desc, name : this.$refs.dataGrid.selectedRows[0].origin_name, address: this.$refs.dataGrid.selectedRows[0].origin_address}]
                this.customer_destinations = [{value: this.$refs.dataGrid.selectedRows[0].dest_id, text: this.$refs.dataGrid.selectedRows[0].dest_desc, name : this.$refs.dataGrid.selectedRows[0].dest_name, address: this.$refs.dataGrid.selectedRows[0].dest_address}]
                this.uomList = [{value: this.$refs.dataGrid.selectedRows[0].uom, text: this.$refs.dataGrid.selectedRows[0].uom_desc}]
                this.uomListV2 = [{value: this.$refs.dataGrid.selectedRows[0].uom_v2, text: this.$refs.dataGrid.selectedRows[0].uom_v2_desc}]

                this.model = ModelUtil.resetModel(this.model);
                this.model = ModelUtil.mapModel(this.model, this.$refs.dataGrid.selectedRows[0])
                this.modelNotMapping = ModelUtil.resetModel(this.modelNotMapping);
                this.modelNotMapping = ModelUtil.mapModel(this.modelNotMapping, this.$refs.dataGrid.selectedRows[0])

                this.model.delivery_date = new Date(this.model.delivery_date);
                this.model.req_arrival_date = new Date(this.model.req_arrival_date);
                if(this.model.order_status == 1){
                    this.disabledClosed = true;
                }else{
                    this.disabledClosed = false;
                }
                this.modelNotMapping.reference_id = String(this.modelNotMapping.reference_id);
                this.model.order_qty = parseInt(this.model.order_qty);
                this.model.order_qty_v2 = parseInt(this.model.order_qty_v2);

                this.dataGridDetail.rows = this.model.item_product != null ? JSON.parse(this.model.item_product) : null;
                this.dataGridDetail.rowsTemp = this.dataGridDetail.rows;

                this.$refs.dlg.open();
            }else{
                this.$refs.notification.centerTop(InformationUtil.selectRow);
            }
        },
        onDelete(){
            if(this.$refs.dataGrid.selectedRows.length > 0){

                this.$messager.confirm({
                    title: 'Confirm',
                    msg: InformationUtil.confirmDelete,
                    result: (r) => {
                        if (r){
                            this.dataGrid.loading = true;
                            TransportService.deleteData("/transportOrder", this.$refs.dataGrid.selectedRows[0].id)
                                .then((response) => {
                                    this.dataGrid.loading = false;
                                    this.$refs.dlg.close();
                                    if(response.code == 200){
                                        this.$refs.notification.success(this.titleMessage + " " + InformationUtil.delete);
                                        this.loadData();
                                    }else{
                                        this.$refs.notification.error(response.message);
                                    }
                                }).catch((err) => {
                                    this.$refs.notification.error(err.data.message);
                                });
                            this.$refs.dataGrid.unselectRow(this.$refs.dataGrid.selectedRows[0]) 
                        }
                    }
                })
            }else{
                this.$refs.notification.centerTop(InformationUtil.selectRow);
            }
        },
        onSave(){
            ModelUtil.resetModel(this.textError);
            this.$refs.form.validate((valid) => {
                if(!valid){
                    this.disableBtnSave = true; 
                    this.model.delivery_date = new Date(this.$moment(this.model.delivery_date).format('YYYY-MM-DD'));
                    this.model.req_arrival_date = new Date(this.$moment(this.model.req_arrival_date).format('YYYY-MM-DD'));
                    if(this.model.id == null){
                        // action add data
                        TransportService.addData("/transportOrder", this.model)
                            .then((res) => {
                                this.disableBtnSave = false;
                                if(res.code == 200){
                                    this.$refs.notification.success(this.titleMessage + " " + InformationUtil.add);
                                    this.loadData();
                                    this.$refs.dlg.close();
                                } else {
                                    this.$messager.alert({
                                        title: "Error",
                                        icon: "error",
                                        msg: res.message
                                    });
                                }
                            }).catch((error) => {
                                this.disableBtnSave = false;
                                this.$refs.notification.error(error);
                            });

                    }else{
                        // action update data
                        TransportService.updateData("/transportOrder", this.model.id, this.model)
                            .then((res) => {
                                this.disableBtnSave = false;
                                if(res.code == 200){
                                    this.$refs.notification.success(this.titleMessage + " " + InformationUtil.update);
                                    this.loadData();
                                    this.$refs.dlg.close();
                                } else {
                                    this.$messager.alert({
                                        title: "Error",
                                        icon: "error",
                                        msg: res.message
                                    });
                                }
                            }).catch((error) => {
                                this.disableBtnSave = false;
                                this.$refs.notification.error(error);
                            });
                    }
                    this.$refs.dataGrid.unselectRow(this.$refs.dataGrid.selectedRows[0])
                } else {
                    for(let key in valid){
                        this.textError[key] = this.getError(key);
                    }
                }
            });
            
        },
        onDuplicate(){
            if(this.$refs.dataGrid.selectedRows.length > 0){
                this.$messager.confirm({
                    title: 'Confirm',
                    msg: InformationUtil.confirmDuplicate,
                    result: (r) => {
                        if (r){
                            this.dataGrid.loading = true;
                            let param = {
                                id : this.$refs.dataGrid.selectedRows[0].id
                            }
                            TransportService.post("/transportOrder/duplicate", param)
                                .then((response) => {
                                    this.dataGrid.loading = false;
                                    this.$refs.dlg.close();
                                    if(response.code == 200){
                                        this.$refs.notification.success(this.titleMessage + " " + InformationUtil.duplicate);
                                        this.loadData();
                                    }else{
                                        this.$refs.notification.error(response.message);
                                    }
                                }).catch((err) => {
                                    this.$refs.notification.error(err.data.message);
                                });
                            this.$refs.dataGrid.unselectRow(this.$refs.dataGrid.selectedRows[0]) 
                        }
                    }
                })
            }else{
                this.$refs.notification.centerTop(InformationUtil.selectRow);
            }
        },

        // import file
        onDownloadFile(){
            TransportService.downloadFile("/transportOrder/download")
                .then((res) => {
                    if(res.type === 'application/vnd.ms-excel' && res != null){
                        let blob = new Blob([res], { type: "application/vnd.ms-excel" })
                        let objectUrl = window.URL.createObjectURL(blob)
                        const elem = window.document.createElement('a')
                        elem.href = objectUrl;
                        elem.download = "transport_order_template.xls"
                        document.body.appendChild(elem)
                        elem.click()
                        document.body.removeChild(elem)
                        window.URL.revokeObjectURL(blob);
                        window.URL.revokeObjectURL(objectUrl);
                    } else {
                        let message = new Blob([res], { type: 'application/json' })
                        message.text()
                            .then(value => {
                                let valMsg = JSON.parse(value);
                                this.$messager.alert({
                                    title: "Error",
                                    icon: "error",
                                    msg: valMsg.message
                                });
                            })
                            .catch(error => {
                                console.log("Something went wrong" + error);
                            });
                    }
                }).catch((error) => {
                    this.$refs.notification.error(error);
                });
        },
        onImport(){
            this.$refs.dlgImport.open();
            this.file = null;
            this.fileName = "";
        },
        onSelectedFile(event){
            this.file = event[0];
            this.fileName = this.file.name;
        },

        onSaveImport(){
            if(this.file != null && this.file != ''){
                let formData = new FormData();
                formData.append('file', this.file);
                TransportService.importFile("/transportOrder/import", formData)
                    .then((res) => {
                        if(res != null){
                            let blob = new Blob([res], { type: "application/vnd.ms-excel" })
                            let objectUrl = window.URL.createObjectURL(blob)
                            const elem = window.document.createElement('a')
                            elem.href = objectUrl;
                            elem.download = "transport_order_validation.xls"
                            document.body.appendChild(elem)
                            elem.click()
                            document.body.removeChild(elem)
                            window.URL.revokeObjectURL(blob);
                            window.URL.revokeObjectURL(objectUrl);
                            
                            this.loadData();
                            this.$refs.notification.success(InformationUtil.refresh);

                            this.$refs.dlgImport.close();
                        } else {
                            this.$messager.alert({
                                        title: "Error",
                                        icon: "error",
                                        msg: res.message
                                    });
                        }
                    }).catch((error) => {
                        this.$refs.notification.error(error);
                    });
            } else {
                this.$messager.alert({
                    title: "Info",
                    icon: "info",
                    msg: InformationUtil.infoSelectFile
                });
            }
        },

        onCancelImport(){
            this.$refs.dlgImport.close();
        },
        
        // handle data grid
        onMultiSearch(){
            this.onCallAllOption();
            this.$refs.dlgSearch.open();
        },
        onSearchMulti(){
            this.dataGrid.pageNumber = 1;
            this.$refs.dataGrid.unselectRow(this.$refs.dataGrid.selectedRows[0]);

            // set date format
            this.modelMultiSearch.delivery_date_from = this.modelMultiSearch.delivery_date_from != null ? new Date(this.$moment(this.modelMultiSearch.delivery_date_from).format('YYYY-MM-DD')) : null;
            this.modelMultiSearch.delivery_date_to = this.modelMultiSearch.delivery_date_to != null ? new Date(this.$moment(this.modelMultiSearch.delivery_date_to).format('YYYY-MM-DD')) : null;
            this.modelMultiSearch.req_arrival_date_from = this.modelMultiSearch.req_arrival_date_from != null ? new Date(this.$moment(this.modelMultiSearch.req_arrival_date_from).format('YYYY-MM-DD')) : null;
            this.modelMultiSearch.req_arrival_date_to = this.modelMultiSearch.req_arrival_date_to != null ? new Date(this.$moment(this.modelMultiSearch.req_arrival_date_to).format('YYYY-MM-DD')) : null;
            this.modelMultiSearch.created_date_from = this.modelMultiSearch.created_date_from != null ? new Date(this.$moment(this.modelMultiSearch.created_date_from).format('YYYY-MM-DD')) : null;
            this.modelMultiSearch.created_date_to = this.modelMultiSearch.created_date_to != null ? new Date(this.$moment(this.modelMultiSearch.created_date_to).format('YYYY-MM-DD')) : null;

            this.loadData();
            this.$refs.dlgSearch.close();
        },
        onResetSearchMulti(){
            this.modelMultiSearch = ModelUtil.resetModel(this.modelMultiSearch);

            this.modelMultiSearch.delivery_date_from = null;
            this.modelMultiSearch.delivery_date_to = null;
            this.modelMultiSearch.req_arrival_date_from = null;
            this.modelMultiSearch.req_arrival_date_to = null;
            this.modelMultiSearch.created_date_from = null;
            this.modelMultiSearch.created_date_to = null;

            this.dataGrid.pageNumber = 1;
            this.$refs.dataGrid.unselectRow(this.$refs.dataGrid.selectedRows[0]);
            this.loadData();
            this.$refs.dlgSearch.close();
        },
        async onSelectionStatusData(){
            this.dataGrid.pageNumber = 1;
            await this.loadData();
            this.$refs.dataGrid.unselectRow(this.$refs.dataGrid.selectedRows[0]);
            if(this.filter.viewStatusMessage){
                this.$refs.notification.success(InformationUtil.refresh);
            }
            this.filter.viewStatusMessage = true;
        },
        onRowDblClick(){
            if(this.action.edit){
                this.onEdit();
            }
        },
        async onRefresh(){
            this.dataGrid.pageNumber = 1;
            this.$refs.dataGrid.unselectRow(this.$refs.dataGrid.selectedRows[0]);
            await this.loadData();
            this.$refs.notification.success(InformationUtil.refresh);
        },
        async onSearch(){
            await this.loadData();
            this.$refs.dataGrid.unselectRow(this.$refs.dataGrid.selectedRows[0]);
            this.$refs.notification.success(InformationUtil.filter);
        },
        onSortChange(event){
            if(event.length > 0){
                this.dataGrid.pageNumber = 1;
                this.dataGrid.sortField = event[0].field;
                this.dataGrid.sortOrder = event[0].order;
                this.loadData();
            }
        },
        onPageChange(event) {
            this.$refs.dataGrid.unselectRow(this.$refs.dataGrid.selectedRows[0]);
            this.dataGrid.pageNumber = event.pageNumber;
            this.dataGrid.pageSize = event.pageSize;
            if(event.refresh) this.dataGrid.pageNumber = 1;
            this.loadData();
            if(event.refresh){
                this.$refs.notification.success(InformationUtil.refresh);
            }
        },

        async loadData(){
            this.dataGrid.loading = true;
            let param = {
                statusData : this.searchStatusData,
                multiSearch : this.modelMultiSearch,
            }
            return TransportService.getData('/transportOrder', this.dataGrid, param)
                .then((response) => {
                    if(response.data !== "" && response.data !== null){
                        let data = response.data; 
                        this.dataGrid.total = data.total;
                        this.dataGrid.rows = data.rows;
                    }
                    this.dataGrid.loading = false;
                });
        },

        // combo box data
        async optionClient(event){
            let search = event == null ? "" : event.filterValue;
            return await OptionService.getOption("/client", search)
                .then((response) => {
                    this.clients = response.data;
                }).catch((error) => {
                    this.$refs.notification.error(error);
                });
        },
        async optionCustomerOrigin(event){
            let search = event == null ? "" : event.filterValue;
            return await OptionService.getOption("/customer", search)
                .then((response) => {
                    this.customer_origins = response.data;
                }).catch((error) => {
                    this.$refs.notification.error(error);
                });
        },
        async optionCustomerDestination(event){
            let search = event == null ? "" : event.filterValue;
            return await OptionService.getOption("/customer", search)
                .then((response) => {
                    this.customer_destinations = response.data;
                }).catch((error) => {
                    this.$refs.notification.error(error);
                });
        },
        async optionUom(event){
            let search = event == null ? "" : event.filterValue;
            return await OptionService.getOption("/uom", search)
                .then((response) => {
                    this.uomList = response.data;
                }).catch((error) => {
                    this.$refs.notification.error(error);
                });
        },
        async optionUomV2(event){
            let search = event == null ? "" : event.filterValue;
            return await OptionService.getOption("/uom2", search)
                .then((response) => {
                    this.uomListV2 = response.data;
                }).catch((error) => {
                    this.$refs.notification.error(error);
                });
        },

        async ringCode(event){
            let search = event == null ? "" : event.filterValue;
            return await OptionService.getOption("/ringCode", search)
                .then((response) => {
                    this.orderTypes = response.data;
                }).catch((error) => {
                    this.$refs.notification.error(error);
                });
        },
        onCallAllOption(){
            this.optionClient();
            this.optionCustomerOrigin();
            this.optionCustomerDestination();
            this.optionUom();
            this.optionUomV2();
            this.ringCode();
        },
        onSelectionCustomerOrigin(row){
            this.modelNotMapping.origin_customer_name = row.name;
            this.modelNotMapping.origin_customer_address = row.address;
        },
        onSelectionCustomerDestination(row){
            this.modelNotMapping.dest_customer_name = row.name;
            this.modelNotMapping.dest_customer_address = row.address;
        },
    },
    async mounted(){
        this.action = AccessUtil.getAccessAction('transport_order');
        await this.loadData();
        this.$refs.notification.success(InformationUtil.loadGrid + " " + this.dataGrid.total + " " + InformationUtil.successLoadGrid);
    },
    created(){
        GlobalUtil.removeWindow();
    }
};
</script>