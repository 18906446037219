<template>
    <div>
        <Panel :title="titleView"  style="margin:0px 1px; ">
            <div class="datagrid-toolbar">
                <LinkButton :plain="true" :iconCls="iconUtil.iconRefresh" @click="onRefresh()">{{ btnText.refresh }}</LinkButton>
                <LinkButton v-if="action.add" :plain="true" :iconCls="iconUtil.iconAdd" @click="onAdd()">{{ btnText.add }}</LinkButton>
                <LinkButton v-if="action.edit" :plain="true" :iconCls="iconUtil.iconEdit" @click="onEdit()">{{ btnText.edit }}</LinkButton>
                <span class="button-sep"></span> Status : 
                <ComboBox  :data="statusData" inputId="searchStatusData" name="searchStatusData" class="f-field-text" style="width:75px; margin-left: 5px; margin-right: 5px;" v-model="searchStatusData" @selectionChange="onSelectionStatusData($event)"></ComboBox>
                <span class="button-sep"></span> {{ search.text }} : 
                <SearchBox style="width:300px"
                    :placeholder="search.placeholder" 
                    v-model="dataGrid.search"
                    @search="onSearch()">
                    <Addon>
                        <span v-if="dataGrid.search" class="textbox-icon icon-clear" title="Clear value" @click="dataGrid.search=null;onSearch();"></span>
                    </Addon>
                </SearchBox>
                <LinkButton :plain="true" :iconCls="iconUtil.iconMultiSearch" @click="onMultiSearch()">{{ informationUtil.multiSearchText }}</LinkButton>
            </div>
            <DataGrid
                    ref="dataGrid"
                    :columnResizing="true"
                    :border="false"
                    :lazy="true"
                    :loading="dataGrid.loading"
                    :data="dataGrid.rows"
                    :pagination="true"
                    :selectionMode="dataGrid.selectionMode"
                    :total="dataGrid.total"
                    :pageSize="dataGrid.pageSize"
                    :pageNumber="dataGrid.pageNumber"
                    :pageLayout="dataGrid.pageLayout" 
                    @pageChange="onPageChange($event)"
                    @sortChange="onSortChange($event)"
                    @rowDblClick="onRowDblClick()"
                    >
                    <GridColumn align="center" cellCss="datagrid-td-rownumber" width="30">
                    <template slot="body" slot-scope="scope">
                        {{scope.rowIndex + 1}}
                    </template>
                </GridColumn>
                <GridColumn field="dn_number" title="DN Number" :sortable="true"></GridColumn>
                <GridColumn field="delivery_date_desc" title="Req. Delivery Date" :sortable="true"></GridColumn>
                <GridColumn field="created_date" title="Created Date" :sortable="true"></GridColumn>
                <GridColumn field="client_desc" title="Client ID" :sortable="true"></GridColumn>
                <GridColumn field="order_type_desc" title="Order Type" :sortable="true"></GridColumn>
                <GridColumn field="total_qty" title="Total Qty" :sortable="true"></GridColumn>
                <GridColumn field="total_weight" title="Total Tonase" :sortable="true"></GridColumn>
                <GridColumn field="total_volume" title="Total M3" :sortable="true"></GridColumn>
                <GridColumn field="status_desc" title="Status" :sortable="true"></GridColumn>
            </DataGrid>
        </Panel>

        <!-- Multi Search -->
        <Dialog ref="dlgSearch" :iconCls="iconUtil.iconMultiSearch" bodyCls="f-column" :title="informationUtil.multiSearchText" :draggable="true" :modal="true" closed :dialogStyle="{width:'500px', height:'auto'}">
            <div class="f-full" style="overflow:auto">
                <table width="100%" cellspacing="1" cellpadding="5" border="0">
                    <tbody>
                        <tr>
                            <td class="bg_subtitle">DN Number</td>
                            <td class="bg_grid2" colspan="3">
                                <TextBox inputId="dn_number" name="dn_number" class="f-field-text" v-model="modelMultiSearch.dn_number">
                                    <Addon>
                                        <span v-if="modelMultiSearch.dn_number" class="textbox-icon icon-clear" title="Clear value" @click="modelMultiSearch.dn_number=null;"></span>
                                    </Addon>
                                </TextBox>
                            </td>
                        </tr>
                        <tr>
                            <td class="bg_subtitle">Delivery Date</td>
                            <td class="bg_grid2">
                                <DateBox inputId="delivery_date_from" name="delivery_date_from" class="f-field-text" v-model="modelMultiSearch.delivery_date_from" format="dd/MM/yyyy">
                                    <Addon>
                                        <span v-if="modelMultiSearch.delivery_date_from" class="textbox-icon icon-clear" title="Clear value" @click="modelMultiSearch.delivery_date_from=null;"></span>
                                    </Addon>
                                </DateBox>
                            </td>
                            <td>to</td>
                            <td class="bg_grid2">
                                <DateBox inputId="delivery_date_to" name="delivery_date_to" class="f-field-text" v-model="modelMultiSearch.delivery_date_to" format="dd/MM/yyyy">
                                    <Addon>
                                        <span v-if="modelMultiSearch.delivery_date_to" class="textbox-icon icon-clear" title="Clear value" @click="modelMultiSearch.delivery_date_to=null;"></span>
                                    </Addon>
                                </DateBox>
                            </td>
                        </tr>

                        <tr>
                            <td class="bg_subtitle">Created Date</td>
                            <td class="bg_grid2">
                                <DateBox inputId="created_date_from" name="created_date_from" class="f-field-text" v-model="modelMultiSearch.created_date_from" format="dd/MM/yyyy">
                                    <Addon>
                                        <span v-if="modelMultiSearch.created_date_from" class="textbox-icon icon-clear" title="Clear value" @click="modelMultiSearch.created_date_from=null;"></span>
                                    </Addon>
                                </DateBox>
                            </td>
                            <td>to</td>
                            <td class="bg_grid2">
                                <DateBox inputId="created_date_to" name="created_date_to" class="f-field-text" v-model="modelMultiSearch.created_date_to" format="dd/MM/yyyy">
                                    <Addon>
                                        <span v-if="modelMultiSearch.created_date_to" class="textbox-icon icon-clear" title="Clear value" @click="modelMultiSearch.created_date_to=null;"></span>
                                    </Addon>
                                </DateBox>
                            </td>
                        </tr>

                        <tr>
                            <td class="bg_subtitle">Order Type </td>
                            <td class="bg_grid2" colspan="3">
                                <ComboBox  :data="orderTypes" inputId="order_type" name="order_type" class="f-field-text" v-model="modelMultiSearch.order_type">
                                    <Addon>
                                        <span v-if="modelMultiSearch.order_type" class="textbox-icon icon-clear" title="Clear value" @click="modelMultiSearch.order_type=null;"></span>
                                    </Addon>
                                </ComboBox>
                            </td>
                        </tr>

                        <tr>
                            <td class="bg_subtitle">
                                Client ID
                            </td>
                            <td class="bg_grid2" colspan="3"> 
                                <ComboBox  :data="clients" @filterChange="optionClient($event)" inputId="client_id" name="client_id" class="f-field-text" v-model="modelMultiSearch.client_id">
                                    <template slot="item" slot-scope="scope">
                                        <span style="font-weight:bold">{{scope.row.text}}</span><br/>
                                        <span style="color:#888">{{scope.row.address}}</span><br/>
                                    </template>
                                    <Addon>
                                        <span v-if="modelMultiSearch.client_id" class="textbox-icon icon-clear" title="Clear value" @click="modelMultiSearch.client_id=null;"></span>
                                    </Addon>
                                </ComboBox>
                            </td>
                        </tr>
                        <tr>
                            <td class="bg_subtitle">Status Data</td>
                            <td class="bg_grid2" colspan="3">
                                <ComboBox  :data="statusDataSearch" inputId="status" name="status" class="f-field-text" v-model="modelMultiSearch.status">
                                    <Addon>
                                        <span v-if="modelMultiSearch.status" class="textbox-icon icon-clear" title="Clear value" @click="modelMultiSearch.status=null;"></span>
                                    </Addon>
                                </ComboBox>
                            </td>
                        </tr>                        
                    </tbody>
                </table>
            </div>
            <div class="dialog-button">
                <LinkButton :iconCls="iconUtil.iconSearch" @click="onSearchMulti()" style="width:89px">{{ informationUtil.searchText }}</LinkButton>
                <LinkButton :iconCls="iconUtil.iconCancel" @click="onResetSearchMulti()" style="width:89px">{{ informationUtil.resetText }}</LinkButton>
            </div>
        </Dialog>

        <!-- Form -->
        <Dialog ref="dlg" :iconCls="icon" bodyCls="f-column" :title="title" :draggable="true" :modal="true" closed :dialogStyle="{width:'750px', height:'600px'}">
            <div class="f-full" style="overflow:auto">
                <Form ref="form" :model="model" :rules="rules" @validate="errors=$event">
                    <fieldset class="c-fieldset">
                        <legend class="c-legend"><b>{{ titleMessage }}</b></legend>
                        <table width="100%" cellspacing="1" cellpadding="5" border="0">
                            <tbody>
                                <tr>
                                    <td>DN Number <font color="red">*</font></td>
                                    <td>
                                        <TextBox inputId="dn_number" name="dn_number" class="f-field-text" v-model="model.dn_number" :disabled="disabledClosed"></TextBox>
                                        <div class="error">{{textError.dn_number}}</div>
                                    </td>
                                    <td>
                                        Client ID <font color="red">*</font>
                                    </td>
                                    <td> 
                                        <ComboBox  :data="clients" inputId="client_id" name="client_id" @filterChange="optionClient($event)" @selectionChange="optionSalesOrder($event);resetDetail()" class="f-field-text" v-model="model.client_id" :disabled="disabledClosed">
                                            <template slot="item" slot-scope="scope">
                                                <span style="font-weight:bold">{{scope.row.text}}</span><br/>
                                                <span style="color:#888">{{scope.row.address}}</span><br/>
                                            </template>
                                        </ComboBox>
                                        <div class="error">{{textError.client_id}}</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Reference</td>
                                    <td>
                                        <TextBox inputId="reference" name="reference" class="f-field-text" v-model="model.reference" :disabled="disabledClosed"></TextBox>
                                    </td>
                                    <td>Order Type <font color="red">*</font></td>
                                    <td>
                                        <ComboBox  :data="orderTypes" inputId="order_type" name="order_type" class="f-field-text" v-model="model.order_type" :disabled="disabledClosed"></ComboBox>
                                        <div class="error">{{textError.order_type}}</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Delivery Date <font color="red">*</font></td>
                                    <td>
                                        <DateBox inputId="delivery_date" name="delivery_date" class="f-field-text" v-model="model.delivery_date" format="dd/MM/yyyy" :disabled="disabledClosed"></DateBox>
                                        <div class="error">{{textError.delivery_date}}</div>
                                    </td>
                                    <td>Status</td>
                                    <td>
                                        <TextBox inputId="status_desc" name="status_desc" class="f-field-text" v-model="modelNotMapping.status_desc" :disabled="true"></TextBox>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Remark</td>
                                    <td>
                                        <TextBox inputId="remark" name="remark" :multiline="true" class="f-field-text" v-model="model.remark"></TextBox>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </fieldset>
                    <fieldset class="c-fieldset">
                        <legend class="c-legend"><b>Details Information</b></legend>
                        <Tabs style="height:395px">
                            <TabPanel :title="'Product'">
                                <fieldset class="c-fieldset">
                                    <legend class="c-legend"><b>&nbsp;&nbsp;Item</b></legend>
                                    <table width="50%" cellspacing="1" cellpadding="5" border="0" style="float: left;">
                                        <tbody>
                                            <tr>
                                                <td><LinkButton v-if="action.add && isAddData()" :iconCls="iconUtil.iconAdd" style="border:none;" @click="addDetail()">{{ btnText.add }}</LinkButton></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <table width="50%" cellspacing="1" cellpadding="5" border="0">
                                        <tbody>
                                            <tr>
                                                <td width="50%">
                                                    Total Weight
                                                </td>
                                                <td>:</td>
                                                <td>
                                                    {{ this.modelPreferredVehicleType.weight }}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Preferred Vehicle Type
                                                </td>
                                                <td>:</td>
                                                <td>
                                                    {{ this.modelPreferredVehicleType.vehicle }}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </fieldset>
                                <div class="datagrid-toolbar" style="margin-bottom: 10px;">
                                    {{ search.text }} : 
                                    <SearchBox style="width:300px"
                                        :placeholder="search.placeholder" 
                                        v-model="dataGridDetail.search"
                                        @search="onSearchDetail()">
                                        <Addon>
                                            <span v-if="dataGridDetail.search" class="textbox-icon icon-clear" title="Clear value" @click="dataGridDetail.search=null;onSearchDetail();"></span>
                                        </Addon>
                                    </SearchBox>
                                    <LinkButton v-if="action.delete && isAddData()" :plain="true" :iconCls="iconUtil.iconDelete" @click="onDeleteDetail()">{{ btnText.delete }}</LinkButton>
                                    <LinkButton v-if="action.edit && isAddData()" :plain="true" :iconCls="iconUtil.iconEdit" @click="onEditDetail()">{{ btnText.edit }}</LinkButton>
                                </div>
                                <DataGrid
                                    ref="dataGridDetail"
                                    :columnResizing="true"
                                    :border="false"
                                    :loading="dataGridDetail.loading"                                    
                                    :data="dataGridDetail.rows"
                                    :pagination="true"
                                    :selectionMode="dataGridDetail.selectionMode"
                                    :total="dataGridDetail.total"
                                    :pageSize="dataGridDetail.pageSize"
                                    :pageNumber="dataGridDetail.pageNumber"
                                    :pageLayout="dataGridDetail.pageLayout"
                                    >
                                    <GridColumn align="center" cellCss="datagrid-td-rownumber" width="30">
                                        <template slot="body" slot-scope="scope">
                                            {{scope.rowIndex + 1}}
                                        </template>
                                    </GridColumn>
                                    <GridColumn field="so_number" title="SO Number" :sortable="true"></GridColumn>
                                    <GridColumn field="product_name" title="Product Name" :sortable="true"></GridColumn>
                                    <GridColumn field="qty" title="Delivery Qty" :sortable="true"></GridColumn>
                                    <GridColumn field="uom_desc" title="UOM" :sortable="true"></GridColumn>
                                    <GridColumn field="weight" title="Weight" :sortable="true"></GridColumn>
                                    <GridColumn field="volume" title="Volume" :sortable="true"></GridColumn>
                                    <GridColumn field="base_qty" title="Base Qty" :sortable="true"></GridColumn>
                                </DataGrid>
                            </TabPanel>
                            <TabPanel :title="'Log'">
                                <table width="100%" cellspacing="1" cellpadding="5" border="0">
                                    <tbody>
                                        <tr>
                                            <td>
                                                Created By
                                            </td>
                                            <td>
                                                <TextBox inputId="created_by" name="created_by" class="f-field-text" :value="modelNotMapping.created_by" :disabled="true"></TextBox>
                                            </td>
                                            <td>
                                                Created Date
                                            </td>
                                            <td>
                                                <TextBox inputId="created_date" name="created_date" class="f-field-text" :value="modelNotMapping.created_date" :disabled="true"></TextBox>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Updated By
                                            </td>
                                            <td>
                                                <TextBox inputId="updated_by" name="updated_by" class="f-field-text" :value="modelNotMapping.updated_by" :disabled="true"></TextBox>
                                            </td>
                                            <td>
                                                Updated Date
                                            </td>
                                            <td>
                                                <TextBox inputId="updated_date" name="updated_date" class="f-field-text" :value="modelNotMapping.updated_date" :disabled="true"></TextBox>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </TabPanel>
                        </Tabs>
                    </fieldset>
                </Form>
            </div>
            <div class="dialog-button">
                <LinkButton :iconCls="iconUtil.iconSave" v-if="action.add || action.edit" @click="onSave()" :disabled="disableBtnSave" style="width:89px">{{ btnText.save }}</LinkButton>
                <LinkButton :iconCls="iconUtil.iconCancel" @click="$refs.dlg.close()" style="width:89px">{{ btnText.cancel }}</LinkButton>
            </div>
        </Dialog>

        <Dialog ref="dlgDetailData" :iconCls="iconDetail" bodyCls="f-column" :title="titleDetail" :draggable="true" :modal="true" closed :dialogStyle="{width:'380px', height:'auto'}">
            <div class="f-full" style="overflow:auto">
                <table width="100%" cellspacing="1" cellpadding="5" border="0">
                    <tbody>
                        <tr>
                            <td class="bg_subtitle">Produc Name</td>
                            <td class="bg_grid2">
                                {{ modelDetailData.product_name }}
                            </td>
                        </tr>
                        <tr>
                            <td class="bg_subtitle">Qty Remain</td>
                            <td class="bg_grid2">
                                {{ modelDetailData.qty_remain_current }}
                            </td>
                        </tr>
                        <tr>
                            <td class="bg_subtitle">Qty Delivery</td>
                            <td class="bg_grid2">
                                <NumberBox inputId="start" name="start" class="f-field-text" v-model="modelDetailData.qty" :spinners="false"></NumberBox>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="dialog-button">
                <LinkButton :iconCls="iconUtil.iconSave" @click="onSaveDetail()" style="width:89px">{{ btnText.save }}</LinkButton>
                <LinkButton :iconCls="iconUtil.iconCancel" @click="$refs.dlgDetailData.close()" style="width:89px">{{ btnText.cancel }}</LinkButton>
            </div>
        </Dialog>

        <NotificationComponent ref="notification"></NotificationComponent>
        <GridItemOrderComponent ref="gridItemOrder" actionData="delivery_order" :disableBtnSave="disableBtnSave" :clientId="this.model.client_id" :itemData="this.dataGridDetail.rows" @onSaveData="onSaveDataItem($event)"></GridItemOrderComponent>
    </div>
    
</template>

<script>
import NotificationComponent from '@/components/NotificationComponent.vue';
import DeliveryOrder from '@/models/DeliveryOrder';
import OutboundService from '@/services/OutboundService';
import ModelUtil from '@/utils/ModelUtil';
import InformationUtil from '@/utils/InformationUtil';
import AccessUtil from '@/utils/AccessUtil';
import IconUtil from '@/utils/IconUtil';
import GlobalUtil from '@/utils/GlobalUtil';

import OptionService from '@/services/OptionService';
// component
import GridItemOrderComponent from '@/components/grids/DeliveryOrder/GridItemOrderComponent.vue';

export default {
    name: 'DeliveryOrderView',
    components : {
        NotificationComponent,
        GridItemOrderComponent
    },
    data() {
        return {
            titleView : InformationUtil.deliveryOrder + "s",
            titleMessage : InformationUtil.deliveryOrder,
            iconUtil : IconUtil,
            informationUtil : InformationUtil,

            titleForm :{
                add : InformationUtil.addText,
                edit : InformationUtil.editText
            },

            iconForm :{
                add : IconUtil.iconAdd,
                edit : IconUtil.iconEdit
            },
            
            action : {
                view : false,
                add : false,
                edit : false,
                delete : false
            },

            filter : {
                viewStatusMessage : false,
            },

            btnText :{
                refresh : InformationUtil.refreshText,
                add : InformationUtil.addText,
                edit : InformationUtil.editText,
                delete : InformationUtil.removeText,
                save : InformationUtil.saveText,
                cancel : InformationUtil.cancelText,
                search : InformationUtil.searchText,
                duplicate : InformationUtil.duplicateText,
                import : InformationUtil.importText,
                upload : InformationUtil.uploadText,
                downloadTemplate : InformationUtil.downloadTemplateText
            },

            search : {
                text : InformationUtil.searching,
                placeholder : InformationUtil.searchHolder
            },
            
            modelMultiSearch : {
                dn_number : "",
                delivery_date_from : null,
                delivery_date_to : null,
                created_date_from : null,
                created_date_to : null,
                order_type : null,
                client_id : "",
                status : null,
            },

            dataGrid : {
                loading: false,
                rows : [],
                total: 0,
                start: 0,
                pageNumber: 1,
                pageSize: 10,
                selectionMode : 'single',
                pageLayout: ['list','sep','first','prev','next','last','sep','refresh','info'],

                // filter
                search : null,
                sortField : "",
                sortOrder : ""
            },

            dataGridDetail : {
                loading: false,
                rows : [],
                rowsTemp : [],
                total: 0,
                start: 0,
                pageNumber: 1,
                pageSize: 10,
                selectionMode : 'single',
                pageLayout: ['list','sep','first','prev','next','last','sep','refresh','info'],

                // filter
                search : null,
                sortField : "",
                sortOrder : "",
            },

            // form
            title: '',
            icon: '',
            titleDetail: '',
            iconDetail: '',
            model: DeliveryOrder,
            disabled : false,
            disabledClosed : false,
            disableBtnSave : false,
            modelNotMapping : {
                created_by : "",
                updated_by : "",
                created_date : "",
                updated_date : "",
                status_desc : "",
            },
            
            searchStatusData : 0,

            // validation
            rules: {
                client_id : 'required',
                dn_number : 'required',
                order_type : 'required',
                delivery_date : 'required'
            },
            errors: {},
            textError :{
                client_id : '',
                dn_number : '',
                order_type : '',
                delivery_date : ''
            },

            // combobox
            statusData : [
                { value: 0 , text: 'Open' },
                { value: 1 , text: 'Closed' },
                { value: 'all' , text: 'All' },
            ],

            orderTypes: [],
            clients : [],
            salesOrderList : [],
            productSalesOrderList : [],
            
            statusDataSearch : [
                { value: 0 , text: 'OPEN' },
                { value: 1 , text: 'CLOSE' },
            ],
            
            modelPreferredVehicleType : {
                weight : "0",
                vehicle : "",
                vehicle_type_id : 0
            },
            modelDetailData : {
                id_so_number : "",
                so_number : "",
                product_name : "",
                uom : "",
                uom_desc : "",
                qty : 0,
                base_qty : "",
                weight : "",
                volume : "",
                max_volume : "",
                max_weight : "",
                vehicle_type_id : "",
                vehicle_type_desc : "",
                qty_remain_current : 0,
                weight_per_qty : 0,
                volume_per_qty : 0
            }
        }
    },
    methods:{
        isAddData() {
            return this.title === this.titleForm.add ? true : false;
        },
        resetDetail(){            
            if(this.isAddData()){
                this.dataGridDetail.rows = [];
                this.dataGridDetail.rowsTemp = [];
            }

            this.setPreferredVehicleType();
        },
        onTabSelected(){
            this.dataGrid.search = null;
            this.onRefresh();
        },
        getError(name) {
            return this.errors[name] && this.errors[name].length
            ? this.errors[name][0]
            : null;
        },
        onAdd(){
            ModelUtil.resetModel(this.textError);
            // method for add data
            this.disabled = false;
            this.disableBtnSave = false; 
            this.$refs.dataGrid.unselectRow(this.$refs.dataGrid.selectedRows[0]);
            this.model = ModelUtil.resetModel(this.model);
            this.modelNotMapping = ModelUtil.resetModel(this.modelNotMapping);
            this.modelPreferredVehicleType = ModelUtil.resetModel(this.modelPreferredVehicleType);
            this.onCallAllOption();

            //  reset grid details
            this.dataGridDetail.rows = [];
            this.dataGridDetail.rowsTemp = [];

            // set data default
            this.model.order_type = 1;
            this.model.uom = 1;
            this.model.uom_v2 = 1;
            this.model.delivery_date = new Date();
            if(this.model.status == 1){
                this.disabledClosed = true;
            }else{
                this.disabledClosed = false;
            }

            this.title = this.titleForm.add;
            this.icon = this.iconForm.add;
            this.$refs.dlg.open();
        },
        onEdit(){
            ModelUtil.resetModel(this.textError);
            ModelUtil.resetModel(this.modelPreferredVehicleType);
            // method for get data to edit
            if(this.$refs.dataGrid.selectedRows.length > 0){
                this.disabled = true;
                this.disableBtnSave = false; 
                this.title = this.titleForm.edit;
                this.icon = this.iconForm.edit;
                this.onCallAllOption();

                // additional for combo box
                this.clients = [{value: this.$refs.dataGrid.selectedRows[0].client_id, text: this.$refs.dataGrid.selectedRows[0].client_desc, address : this.$refs.dataGrid.selectedRows[0].client_address}];
                this.model = ModelUtil.resetModel(this.model);
                this.model = ModelUtil.mapModel(this.model, this.$refs.dataGrid.selectedRows[0])
                this.model.client_id = Number(this.model.client_id)
                this.modelNotMapping = ModelUtil.resetModel(this.modelNotMapping);

                this.modelNotMapping = ModelUtil.mapModel(this.modelNotMapping, this.$refs.dataGrid.selectedRows[0])

                this.dataGridDetail.rows = this.model.item_product != null ? JSON.parse(this.model.item_product) : null;
                this.dataGridDetail.rowsTemp = this.dataGridDetail.rows;

                this.model.delivery_date = new Date(this.model.delivery_date);
                if(this.model.status == 1){
                    this.disabledClosed = true;
                }else{
                    this.disabledClosed = false;
                }
                
                this.setPreferredVehicleType();

                this.$refs.dlg.open();
            }else{
                this.$refs.notification.centerTop(InformationUtil.selectRow);
            }
        },
        onDelete(){
            if(this.$refs.dataGrid.selectedRows.length > 0){
                this.$messager.confirm({
                    title: 'Confirm',
                    msg: InformationUtil.confirmDelete,
                    result: (r) => {
                        if (r){
                            this.dataGrid.loading = true;
                            OutboundService.deleteData("/deliveryOrder", this.$refs.dataGrid.selectedRows[0].id)
                                .then((response) => {
                                    this.dataGrid.loading = false;
                                    this.$refs.dlg.close();
                                    if(response.code == 200){
                                        this.$refs.notification.success(this.titleMessage + " " + InformationUtil.delete);
                                        this.loadData();
                                    }else{
                                        this.$refs.notification.error(response.message);
                                    }
                                }).catch((err) => {
                                    this.$refs.notification.error(err.data.message);
                                });
                            this.$refs.dataGrid.unselectRow(this.$refs.dataGrid.selectedRows[0]) 
                        }
                    }
                })
            }else{
                this.$refs.notification.centerTop(InformationUtil.selectRow);
            }
        },
        onSave(){
            ModelUtil.resetModel(this.textError);
            this.$refs.form.validate((valid) => {
                if(!valid){
                    this.disableBtnSave = true; 
                    this.model.delivery_date = new Date(this.$moment(this.model.delivery_date).format('YYYY-MM-DD'));

                    this.model.item_product = this.dataGridDetail.rowsTemp;

                    this.model.preferred_vehicle_type_id = this.modelPreferredVehicleType.vehicle_type_id;
                    this.model.preferred_vehicle = this.modelPreferredVehicleType.vehicle;

                    //validation item product
                    if(this.model.item_product == null || this.model.item_product.length == 0){
                        this.$messager.alert({
                            title: "Error",
                            icon: "error",
                            msg: "Product is required"
                        });
                        this.disableBtnSave = false;
                        return;
                    }

                    if(this.model.id == null){
                        // action add data
                        OutboundService.addData("/deliveryOrder", this.model)
                            .then((res) => {
                                this.disableBtnSave = false;
                                if(res.code == 200){
                                    this.$refs.notification.success(this.titleMessage + " " + InformationUtil.add);
                                    this.loadData();
                                    this.$refs.dlg.close();
                                } else {
                                    this.$messager.alert({
                                        title: "Error",
                                        icon: "error",
                                        msg: res.message
                                    });
                                }
                            }).catch((error) => {
                                this.disableBtnSave = false;
                                this.$refs.notification.error(error);
                            });

                    }else{
                        this.$refs.dlg.close();
                    }
                    this.$refs.dataGrid.unselectRow(this.$refs.dataGrid.selectedRows[0])
                } else {
                    for(let key in valid){
                        this.textError[key] = this.getError(key);
                    }
                }
            });
        },

        // handle form item
        onSaveDataItem(row){
            if(row.length > 0) {
                row.forEach((item) => {
                    item.item_add.forEach((dtl) => {
                        let data = {
                            id_so_number : item.id,
                            so_number : item.so_number,
                            product_name : dtl.product_name,
                            uom : dtl.uom,
                            uom_desc : dtl.uom_desc,
                            qty : parseInt(dtl.qty_delivery),
                            qty_remain_current : dtl.qty_remain,
                            weight_per_qty : dtl.weight_per_qty,
                            volume_per_qty : dtl.volume_per_qty,
                            base_qty : parseInt(dtl.qty_remain) - parseInt(dtl.qty_delivery),
                            weight : parseFloat(parseInt(dtl.qty_delivery) * parseFloat(dtl.weight_per_qty)).toFixed(2),
                            volume : parseFloat(parseInt(dtl.qty_delivery) * parseFloat(dtl.volume_per_qty)).toFixed(2),
                            max_volume : dtl.max_volume,
                            max_weight : dtl.max_weight,
                            vehicle_type_id : dtl.vehicle_type_id,
                            vehicle_type_desc : dtl.vehicle_type_desc
                        }

                        this.dataGridDetail.rows.push(data);
                    })
                    
                });
                
                this.dataGridDetail.rowsTemp = this.dataGridDetail.rows;
                this.dataGridDetail.total = this.dataGridDetail.rows.length;

                // set preferred vehicle
                this.setPreferredVehicleType();
            }
        },
        onSaveDetail() {
            // validation qty actual must > 0 and <= qty reamin
            let data = this.modelDetailData
            if(data.qty < 1) {
                this.$messager.alert({
                    title: "Error",
                    icon: "error",
                    msg: "Qty Delivery must > 0"
                });
                return;
            }

            if(data.qty > data.qty_remain_current) {
                this.$messager.alert({
                    title: "Error",
                    icon: "error",
                    msg: "Qty Delivery must < Qty Remain : " + data.qty_remain
                });
                return;
            }
            
            // update data grid
            this.dataGridDetail.rows.map(row => {
                if(row.product_name == data.product_name){
                    row.qty = parseInt(data.qty);
                    row.qty_remain_current = data.qty_remain_current;
                    row.base_qty = parseInt(data.qty_remain_current) - parseInt(data.qty);
                    row.weight = parseFloat(parseInt(data.qty) * parseFloat(data.weight_per_qty)).toFixed(2);
                    row.volume = parseFloat(parseInt(data.qty) * parseFloat(data.volume_per_qty)).toFixed(2);
                }
                return row;
            });
            this.setPreferredVehicleType();

            this.$refs.dlgDetailData.close();                
            this.$refs.notification.success(this.titleMessage + " " + InformationUtil.update);
        },
        onEditDetail(){
            this.iconDetail = this.iconForm.edit;
            this.titleDetail = this.informationUtil.editText + " " + this.informationUtil.deliveryOrderItem;
            if(this.$refs.dataGridDetail.selectedRows.length > 0){
                this.modelDetailData = ModelUtil.resetModel(this.modelDetailData);
                this.modelDetailData = ModelUtil.mapModel(this.modelDetailData, this.$refs.dataGridDetail.selectedRows[0])
                this.$refs.dlgDetailData.open();
            }else{
                this.$messager.alert({
                    title: "Info",
                    icon: "info",
                    msg: InformationUtil.selectRow
                });
            }
        },
        onDeleteDetail(){
            if(this.$refs.dataGridDetail.selectedRows.length > 0){
                this.$messager.confirm({
                    title: 'Confirm',
                    msg: InformationUtil.confirmDelete,
                    result: (r) => {
                        if (r){
                            this.dataGridDetail.loading = true;
                            // find index
                            let index = -1;
                            for(let i = 0; i < this.dataGridDetail.rows.length; i++){
                                if(this.dataGridDetail.rows[i].product_name == this.$refs.dataGridDetail.selectedRows[0].product_name){
                                    index = i;
                                    break;
                                }
                            }
                            if(index > -1) this.dataGridDetail.rows.splice(index, 1);
                            this.dataGridDetail.loading = false;
                            this.$refs.notification.success(this.titleMessage + " " + InformationUtil.delete);
                            this.$refs.dataGridDetail.unselectRow(this.$refs.dataGridDetail.selectedRows[0]);
                            this.dataGridDetail.rowsTemp = this.dataGridDetail.rows;
                        }
                    }
                })
            }else{
                this.$messager.alert({
                    title: "Info",
                    icon: "info",
                    msg: InformationUtil.selectRow
                });
            }
        },
        addDetail(){            
            // validation client ID
            if(! this.model.client_id ) {
                this.$messager.alert({
                    title: "Error",
                    icon: "error",
                    msg: "Client ID is not found"
                });
                return;
            }
            
            // view grid item order
            this.$refs.gridItemOrder.onPageShow();
        },
        onSearchDetail(){
            let search = this.dataGridDetail.search;
            let rows = this.dataGridDetail.rowsTemp;
            let result = [];
            if(search != null && search != ""){
                for(let i = 0; i < rows.length; i++){
                    if(rows[i].product_name.toLowerCase().indexOf(search.toLowerCase()) > -1){
                        result.push(rows[i]);
                    }
                }
                this.dataGridDetail.rows = result;
            }else{
                this.dataGridDetail.rows = this.dataGridDetail.rowsTemp;
            }
        },

        // set preferred vehicle
        setPreferredVehicleType(){
            this.modelPreferredVehicleType = ModelUtil.resetModel(this.modelPreferredVehicleType);

            // sum weight
            let sumWeight = 0;
            for(let i = 0; i < this.dataGridDetail.rows.length; i++){
                sumWeight += parseFloat(this.dataGridDetail.rows[i].weight);
            }

            this.modelPreferredVehicleType.weight = sumWeight;

            // get object min max weight
            let minMaxWeight = null;
            for(let i = 0; i < this.dataGridDetail.rows.length; i++){
                if(minMaxWeight == null){
                    minMaxWeight = this.dataGridDetail.rows[i];
                }else{
                    if(minMaxWeight.max_weight > this.dataGridDetail.rows[i].max_weight){
                        minMaxWeight = this.dataGridDetail.rows[i];
                    }
                }
            }
            // get name vehicle type desc
            this.modelPreferredVehicleType.vehicle = minMaxWeight == null ? "" : minMaxWeight.vehicle_type_desc;
            this.modelPreferredVehicleType.vehicle_type_id = minMaxWeight == null ? 0 : minMaxWeight.vehicle_type_id;
        },

        // handle data grid
        onMultiSearch(){
            this.onCallAllOption();
            this.$refs.dlgSearch.open();
        },
        onSearchMulti(){
            this.dataGrid.pageNumber = 1;
            this.$refs.dataGrid.unselectRow(this.$refs.dataGrid.selectedRows[0]);

            // set date format
            this.modelMultiSearch.delivery_date_from = this.modelMultiSearch.delivery_date_from != null ? new Date(this.$moment(this.modelMultiSearch.delivery_date_from).format('YYYY-MM-DD')) : null;
            this.modelMultiSearch.delivery_date_to = this.modelMultiSearch.delivery_date_to != null ? new Date(this.$moment(this.modelMultiSearch.delivery_date_to).format('YYYY-MM-DD')) : null;
            this.modelMultiSearch.created_date_from = this.modelMultiSearch.created_date_from != null ? new Date(this.$moment(this.modelMultiSearch.created_date_from).format('YYYY-MM-DD')) : null;
            this.modelMultiSearch.created_date_to = this.modelMultiSearch.created_date_to != null ? new Date(this.$moment(this.modelMultiSearch.created_date_to).format('YYYY-MM-DD')) : null;

            this.loadData();
            this.$refs.dlgSearch.close();
        },
        onResetSearchMulti(){
            this.modelMultiSearch = ModelUtil.resetModel(this.modelMultiSearch);

            this.modelMultiSearch.delivery_date_from = null;
            this.modelMultiSearch.delivery_date_to = null;
            this.modelMultiSearch.created_date_from = null;
            this.modelMultiSearch.created_date_to = null;

            this.dataGrid.pageNumber = 1;
            this.$refs.dataGrid.unselectRow(this.$refs.dataGrid.selectedRows[0]);
            this.loadData();
            this.$refs.dlgSearch.close();
        },
        async onSelectionStatusData(){
            this.dataGrid.pageNumber = 1;
            await this.loadData();
            this.$refs.dataGrid.unselectRow(this.$refs.dataGrid.selectedRows[0]);
            if(this.filter.viewStatusMessage){
                this.$refs.notification.success(InformationUtil.refresh);
            }
            this.filter.viewStatusMessage = true;
        },

        onRowDblClick(){
            if(this.action.edit){
                this.onEdit();
            }
        },
        async onRefresh(){
            this.dataGrid.pageNumber = 1;
            this.$refs.dataGrid.unselectRow(this.$refs.dataGrid.selectedRows[0]);
            await this.loadData();
            this.$refs.notification.success(InformationUtil.refresh);
        },
        async onSearch(){
            await this.loadData();
            this.$refs.dataGrid.unselectRow(this.$refs.dataGrid.selectedRows[0]);
            this.$refs.notification.success(InformationUtil.filter);
        },
        onSortChange(event){
            if(event.length > 0){
                this.dataGrid.pageNumber = 1;
                this.dataGrid.sortField = event[0].field;
                this.dataGrid.sortOrder = event[0].order;
                this.loadData();
            }
        },
        onPageChange(event) {
            this.$refs.dataGrid.unselectRow(this.$refs.dataGrid.selectedRows[0]);
            this.dataGrid.pageNumber = event.pageNumber;
            this.dataGrid.pageSize = event.pageSize;
            if(event.refresh) this.dataGrid.pageNumber = 1;
            this.loadData();
            if(event.refresh){
                this.$refs.notification.success(InformationUtil.refresh);
            }
        },

        async loadData(){
            this.dataGrid.loading = true;
            let param = {
                statusData : this.searchStatusData,
                multiSearch : this.modelMultiSearch,
            }
            return OutboundService.getData('/deliveryOrder', this.dataGrid, param)
                .then((response) => {
                    if(response.data !== "" && response.data !== null){
                        let data = response.data; 
                        this.dataGrid.total = data.total;
                        this.dataGrid.rows = data.rows;
                    }
                    this.dataGrid.loading = false;
                });
        },

        // combo box data
        async optionClient(event){
            let search = event == null ? "" : event.filterValue;
            return await OptionService.getOption("/client", search)
                .then((response) => {
                    this.clients = response.data;
                }).catch((error) => {
                    this.$refs.notification.error(error);
                });
        },
        async optionSalesOrder(event){
            let search = event == null ? "" : event.filterValue;
            const params = {
                search,
                client_id : this.model.client_id
            }
            return await OptionService.getOptionByParams("/sales-order", params)
                .then((response) => {
                    this.salesOrderList = response.data;
                }).catch((error) => {
                    this.$refs.notification.error(error);
                });
        },
        async ringCode(event){
            let search = event == null ? "" : event.filterValue;
            return await OptionService.getOption("/ringCode", search)
                .then((response) => {
                    this.orderTypes = response.data;
                }).catch((error) => {
                    this.$refs.notification.error(error);
                });
        },
        onCallAllOption(){
            this.optionClient();
            this.optionSalesOrder();
            this.ringCode();
        },
        onSelectionCustomerOrigin(row){
            this.modelNotMapping.origin_customer_name = row.name;
            this.modelNotMapping.origin_customer_address = row.address;
        },
        onSelectionCustomerDestination(row){
            this.modelNotMapping.dest_customer_name = row.name;
            this.modelNotMapping.dest_customer_address = row.address;
        },
    },
    async mounted(){
        this.action = AccessUtil.getAccessAction('delivery_order');
        await this.loadData();
        this.$refs.notification.success(InformationUtil.loadGrid + " " + this.dataGrid.total + " " + InformationUtil.successLoadGrid);
    },
    created(){
        GlobalUtil.removeWindow();
    }
};
</script>