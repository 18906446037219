export default{
    variable_cost : 0,
    client_variable_cost : 0,
    component_entries : null,
    sum_component_cost : 0,
    client_component_entries : null,
    client_sum_component_cost : 0,
    rate : 0,
    client_rate : 0,
    load_kg : 0,
    client_load_kg : 0,
    rate_type : 1,
    client_rate_type : 1
}