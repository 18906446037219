export default {
    manifest_id : "",
    point_id : "",
    tm_state : "",
    arrival_eta : "",
    arrival_etatime : "",
    arrival_ata : "",
    arrival_atatime : "",
    spm_submit : "",
    spm_submittime : "",
    loading_start : "",
    loading_starttime : "",
    loading_finish : "",
    loading_finishtime : "",
    documentation : "",
    documentationtime : "",
    departure_eta : "",
    departure_etatime : "",
    departure_ata : "",
    departure_atatime : "",
    notes : "",
    arrival_note : "",
    arrival_image : "",
    loading_start_note : "",
    loading_start_image : "",
    departure_finish_note : "",
    departure_finish_image : ""
}