import AppService from "./AppService";

class AuthService{
    
    /**
     * Service for login application
     * @param {object} user 
     * @returns 
     */
    login(user){
        return AppService.post("auth/login", user);
    }
    
    getMenuAccess(){
        return AppService.get("auth/access");
    }

    getUser(){
        return AppService.get("auth/user");
    }

    getAccessApp(){
        return AppService.get("auth/get-access-app");
    }

}

export default new AuthService()