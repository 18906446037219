<template>
    <ul class="pagination">
      <li class="pagination-item">
        <button type="button" @click="onClickFirstPage" :disabled="isInFirstPage" aria-label="Go to first page" class="first">
          <span class="l-btn-icon-custom pagination-first"></span>
        </button>
      </li>
  
      <li class="pagination-item">
        <button type="button" @click="onClickPreviousPage" :disabled="isInFirstPage" aria-label="Go to previous page" class="previous">
          <span class="l-btn-icon-custom pagination-prev"></span>
        </button>
      </li>
  
      <li v-for="(page, index) in pages" class="pagination-item" :key="index">
        <button type="button" @click="onClickPage(page.name)" :disabled="page.isDisabled" :class="{ active: isPageActive(page.name) }" :aria-label="`Go to page number ${page.name}`">
          {{ page.name }}
        </button>
      </li>
  
      <li class="pagination-item">
        <button type="button" class="next" @click="onClickNextPage" :disabled="isInLastPage" aria-label="Go to next page">
          <span class="l-btn-icon-custom pagination-next"></span>
        </button>
      </li>
  
      <li class="pagination-item">
        <button type="button" class="last" @click="onClickLastPage" :disabled="isInLastPage" aria-label="Go to last page">
          <span class="l-btn-icon-custom pagination-last"></span>
        </button>
      </li>
    </ul>
  </template>
  <script>
  export default {
    name: "ThePagination",
    props: {
      maxVisibleButtons: {
        type: Number,
        required: false,
        default: 0,
      },
      totalPages: {
        type: Number,
        required: true,
      },
      total: {
        type: Number,
        required: true,
      },
      perPage: {
        type: Number,
        required: true,
      },
      currentPage: {
        type: Number,
        required: true,
      },
    },
    computed: {
      startPage() {
        if (this.currentPage === 1) {
          return 1;
        }
  
        if (this.currentPage === this.totalPages) {
          return this.totalPages - this.maxVisibleButtons + 1;
        }
  
        return this.currentPage - 1;
      },
      endPage() {
        return Math.min(
          this.startPage + this.maxVisibleButtons - 1,
          this.totalPages
        );
      },
      pages() {
        const range = [];
  
        for (let i = this.startPage; i <= this.endPage; i += 1) {
          range.push({
            name: i,
            isDisabled: i === this.currentPage,
          });
        }
  
        return range;
      },
      isInFirstPage() {
        return this.currentPage === 1;
      },
      isInLastPage() {
        return this.currentPage === this.totalPages;
      },
    },
    methods: {
      onClickFirstPage() {
        this.$emit("pagechanged", 1);
      },
      onClickPreviousPage() {
        this.$emit("pagechanged", this.currentPage - 1);
      },
      onClickPage(page) {
        this.$emit("pagechanged", page);
      },
      onClickNextPage() {
        this.$emit("pagechanged", this.currentPage + 1);
      },
      onClickLastPage() {
        this.$emit("pagechanged", this.totalPages);
      },
      isPageActive(page) {
        return this.currentPage === page;
      },
    },
  };
  </script>
  <style scoped>
  #app {
    font-family: Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin-top: 60px;
  }
  
  .pagination {
    list-style-type: none;
    margin: 0px;
  }
  
  .pagination-item {
    display: inline-block;
    margin-right: -5px;
  }
  
  /* button background transparent */
  .pagination-item button {
    background-color: transparent;
    border: 1px solid transparent;
    color: #2c3e50;
    padding: 0px 6px;
    cursor: pointer;
  }
  
  /* active */
  .pagination-item button.active {
    background-color: #dddddd;
    color: #2c3e50;
  }
  
  .pagination-item button img {
    width: 15px;
    height: 15px;
  }
  
  .pagination-item button.first img {
    transform: rotate(180deg);
    color: #4aae9b;
  }
  .pagination-item button.previous img {
    transform: rotate(180deg);
  }
  
  /* Filter Blur IMG */
  .pagination-item button:disabled img {
    filter: blur(2px);
  }
  
  .pagination-item button:disabled span {
    opacity: .5;
  }
  
  .l-btn-icon-custom {
      display: inline-block;
      width: 16px;
      height: 16px;
  }
  </style>
  