<template>
    <div>
        <Panel :title="titleView"  style="margin:0px 1px; ">
            <div class="datagrid-toolbar">
                <LinkButton :plain="true" :iconCls="iconUtil.iconRefresh" @click="onRefresh()">{{ btnText.refresh }}</LinkButton>
                <LinkButton v-if="action.add" :plain="true" :iconCls="iconUtil.iconAdd" @click="onAdd()">{{ btnText.add }}</LinkButton>
                <LinkButton v-if="action.edit" :plain="true" :iconCls="iconUtil.iconEdit" @click="onEdit()">{{ btnText.edit }}</LinkButton>
                <LinkButton v-if="action.delete" :plain="true" :iconCls="iconUtil.iconDelete" @click="onDelete()">{{ btnText.delete }}</LinkButton>
                <span class="button-sep"></span> {{ search.text }} : 
                <SearchBox style="width:300px"
                    :placeholder="search.placeholder" 
                    v-model="dataGrid.search"
                    @search="onSearch()">
                    <Addon>
                        <span v-if="dataGrid.search" class="textbox-icon icon-clear" title="Clear value" @click="dataGrid.search=null;onSearch();"></span>
                    </Addon>
                </SearchBox>
            </div>
            <DataGrid
                    ref="dataGrid"
                    :columnResizing="true"
                    :border="false"
                    :lazy="true"
                    :loading="dataGrid.loading"                                    
                    :data="dataGrid.rows"
                    :pagination="true"
                    :selectionMode="dataGrid.selectionMode"
                    :total="dataGrid.total"
                    :pageSize="dataGrid.pageSize"
                    :pageNumber="dataGrid.pageNumber"
                    :pageLayout="dataGrid.pageLayout" 
                    @pageChange="onPageChange($event)"
                    @sortChange="onSortChange($event)"
                    @rowDblClick="onRowDblClick()"
                    >
                
                <GridColumn align="center" cellCss="datagrid-td-rownumber" width="30">
                    <template slot="body" slot-scope="scope">
                        {{scope.rowIndex + 1}}
                    </template>
                </GridColumn>
                <GridColumn field="client_id" title="Client ID" :sortable="true"></GridColumn>
                <GridColumn field="name" title="Name" :sortable="true"></GridColumn>
                <GridColumn field="address1" title="Address 1" :sortable="true"></GridColumn>
                <GridColumn field="address2" title="Address 2" :sortable="true"></GridColumn>
                <GridColumn field="city" title="City" :sortable="true"></GridColumn>
                <GridColumn field="postal_code" title="Postal Code" :sortable="true"></GridColumn>
                <GridColumn field="phone" title="Phone" :sortable="true"></GridColumn>
                <GridColumn field="fax" title="Fax" :sortable="true"></GridColumn>
                <GridColumn field="pic" title="PIC" :sortable="true"></GridColumn>
                <GridColumn field="email" title="Email" :sortable="true"></GridColumn>
                <GridColumn field="additional_information" title="Additional Information" :sortable="true"></GridColumn>
                <GridColumn field="payment_term" title="Payment Term" :sortable="true"></GridColumn>
            </DataGrid>
        </Panel>

        <!-- Form -->
        <Dialog ref="dlg" :iconCls="icon" bodyCls="f-column" :title="title" :draggable="true" :modal="true" closed :dialogStyle="{width:'686px', height:'auto'}">
            <div class="f-full" style="overflow:auto">
                <Form ref="form" :model="model" :rules="rules" @validate="errors=$event">
                    <Tabs>
                        <TabPanel :title="'Header'">
                            <table width="100%" cellspacing="1" cellpadding="5" border="0">
                                <tbody>
                                    <tr>
                                        <td class="bg_subtitle">
                                            Client ID <font color="red">*</font>
                                        </td>
                                        <td class="bg_grid2"> 
                                            <TextBox inputId="client_id" name="client_id" class="f-field-text" v-model="model.client_id" :disabled="disabled"></TextBox>
                                            <div class="error">{{textError.client_id}}</div>
                                        </td>
                                        <td class="bg_subtitle">Postal Code</td>
                                        <td class="bg_grid2">
                                            <TextBox inputId="postal_code" name="postal_code" class="f-field-text" v-model="model.postal_code"></TextBox>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="bg_subtitle">Name <font color="red">*</font></td>
                                        <td class="bg_grid2">
                                            <TextBox inputId="name" name="name" class="f-field-text" v-model="model.name"></TextBox>
                                            <div class="error">{{textError.name}}</div>
                                        </td>
                                        <td class="bg_subtitle">Phone</td>
                                        <td class="bg_grid2">
                                            <TextBox inputId="phone" name="phone" class="f-field-text" v-model="model.phone"></TextBox>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="bg_subtitle">Address 1</td>
                                        <td class="bg_grid2">
                                            <TextBox inputId="address1" name="address1" :multiline="true" class="f-field-text f-field-text-area" v-model="model.address1"></TextBox>
                                        </td>
                                        <td class="bg_subtitle">Address 2</td>
                                        <td class="bg_grid2">
                                            <TextBox inputId="address2" name="address2" :multiline="true" class="f-field-text f-field-text-area" v-model="model.address2"></TextBox>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="bg_subtitle">Fax</td>
                                        <td class="bg_grid2">
                                            <TextBox inputId="fax" name="fax" class="f-field-text" v-model="model.fax"></TextBox>
                                        </td>
                                        <td class="bg_subtitle">PIC</td>
                                        <td class="bg_grid2">
                                            <TextBox inputId="pic" name="pic" class="f-field-text" v-model="model.pic"></TextBox>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="bg_subtitle">City</td>
                                        <td class="bg_grid2">
                                            <TextBox inputId="city" name="city" class="f-field-text" v-model="model.city"></TextBox>
                                        </td>
                                        <td class="bg_subtitle">Email</td>
                                        <td class="bg_grid2">
                                            <TextBox inputId="email" name="email" class="f-field-text" v-model="model.email"></TextBox>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="bg_subtitle">Payment Term</td>
                                        <td class="bg_grid2">
                                            <TextBox inputId="payment_term" name="payment_term" class="f-field-text" v-model="model.payment_term"></TextBox>
                                        </td>
                                        <td class="bg_subtitle">Additional Information</td>
                                        <td class="bg_grid2">
                                            <TextBox inputId="additional_information" name="additional_information" class="f-field-text" v-model="model.additional_information"></TextBox>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="bg_subtitle">Calculate By Type Vehicle</td>
                                        <td class="bg_grid2">
                                            <SwitchButton inputId="is_by_type" name="is_by_type" class="f-field-text" v-model="model.is_by_type"></SwitchButton>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </TabPanel>
                        <TabPanel :title="'Area'">
                            <fieldset class="c-fieldset">
                                <legend class="c-legend"><b>Dalam Kota</b></legend>
                                <table width="100%" cellspacing="0" cellpadding="2" border="0">
                                    <tbody>
                                        <tr>
                                            <td style="width:50%;" align="left">
                                                Area :
                                                <ComboBox  :data="areas" @filterChange="optionArea($event)" @selectionChange="onSelectAreaIn($event)"  inputId="area_id" name="area_id" class="f-field-text" v-model="modelInputAreaIn.area_id"></ComboBox>
                                            </td>
                                            <td style="width:5%;padding-left: 10px;" align="left">
                                                &nbsp;&nbsp;
                                                <LinkButton :iconCls="iconUtil.iconAdd" style="border:none;" @click="addAreaIn()"></LinkButton>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <Panel>
                                    <div class="datagrid-toolbar" style="margin-bottom: 10px;">
                                        {{ search.text }} : 
                                        <SearchBox style="width:200px"
                                            :placeholder="search.placeholder" 
                                            v-model="dataGridAreaIn.search"
                                            @search="onSearchAreaIn()">
                                            <Addon>
                                                <span v-if="dataGridAreaIn.search" class="textbox-icon icon-clear" title="Clear value" @click="dataGridAreaIn.search=null;onSearchAreaIn();"></span>
                                            </Addon>
                                        </SearchBox>
                                        <LinkButton v-if="action.add || action.edit" :plain="true" :iconCls="iconUtil.iconDelete" @click="onDeleteAreaIn()">{{ btnText.delete }}</LinkButton>
                                    </div>

                                    <DataGrid
                                        ref="dataGridAreaIn"
                                        :columnResizing="true"
                                        :border="false"
                                        :loading="dataGridAreaIn.loading"
                                        :data="dataGridAreaIn.rows"
                                        :pagination="true"
                                        :selectionMode="dataGridAreaIn.selectionMode"
                                        :total="dataGridAreaIn.total"
                                        :pageSize="dataGridAreaIn.pageSize"
                                        :pageNumber="dataGridAreaIn.pageNumber"
                                        :pageLayout="dataGridAreaIn.pageLayout"
                                        style="width: 550px;height:190px">
                                        <GridColumn field="area_desc" title="Area" width="150"></GridColumn>
                                    </DataGrid>
                                </Panel>
                            </fieldset>
                            <fieldset class="c-fieldset">
                                <legend class="c-legend"><b>Luar Kota</b></legend>
                                <table width="100%" cellspacing="0" cellpadding="2" border="0">
                                    <tbody>
                                        <tr>
                                            <td style="width:50%;" align="left">
                                                Area :
                                                <ComboBox  :data="areas" @filterChange="optionArea($event)" @selectionChange="onSelectAreaOut($event)"  inputId="area_id" name="area_id" class="f-field-text" v-model="modelInputAreaOut.area_id"></ComboBox>
                                            </td>
                                            <td style="width:5%;padding-left: 10px;" align="left">
                                                &nbsp;&nbsp;
                                                <LinkButton :iconCls="iconUtil.iconAdd" style="border:none;" @click="addAreaOut()"></LinkButton>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <Panel>
                                    <div class="datagrid-toolbar" style="margin-bottom: 10px;">
                                        {{ search.text }} : 
                                        <SearchBox style="width:200px"
                                            :placeholder="search.placeholder" 
                                            v-model="dataGridAreaOut.search"
                                            @search="onSearchAreaOut()">
                                            <Addon>
                                                <span v-if="dataGridAreaOut.search" class="textbox-icon icon-clear" title="Clear value" @click="dataGridAreaOut.search=null;onSearchAreaOut();"></span>
                                            </Addon>
                                        </SearchBox>
                                        <LinkButton v-if="action.add || action.edit" :plain="true" :iconCls="iconUtil.iconDelete" @click="onDeleteAreaOut()">{{ btnText.delete }}</LinkButton>
                                    </div>

                                    <DataGrid
                                        ref="dataGridAreaOut"
                                        :columnResizing="true"
                                        :border="false"
                                        :loading="dataGridAreaOut.loading"
                                        :data="dataGridAreaOut.rows"
                                        :pagination="true"
                                        :selectionMode="dataGridAreaOut.selectionMode"
                                        :total="dataGridAreaOut.total"
                                        :pageSize="dataGridAreaOut.pageSize"
                                        :pageNumber="dataGridAreaOut.pageNumber"
                                        :pageLayout="dataGridAreaOut.pageLayout"
                                        style="width: 550px;height:190px">
                                        <GridColumn field="area_desc" title="Area" width="150"></GridColumn>
                                    </DataGrid>
                                </Panel>
                            </fieldset>
                        </TabPanel>
                    </Tabs>
                </Form>
            </div>
            <div class="dialog-button">
                <LinkButton :iconCls="iconUtil.iconSave" v-if="action.add || action.edit" @click="onSave()" :disabled="disableBtnSave" style="width:89px">{{ btnText.save }}</LinkButton>
                <LinkButton :iconCls="iconUtil.iconCancel" @click="$refs.dlg.close()" style="width:89px">{{ btnText.cancel }}</LinkButton>
            </div>
        </Dialog>

        <NotificationComponent ref="notification"></NotificationComponent>
    </div>
    
</template>

<script>
import NotificationComponent from '@/components/NotificationComponent.vue';
import Client from '@/models/Client';
import MasterService from '@/services/MasterService';
import ModelUtil from '@/utils/ModelUtil';
import InformationUtil from '@/utils/InformationUtil';
import AccessUtil from '@/utils/AccessUtil';
import IconUtil from '@/utils/IconUtil';
import GlobalUtil from '@/utils/GlobalUtil';
import OptionService from '@/services/OptionService';

export default {
    name: 'ClientView',
    components : {
        NotificationComponent
    },
    data() {
        return {
            titleView : InformationUtil.client + "s",
            titleMessage : InformationUtil.client,
            iconUtil : IconUtil,

            titleForm :{
                add : InformationUtil.addText,
                edit : InformationUtil.editText
            },

            iconForm :{
                add : IconUtil.iconAdd,
                edit : IconUtil.iconEdit
            },

            action : {
                view : false,
                add : false,
                edit : false,
                delete : false
            },

            btnText :{
                refresh : InformationUtil.refreshText,
                add : InformationUtil.addText,
                edit : InformationUtil.editText,
                delete : InformationUtil.removeText,
                save : InformationUtil.saveText,
                cancel : InformationUtil.cancelText,
                search : InformationUtil.searchText
            },

            search : {
                text : InformationUtil.searching,
                placeholder : InformationUtil.searchHolder
            },

            dataGrid : {
                loading: false,
                rows : [],
                total: 0,
                start: 0,
                pageNumber: 1,
                pageSize: 10,
                selectionMode : 'single',
                pageLayout: ['list','sep','first','prev','next','last','sep','refresh','info'],

                // filter
                search : "",
                sortField : "",
                sortOrder : ""
            },

            // grid area in
            dataGridAreaIn : {
                loading: false,
                rows : [],
                rowsTemp : [],
                total: 0,
                start: 0,
                pageNumber: 1,
                pageSize: 5,
                selectionMode : 'single',
                pageLayout: ['first','prev','next','last','sep','refresh','info'],

                // filter
                search : null,
                sortField : "",
                sortOrder : "",
            },

            dataGridAreaOut : {
                loading: false,
                rows : [],
                rowsTemp : [],
                total: 0,
                start: 0,
                pageNumber: 1,
                pageSize: 5,
                selectionMode : 'single',
                pageLayout: ['first','prev','next','last','sep','refresh','info'],

                // filter
                search : null,
                sortField : "",
                sortOrder : "",
            },

            // form
            title: '',
            icon: '',
            model: Client,
            disabled : false,
            disableBtnSave : false,

            modelInputAreaIn : {
                area_id : "",
                area_desc : "",
            },

            modelInputAreaOut : {
                area_id : "",
                area_desc : "",
            },

            // validation
            rules: {
                client_id: 'required',
                name: 'required'
            },
            errors: {},
            textError :{
                client_id : "",
                name : ""
            },
            areas : [],
        }
    },
    methods:{
        onTabSelected(){
            this.dataGrid.search = null;
            this.onRefresh();
        },
        getError(name) {
            return this.errors[name] && this.errors[name].length ? this.errors[name][0] : null;
        },
        onAdd(){
            ModelUtil.resetModel(this.textError);
            this.onCallAllOption();
            // method for add data
            this.disabled = false;
            this.$refs.dataGrid.unselectRow(this.$refs.dataGrid.selectedRows[0]);
            this.model = ModelUtil.resetModel(this.model);

            this.modelInputAreaIn = ModelUtil.resetModelByType(this.modelInputAreaIn);
            this.modelInputAreaIn.component_id = null;
            this.dataGridAreaIn.rows = [];
            this.dataGridAreaIn.rowsTemp = this.dataGridAreaIn.rows;

            this.modelInputAreaOut = ModelUtil.resetModelByType(this.modelInputAreaOut);
            this.modelInputAreaOut.component_id = null;
            this.dataGridAreaOut.rows = [];
            this.dataGridAreaOut.rowsTemp = this.dataGridAreaOut.rows;

            this.model.is_by_type = false;

            this.title = this.titleForm.add;
            this.icon = this.iconForm.add;
            this.$refs.dlg.open();
        },
        onEdit(){
            ModelUtil.resetModel(this.textError);
            // method for get data to edit
            if(this.$refs.dataGrid.selectedRows.length > 0){
                this.onCallAllOption();
                this.disabled = true;
                this.title = this.titleForm.edit;
                this.icon = this.iconForm.edit;
                this.model = ModelUtil.resetModel(this.model);
                this.model = ModelUtil.mapModel(this.model, this.$refs.dataGrid.selectedRows[0])

                this.modelInputAreaIn = ModelUtil.resetModelByType(this.modelInputAreaIn);
                this.modelInputAreaIn.component_id = null;
                this.dataGridAreaIn.rows = [];
                this.dataGridAreaIn.rows = this.$refs.dataGrid.selectedRows[0].area_in;
                this.dataGridAreaIn.rowsTemp = this.dataGridAreaIn.rows;
                this.modelInputAreaOut = ModelUtil.resetModelByType(this.modelInputAreaOut);
                this.modelInputAreaOut.component_id = null;
                this.dataGridAreaOut.rows = [];
                this.dataGridAreaOut.rows = this.$refs.dataGrid.selectedRows[0].area_out;
                this.dataGridAreaOut.rowsTemp = this.dataGridAreaOut.rows;

                this.model.is_by_type = this.model.is_by_type == 1 ? true : false;

                this.$refs.dlg.open();
            }else{
                this.$refs.notification.centerTop(InformationUtil.selectRow);
            }
        },
        onDelete(){
            if(this.$refs.dataGrid.selectedRows.length > 0){

                this.$messager.confirm({
                    title: 'Confirm',
                    msg: InformationUtil.confirmDelete,
                    result: (r) => {
                        if (r){
                            this.dataGrid.loading = true;
                            MasterService.deleteData("/client", this.$refs.dataGrid.selectedRows[0].id)
                                .then(() => {
                                    this.dataGrid.loading = false;
                                    this.$refs.notification.success(this.titleMessage + " " + InformationUtil.delete);
                                    this.loadData();
                                    this.$refs.dlg.close();
                                }).catch((err) => {
                                    this.$refs.notification.error(err.data.message);
                                });
                            this.$refs.dataGrid.unselectRow(this.$refs.dataGrid.selectedRows[0]) 
                        }
                    }
                })
                
            }else{
                this.$refs.notification.centerTop(InformationUtil.selectRow);
            }
            
        },
        onSave(){
            ModelUtil.resetModel(this.textError);
            this.$refs.form.validate((valid) => {
                if(!valid){
                    this.disableBtnSave = true; 
                    
                    this.model.area_in = this.dataGridAreaIn.rowsTemp;
                    this.model.area_out = this.dataGridAreaOut.rowsTemp;

                    if(this.model.id == null){
                        // action add data
                        MasterService.addData("/client", this.model)
                            .then((res) => {
                                this.disableBtnSave = false;
                                if(res.code == 200){
                                    this.$refs.notification.success(this.titleMessage + " " + InformationUtil.add);
                                    this.loadData();
                                    this.$refs.dlg.close();
                                } else {
                                    this.$messager.alert({
                                        title: "Error",
                                        icon: "error",
                                        msg: res.message
                                    });
                                }                               
                            }).catch((error) => {
                                this.disableBtnSave = false;
                                this.$refs.notification.error(error);
                            });

                    }else{
                        // action update data
                        MasterService.updateData("/client", this.model.id, this.model)
                            .then((res) => {
                                this.disableBtnSave = false;
                                if(res.code == 200){
                                    this.$refs.notification.success(this.titleMessage + " " + InformationUtil.update);
                                    this.loadData();
                                    this.$refs.dlg.close();
                                } else {
                                    this.$messager.alert({
                                        title: "Error",
                                        icon: "error",
                                        msg: res.message
                                    });
                                }
                            }).catch((error) => {
                                this.disableBtnSave = false;
                                this.$refs.notification.error(error);
                            });
                    }
                    this.$refs.dataGrid.unselectRow(this.$refs.dataGrid.selectedRows[0])
                } else {
                    for(let key in valid){
                        this.textError[key] = this.getError(key);
                    }
                }
            });
            
        },

        // handle data grid
        onRowDblClick(){
            if(this.action.edit){
                this.onEdit();
            }
        },
        async onRefresh(){
            this.dataGrid.pageNumber = 1;
            this.$refs.dataGrid.unselectRow(this.$refs.dataGrid.selectedRows[0]);
            await this.loadData();
            this.$refs.notification.success(InformationUtil.refresh);
        },
        onSearch(){
            this.loadData();
        },
        onSortChange(event){
            if(event.length > 0){
                this.dataGrid.pageNumber = 1;
                this.dataGrid.sortField = event[0].field;
                this.dataGrid.sortOrder = event[0].order;
                this.loadData();
            }
        },
        onPageChange(event) {
            this.$refs.dataGrid.unselectRow(this.$refs.dataGrid.selectedRows[0]);
            this.dataGrid.pageNumber = event.pageNumber;
            this.dataGrid.pageSize = event.pageSize;
            if(event.refresh) this.dataGrid.pageNumber = 1;
            this.loadData();
            if(event.refresh){
                this.$refs.notification.success(InformationUtil.refresh);
            }
        },

        async loadData(){
            this.dataGrid.loading = true;
            return MasterService.getData('/client', this.dataGrid)
                .then((response) => {
                    if(response.data !== "" && response.data !== null){
                        let data = response.data; 
                        this.dataGrid.total = data.total;
                        this.dataGrid.rows = data.rows;
                    }
                    
                    this.dataGrid.loading = false;
                });
        },

        // data grid area in
        onSelectAreaIn(event){
            if(event != null && event.text != undefined){
                this.modelInputAreaIn.area_desc = event.text;
            }
        },
        addAreaIn(){
            // validation
            if(this.modelInputAreaIn.area_desc == null || this.modelInputAreaIn.area_desc == ""){
                this.$messager.alert({
                    title: "Error",
                    icon: "error",
                    msg: "Area required"
                });
                return;
            }

            let isExistArea = false;
            this.dataGridAreaIn.rows.forEach(element => {
                if(element.area_id == this.modelInputAreaIn.area_id){
                    this.$messager.alert({
                        title: "Error",
                        icon: "error",
                        msg: "Area already exist"
                    });
                    isExistArea = true;
                    return;
                }
            });
            if(isExistArea) return;

            let data = {
                area_id : this.modelInputAreaIn.area_id,
                area_desc : this.modelInputAreaIn.area_desc,
            }
            this.dataGridAreaIn.rows.push(data);
            this.dataGridAreaIn.rowsTemp = this.dataGridAreaIn.rows;
            this.dataGridAreaIn.total = this.dataGridAreaIn.rows.length;

            ModelUtil.resetModelByType(this.modelInputAreaIn);
            this.modelInputAreaIn.area_id = null;
        },

        onSearchAreaIn(){
            let search = this.dataGridAreaIn.search;
            let rows = this.dataGridAreaIn.rowsTemp;
            let result = [];
            if(search != null && search != ""){
                for(let i = 0; i < rows.length; i++){
                    if(rows[i].area_desc.toLowerCase().indexOf(search.toLowerCase()) > -1){
                        result.push(rows[i]);
                    }
                }
                this.dataGridAreaIn.rows = result;
            }else{
                this.dataGridAreaIn.rows = this.dataGridAreaIn.rowsTemp;
            }
        },

        onDeleteAreaIn(){
            if(this.$refs.dataGridAreaIn.selectedRows.length > 0){
                this.$messager.confirm({
                    title: 'Confirm',
                    msg: InformationUtil.confirmDelete,
                    result: (r) => {
                        if (r){
                            this.dataGridAreaIn.loading = true;
                             // find index
                             let index = -1;
                            for(let i = 0; i < this.dataGridAreaIn.rows.length; i++){
                                if(this.dataGridAreaIn.rows[i].area_id == this.$refs.dataGridAreaIn.selectedRows[0].area_id){
                                    index = i;
                                    break;
                                }
                            }
                            if(index > -1) this.dataGridAreaIn.rows.splice(index, 1);
                            this.dataGridAreaIn.loading = false;
                            this.$refs.notification.success(this.titleMessage + " " + InformationUtil.delete);
                            this.$refs.dataGridAreaIn.unselectRow(this.$refs.dataGridAreaIn.selectedRows[0]);
                            this.dataGridAreaIn.rowsTemp = this.dataGridAreaIn.rows;
                        }
                    }
                })
            }else{
                this.$messager.alert({
                    title: "Info",
                    icon: "info",
                    msg: InformationUtil.selectRow
                });
            }
        },

        // data grid area out
        onSelectAreaOut(event){
            if(event != null && event.text != undefined){
                this.modelInputAreaOut.area_desc = event.text;
            }
        },
        addAreaOut(){
            // validation
            if(this.modelInputAreaOut.area_desc == null || this.modelInputAreaOut.area_desc == ""){
                this.$messager.alert({
                    title: "Error",
                    icon: "error",
                    msg: "Area required"
                });
                return;
            }

            let isExistArea = false;
            this.dataGridAreaOut.rows.forEach(element => {
                if(element.area_id == this.modelInputAreaOut.area_id){
                    this.$messager.alert({
                        title: "Error",
                        icon: "error",
                        msg: "Area already exist"
                    });
                    isExistArea = true;
                    return;
                }
            });
            if(isExistArea) return;

            let data = {
                area_id : this.modelInputAreaOut.area_id,
                area_desc : this.modelInputAreaOut.area_desc,
            }
            this.dataGridAreaOut.rows.push(data);
            this.dataGridAreaOut.rowsTemp = this.dataGridAreaOut.rows;
            this.dataGridAreaOut.total = this.dataGridAreaOut.rows.length;

            ModelUtil.resetModelByType(this.modelInputAreaOut);
            this.modelInputAreaOut.area_id = null;
        },

        onSearchAreaOut(){
            let search = this.dataGridAreaOut.search;
            let rows = this.dataGridAreaOut.rowsTemp;
            let result = [];
            if(search != null && search != ""){
                for(let i = 0; i < rows.length; i++){
                    if(rows[i].area_desc.toLowerCase().indexOf(search.toLowerCase()) > -1){
                        result.push(rows[i]);
                    }
                }
                this.dataGridAreaOut.rows = result;
            }else{
                this.dataGridAreaOut.rows = this.dataGridAreaOut.rowsTemp;
            }
        },

        onDeleteAreaOut(){
            if(this.$refs.dataGridAreaOut.selectedRows.length > 0){
                this.$messager.confirm({
                    title: 'Confirm',
                    msg: InformationUtil.confirmDelete,
                    result: (r) => {
                        if (r){
                            this.dataGridAreaOut.loading = true;
                            // find index
                            let index = -1;
                            for(let i = 0; i < this.dataGridAreaOut.rows.length; i++){
                                if(this.dataGridAreaOut.rows[i].area_id == this.$refs.dataGridAreaOut.selectedRows[0].area_id){
                                    index = i;
                                    break;
                                }
                            }
                            if(index > -1) this.dataGridAreaOut.rows.splice(index, 1);
                            this.dataGridAreaOut.loading = false;
                            this.$refs.notification.success(this.titleMessage + " " + InformationUtil.delete);
                            this.$refs.dataGridAreaOut.unselectRow(this.$refs.dataGridAreaOut.selectedRows[0]);
                            this.dataGridAreaOut.rowsTemp = this.dataGridAreaOut.rows;
                        }
                    }
                })
            }else{
                this.$messager.alert({
                    title: "Info",
                    icon: "info",
                    msg: InformationUtil.selectRow
                });
            }
        },

        async optionArea(event){
            let search = event == null ? "" : event.filterValue;
            return await OptionService.getOption("/area", search)
                .then((response) => {
                    for(let i = 0; i < response.data.length; i++){
                        response.data[i].text = response.data[i].area_id;
                    }
                    this.areas = response.data;
                }).catch((error) => {
                    this.$refs.notification.error(error);
                });
        },
        onCallAllOption(){
            this.optionArea();
        },
    },
    async mounted(){
        this.action = AccessUtil.getAccessAction('client');
        await this.loadData();
        this.$refs.notification.success(InformationUtil.loadGrid + " " + this.dataGrid.total + " " + InformationUtil.successLoadGrid);     
    },
    created(){
        GlobalUtil.removeWindow();
    }
};
</script>